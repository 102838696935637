import { deleteProjectMedia, uploadImageMedia } from '../../apis/TemplateAPI';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "universal-cookie";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getImageMedia } from '../../apis/TemplateAPI';

import Modal from '../../components/Modal/Modal';

import { XIcon, TrashIcon, PhotographIcon, UploadIcon } from '@heroicons/react/outline';
import { checkIfNullOrUndefinedOrEmpty } from '../../utilities/general';

const cookies = new Cookies();

const Sidebar = ({ saveCanvasSize, setObjectState, currentImage, setCurrentImage, createImage,
                    setBackgroundImage, orientation, setOrientation, colorspace, setColorspace, predefinedSize, setPredefinedSize, 
                    canvasSize, setCanvasSize, currentImageId, setCurrentImageId, background, bgImageObject, isBgAdded}) => {

    toast.configure();
    const [imageData, setImageData] = useState();
    const [imageUploaded, setImageUploaded] = useState();
    const [imageLoadedForUpload, setImageLoadedForUpload] = useState(false);

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showChangeSizeModal, setShowChangeSizeModal] = useState(false);

    const [changeSizeError, setChangeSizeError] = useState('');

    const [isMediaUploading, setIsMediaUploading] = useState();

    const [tempOrientation, setTempOrientation] = useState();

    const [showChangeOrientationModal, setShowChangeOrientationModal] = useState(false);

    const history = useHistory();

    const [imageMedia, setImageMedia] = useState([]);
    const [mediaPageNo, setMediaPageNo] = useState(0);

    const [imageError, setImageError] = useState('');

    const [hasMoreMedia, setHasMoreMedia] = useState(true);

    // const [converted, setConverted]

    const token = JSON.parse(atob(cookies.get("GG_token")));

    useEffect(() => {
        if(token === undefined || token === null) {
            history.push("/")
          } else {
            fetchImageMedia();
          }
    }, [])

    const readFile = (e) => {
        // setImageData(null);
        var file = e.target.files[0];
        let fileUpload_flag = false;

        if (
            file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png"
        )  {
            setImageError('');

            setImageData(file);
            setImageLoadedForUpload(true);

            setTimeout(() => {
                if(fileUpload_flag === true) {
                    setImageData(file);
                }
            }, 200);
        }
        else if(file.type == "application/pdf"){
            setImageError('');
            setImageData(file);
            setImageLoadedForUpload(true);
        }
        else {
            setImageError('Only .jpg, .jpeg, .png, .pdf files are allowed.');
        }
    }

    const removeImageFromArray = () => {
        let newImageMedia = [];
        for(let i=0; i<imageMedia?.length; i++) {
          if(imageMedia[i]?.id != currentImageId) {
            newImageMedia.push(imageMedia[i])
          }
        }
    
        setImageMedia(newImageMedia);
      }

    const fetchImageMedia = () => {
        setMediaPageNo(mediaPageNo + 1)
        getImageMedia(token, mediaPageNo)
        .then(response => {
            if(response?.data?.data?.length > 0) {
                console.log(response?.data?.data[0]?.path);
                
                // testImgToBase64(response?.data?.data[0]?.path);

                setImageMedia(imageMedia.concat(response.data.data));
            }
            else if(response?.data?.data?.length == 0) {
                setHasMoreMedia(false);
            }
        })
        .catch(err => {
                toast.error("Error while retrieving image media.", {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: false
            })
        })
    }

    // const testImgToBase64 = (url) => {
    //     fetch(url)
    //         .then(res => {
    //             console.log(res);
    //             res.blob()
    //             .then(imageBlob => {
    //                 console.log(imageBlob)
    //                 const imageObjectURL = URL.createObjectURL(imageBlob);
    //                 console.log(imageObjectURL);
    //             }) 
    //         })
    // }

    const uploadImage = () => {
        setIsMediaUploading(true);

        uploadImageMedia(token, imageData)
            .then(response => {
                let imgMedia = imageMedia;

                setImageMedia([{id: null, path: response?.data?.data?.media_path, type: "image"}, ...imgMedia ])
                setImageUploaded(true);
                closeUploadMediaModal();
                setIsMediaUploading(false);
                setImageLoadedForUpload(false);

                toast.success("Image uploaded successfully!", {
                    position: 'top-left',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: false
                })
            })
            .catch(err => {
                toast.error("Error occured while uploading image.", {
                    position: 'top-left',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: false
                  })
                  closeUploadMediaModal();
                  setIsMediaUploading(false);
            })
    }

    const callSaveCanvasSize = () => {
        setShowChangeSizeModal(false);
        saveCanvasSize();    
    }

    const callChangeCanvasOrientation = (e) => {
        setOrientation(tempOrientation);
        setObjectState([]);
        setShowChangeOrientationModal(false);
    }

    const changeCanvasOrientation = (e) => {
        setShowChangeOrientationModal(true);
        setTempOrientation(e.target.value)
    }

    const closeUploadMediaModal = () => {
        setShowUploadModal(false)
        setImageUploaded(false);
    }

    const closeChangeSizeModal = () => {
        setShowChangeSizeModal(false);
        setChangeSizeError('');
    }

    const selectImage = (imagePath, imageId) => {
        setCurrentImage(imagePath);
        setCurrentImageId(imageId);
    }

    const callDeleteImageMedia = () => {
        if(currentImageId != -1 && currentImageId !== null && currentImageId !== undefined) {
            deleteProjectMedia(token, currentImageId)
                .then(res => {
                    removeImageFromArray();
                    toast.success("Image deleted successfully.", {
                        position: 'top-left',
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: false
                      })
                })
                .catch(err => {
                    toast.error("Error deleting image.", {
                        position: 'top-left',
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: false
                    })
                })
        }
        else if(currentImageId == -1) {
            toast.error("Please select an image to delete it.", {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: false
            })
        }
    }

    return (
        <>
        <section className="sidebar py-2 text-sm">
            <div className="flex mx-2 mb-2">
                <button className="w-full px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border mx-1 flex items-center justify-center" onClick={() => setShowUploadModal(true)}>
                    <UploadIcon className='w-5 mr-2' />
                    <span>Upload Image</span>
                </button>
                <button className="w-full px-4 py-1 rounded-md bg-red-500 hover:bg-red-600 border text-white mx-1 flex items-center justify-center" onClick={() => callDeleteImageMedia()}>
                {/* <i className="bi bi-x mr-1"></i>  */}
                <TrashIcon className='w-5 mr-2' /> <span className='mb-1'>Delete Image</span>
                </button>
            </div>
            <div className="flex flex-col w-full">
                <div>
                    <h3 className='mx-3 font-bold my-2 text-md'>Image Gallery:</h3>
                    <div id="mediaScrollContainer" className="h-[61vh] overflow-y-scroll border-slate-700 border-2 mx-2">
                    <InfiniteScroll
                        dataLength={imageMedia?.length}
                        next={fetchImageMedia}
                        hasMore={hasMoreMedia}
                        endMessage={
                            <p className='w-100 text-center'></p>
                        }
                        className="h-[60vh]"
                        scrollableTarget="mediaScrollContainer"
                    >
                        <div className="flex flex-wrap px-3 content-start">
                            {
                                imageMedia?.map(image => 
                                    {
                                        return (
                                            <img className={"my-2 object-contain h-20 w-1/2" + (image?.path === currentImage ? " border-4 border-sky-600" : "") } 
                                            src={image.path} height="auto"
                                            onClick={(e) => selectImage(e.target.src, image?.id) } alt={"Template"}/>
                                        )
                                    }
                                )
                            }
                            {
                                mediaPageNo == 1 &&
                                <div className='h-64 py-6 my-5'></div>
                            }
                        </div>
                    </InfiniteScroll>
                    </div>

                    {/* <div className='m-2'>
                        <button className="bg-white py-1 border w-full px-2" onClick={ createImage }><i clasNames="bi bi-image mr-2"></i>Add Image</button>
                    </div> */}

                    <div className="flex my-3">
                    {
                        isBgAdded ?
                        <button className="mx-1 px-4 py-1 text-white rounded-md bg-red-500 hover:bg-red-600 border w-full flex items-center justify-center" 
                            onClick={() => setBackgroundImage(false) }
                        > 
                                {/* <i className="bi bi-x mr-1"></i>  */}
                                <XIcon className='w-4 mr-2'/>
                                Remove Background
                        </button>
                        :
                        <button className="mx-1 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border w-full flex items-center justify-center" 
                            onClick={() => setBackgroundImage(true)}
                        > 
                            {/* <i className="bi bi-plus mr-1"></i> */}
                            <PhotographIcon className='w-4 mr-2' />
                            Set as background
                        </button>
                    }
                    </div>
                </div>
            </div>

        </section>

        {/* Modal to upload Images */}
        <Modal
            isOpen={showUploadModal}
            onClose={() => setShowUploadModal(false)}
        >
            <div className="modal-content">
                <div className="flex justify-between">
                    <h5 className="font-bold text-lg mb-2">Upload Image</h5>
                    <button type="button" className="" aria-label="Close" onClick={closeUploadMediaModal}>
                        <XIcon className='text-black w-6'/>
                    </button>
                </div>
                <div className="">
                    <p className='mb-2'>Select a file to upload. <br></br>Formats supported: .jpg, .jpeg, .png, .pdf</p>

                    <input className="form-control mb-3" type="file" onChange={e => readFile(e)}/>
                    {
                        imageUploaded ?
                        <p className="text-lime-600 font-bold mb-3">Image uploaded successfully</p>
                        :
                        null
                    }

                    {
                        isMediaUploading &&
                        <p className="my-3">
                            Image being uploaded, please wait...
                        </p>
                    }
                </div>
                { imageError !== '' ? <p className='py-3 text-red-500'>{ imageError }</p> : null}
                <div className="flex">
                    <button type="button" className="px-4 py-1 rounded-md bg-red-500 hover:bg-red-600 text-white border" onClick={closeUploadMediaModal}>Cancel</button>
                    <button type="button" 
                        className="ml-2 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" 
                        onClick={uploadImage}
                        disabled={!imageLoadedForUpload}
                    >
                        Upload Image
                    </button>
                </div>
            </div>
        </Modal>

        {/* Modal to change Size */}
        <Modal
            isOpen={showChangeSizeModal}
            onClose={() => setShowChangeSizeModal(false)}
        >
            <div className="modal-content">
                <div className="flex justify-between">
                    <h5 className="font-bold text-lg mb-2">Save new size</h5>
                    <button type="button" className="" aria-label="Close" onClick={closeChangeSizeModal}>
                    <XIcon className='text-black w-6'/>
                    </button>
                </div>
                <div className="">
                    <p className='mb-2'>Are you sure you wish to change the size? Changing size will reset the components added.</p>

                </div>

                {
                    changeSizeError !== '' && 
                    <p className='py-3 text-red-600'> {changeSizeError} </p>                            
                }
                <div className="flex">
                    <button type="button" className="px-4 py-1 rounded-md bg-red-500 hover:bg-red-600 text-white border" onClick={closeChangeSizeModal}>Cancel</button>
                    <button type="button" className="ml-2 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={callSaveCanvasSize}>
                        Save new size
                    </button>
                </div>
            </div>
        </Modal>

        {/* Modal to change Orientation */}
        <Modal
            isOpen={showChangeOrientationModal}
            onClose={() => setShowChangeOrientationModal(false)}
        >
            <div className="modal-content">
                <div className="flex justify-between">
                    <h5 className="font-bold text-lg mb-2">Change Orientation</h5>
                    <button type="button" className="" aria-label="Close" onClick={() => setShowChangeOrientationModal(false)}>
                        <XIcon className='text-black w-6 mb-2'/>
                    </button>
                </div>
                <div className="">
                    <p className='mb-2'>Are you sure you wish to change the orientation? Changing canvas orientation will reset the components added.</p>

                </div>
                <div className="flex">
                    <button type="button" className="px-4 py-1 rounded-md bg-red-500 hover:bg-red-600 text-white border" onClick={() => setShowChangeOrientationModal(false)}>Cancel</button>
                    <button type="button" className="ml-2 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={callChangeCanvasOrientation}>
                        Change Orientation
                    </button>
                </div>
            </div>
        </Modal>
    </>
    )
}

export default Sidebar;