import EditorHeader from '../../components/EditorHeader/EditorHeader'
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar/Topbar';
import Canvas from '../../components/Canvas/Canvas';
import './templateEditor.css';

import {useState, useEffect, useRef} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Redux based state 
import { connect } from "react-redux";

// Redux action
import {setTemplateMeta} from "../../services/actions/actions";

import { useHistory,useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import { createCertificateTemplate, getTemplates, updateCertificateTemplate } from '../../apis/TemplateAPI';
import { checkIfNotNullOrUndefinedOrEmpty, checkIfNullOrUndefinedOrEmpty } from '../../utilities/general';
import Config from '../../Config';
import SettingsModal from '../../components/SettingsModal/SettingsModal';

const cookies = new Cookies();

const TemplateEditor = ({templateData, setTemplateState}) => {
  toast.configure();
  const history = useHistory();
  const {clientId, templateId} = useParams();
  
  //State for currently selected item
  const [currentlySelected, setCurrentlySelected] = useState({type: null, index: null});

  const [selectedTemplate, setSelectedTemplate] = useState(templateId);
  const [templateName, setTemplateName] = useState("");
  const [editTemplate, setEditTemplate] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  //State for canvas orientation
  const [orientation, setOrientation] = useState("landscape");
  const [colorspace, setColorspace] = useState("CMYK");
  
  const [templates, setTemplates] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templatesPage, setTemplatesPage] = useState(0);
  const [initialLoad, setInitialLoad] = useState(false);

  const [outputType, setOutputType] = useState("PDF");
  const [dpi, setDpi] = useState(300);
  const [bleed, setBleed] = useState({top: 0, left: 0, bottom: 0, right: 0});
  const [canvasDetails, setCanvasDetails] = useState({ canvasSize: 'A4', width: 297, height: 210 });
  const [canvasSize, setCanvasSize] = useState({width: 297, height: 210});
  const [predefinedSize, setPredefinedSize] = useState("A4");
  const [filenameColumn, setFilenameColumn] = useState('');
  const [extendImageIntoBleed, setExtendImageIntoBleed] = useState(false);

  const [bleedColor, setBleedColor] = useState('#FFFFFF');

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [savedData, setSavedData] = useState("");

  //State for Background
  const [isBgAdded, setIsBgAdded] = useState(false);
  const [background, setBackground] = useState({ imgURL: "" });
  const [bgImageObject, setBgImageObject] = useState(null);

  //State for Images
  const [imageObjects, setImageObjects] = useState([]);
  const [selectedImageKey, setSelectedImageKey] = useState(null);

  const [currentImageId, setCurrentImageId] = useState(-1);
  const [currentImage, setCurrentImage] = useState("");
  const [textBoxRotation, setTextBoxRotation] = useState();
  
  //State for Text and Image Objects
  const [objectState, setObjectState] = useState([]);

  const [objectIndex, setObjectIndex] = useState(0);

  //State of inputs to edit the text
  const [lotSize, setLotSize] = useState(100);
  const [currentTextKey, setCurrentTextKey] = useState();
  const [fontSize, setFontSize] = useState(10);
  const [isBold, setTextBold] = useState("");
  const [isItalic, setTextItalic] = useState("");
  const [textDecoration, setTextDecoration] = useState("");
  const [fontFamily, setFontFamily] = useState("Arial");
  const [textColor, setTextColor] = useState("#000000");
  const [textAlignment, setTextAlignment] = useState("left");

  const fontList = ["Arial", "Times New Roman", "Courier New", "Calibri", "Comic Sans MS", "Monotype Corsiva"];

  //Text Area state, which is used to edit text values in TextBox
  const [textAreaVisibility, setTextAreaVisibility] = useState("none");
  const [textAreaStyle, setTextAreaStyle] = useState({});
  const[currentTextBoxText, setCurrentTextBoxText] = useState('');
  const[currentTextRef, setCurrentTextRef] = useState(null);

  const stageRef = useRef();

  let token, member;

  try {
      token = JSON.parse(atob(cookies.get("GG_token")));
      member = atob(cookies.get("__User_Ver"));    
  }
  catch(err) {}
  
  useEffect(() => {
    if(token === undefined || token === null) {
      history.push("/login");
    } else if(member !== '"members"') {
      history.push("/unauthorized");
    }
    else if(window.screen.width < 600) {
      history.replace('/mobileViewNotAvailable')
    } else {
      document.getElementsByTagName('BODY')[0].style.background = 'white';
      getTemplates(token, templatesPage)
      .then(response => {
        setTemplates(response.data.data)
        let temps = response.data.data;

        let templatesArr = [];
        for(let i = 0; i<temps?.length; i++) {
          templatesArr.push({value: temps[i]?.id, label: temps[i]?.templateName})
        }

        console.log(templatesArr)

        setTemplateOptions(templatesArr);
      })
      .catch(err => {
        console.log(err)
        toast.error("Error while retrieving templates.", {
          position: 'top-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false
        })
      })
    }
  }, [])

  useEffect(() => {
    let tempId = templateData?.templateMeta?.templateDetails?.id;
    if(tempId !== null && tempId !== undefined && tempId !== "") {
      setSelectedTemplate(tempId);
      setTemplateName(templateData?.templateMeta?.templateDetails?.templateName);
      // loadTemplate()
      setEditTemplate(true);
    }
  }, [templateData])

  useEffect(() => {
    if(templateId !== null && templateId !== undefined && templateId !== "") {
      console.log(templateId)
      loadTemplate()
    }
  }, [templateId])

  useEffect(() => {
    for(let i=0; i<templates?.length; i++) {
      if(templates[i]?.id == selectedTemplate) {

        setTemplateName(templates[i]?.templateName);
        if(selectedTemplate !== null && selectedTemplate!== undefined && selectedTemplate !== "") {
          loadTemplate();
        }
        else {
          setSelectedTemplate(templates[i]?.id);
        }
        setEditTemplate(true);
      }
    }
    if(templateId !== null && templateId !== undefined && templateId !== "") {
      loadTemplate()
    }
  }, [templates])

  useEffect(() => {
    // if(templateData?.templateMeta !== null && templateData?.templateMeta !== undefined && templateData?.templateMeta !== "") {
      for(let i=0; i<templates?.length; i++) {

        if(templates[i]?.id === selectedTemplate) {
          setTemplateName(templates[i]?.templateName);
          loadTemplate();
        }
      }  
    // }
  }, [selectedTemplate])

  useEffect(() => {
    let selectedText;

    if(currentTextKey || currentTextKey == 0) {
        for(let i=0; i < objectState?.length; i++) {
            if(currentTextKey == objectState[i]?.index) {
                selectedText = objectState[i];                
            }
        }

      if(selectedText?.fontStyle == "bold italic") {
         setTextBold("bold");
         setTextItalic("italic");
      }
      else if(selectedText?.fontStyle == "bold") {
        setTextBold("bold");
        setTextItalic("");
      }
      else if(selectedText?.fontStyle == "italic") { 
        setTextItalic("italic");
        setTextBold("")
      }
      else {
        setTextBold("");
        setTextItalic("");
      }

      setFontFamily(selectedText?.fontFamily);
      setTextAlignment(selectedText?.textAlignment);
      setFontSize(selectedText?.fontSize);
      setTextDecoration(selectedText?.textDecoration);
      setCurrentTextBoxText(selectedText?.text);
      setTextBoxRotation(selectedText?.rotation)
      setTextColor(selectedText?.textColor);
    }
  }, [currentTextKey]); 
  
  useEffect(() => {
    if(currentTextKey || currentTextKey == 0) {
      let selectedText = objectState.filter((element) => { return element.index == currentlySelected?.index });
      selectedText = selectedText[0];

      let newState = objectState;
      
      for(let i=0; i<newState?.length; i++) {
            if(newState[i]?.index == currentlySelected.index) {
                newState[i].fontFamily = fontFamily;
                newState[i].fontSize = fontSize;
                newState[i].fontStyle = (isBold+" "+isItalic).trim();
                newState[i].textDecoration = textDecoration;
                newState[i].text = currentTextBoxText;
                newState[i].textColor = textColor;
                newState[i].textAlignment = textAlignment;
            }
        }

      setObjectState([...newState]);
    } 
  }, [fontFamily, fontSize, currentTextBoxText, isBold, isItalic, textDecoration, textAlignment, textColor]);

  const loadOptions = async (search, prevOptions) => {
    if (!search) {
      try {
        if(!initialLoad) {
          setInitialLoad(true);
          return {
            options: templateOptions,
            hasMore: true
          };
        }
        else {
          let newPage = templatesPage + 1;
          setTemplatesPage(newPage);
          let res = await getTemplates(token, newPage)
          setTemplates(templates.concat(res.data.data))
          let temps = res.data.data;

          let templatesArr = [];
          for(let i = 0; i<temps?.length; i++) {
            templatesArr.push({value: temps[i]?.id, label: temps[i]?.templateName})
            console.log("HERE")
          }

          setTemplateOptions(templateOptions.concat(templatesArr));
          setTemplatesPage(templatesPage + 1);

          let hasMore = true;
          if(res.data.data.length == 0) {
            hasMore = false;
          }

          return {
            options: templatesArr,
            hasMore: hasMore
          };
        }
      }
      catch(err) {
        console.log(err)
        toast.error("Error while retrieving templates.", {
          position: 'top-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false
        });
      }
    }
  }

  //TextBox Methods
  const createTextBox = () => {
    const key = objectIndex;
    setObjectIndex(key+1);

    setObjectState(objectState.concat({
      index: key,
      type: "TextBox",
      text: "Text Box "+key,
      fontFamily: fontFamily,
      fontSize: fontSize,
      fontStyle:  (isBold + " " + isItalic).trim(),
      textDecoration: textDecoration,
      textAlignment: textAlignment,
      rotation: 0,
      textColor: textColor,
      isSelected: false,
      x: canvasDetails?.width/2,
      y: canvasDetails?.height/2
    }));
  }

  const changeFontSizeAccordingToCanvas = (newCanvasSize) => {
    if(Math.min(newCanvasSize.width, newCanvasSize.height) < 7) {
      setFontSize(5);
    }
    else if(Math.min(newCanvasSize.width, newCanvasSize.height) < 12) {
      setFontSize(8);
    }
    else if(Math.min(newCanvasSize.width, newCanvasSize.height) < 35) {
      setFontSize(11);
    }
    else {
      setFontSize(12);
    }
  }

  const onTextBoxSelect = (e, key) => {
    setCurrentTextKey(key);
    setCurrentlySelected({ type: "EditableText", index: key });

    if(currentTextRef !== null) {
      currentTextRef.current.show();
      setTextAreaVisibility("none");
    }

    setSelectedImageKey(null);

    let newState = objectState;

    for(let i=0; i<newState?.length; i++) {
        if(newState[i]?.index == key) {
            newState[i].x = e.attrs.x;
            newState[i].y = e.attrs.y;    
            setCurrentTextBoxText(newState[i].text);
        }
    }

    setObjectState([...newState]);
  }

  const onTextBoxChange = (key, updatedVars) => {
    let newState = objectState;

    for(let i=0; i<newState?.length; i++) {
        if(newState[i]?.index == key) {
            newState[i].x = updatedVars.x;
            newState[i].y = updatedVars.y;
            newState[i].fontSize = updatedVars.fontSize;
            newState[i].rotation = updatedVars.rotation;
            newState[i].width = updatedVars.width;    
            newState[i].textAlignment = updatedVars.textAlignment; 
            newState[i].height = updatedVars.height;       
        }
    }

    setObjectState([...newState]);
  }

  const addTextArea = (coordinates) => {
    setTextAreaVisibility("block");

    setCurrentTextRef(coordinates.textRef);
    coordinates.textRef.current.hide();
    setTextAreaStyle({x: coordinates.x, y: coordinates.y, width: coordinates.width, height: coordinates.height, 
                      fontSize: coordinates.fontSize, lineHeight: coordinates.lineHeight, textColor: coordinates.textColor, currentTextRef: coordinates.textRef});
  }

  // Image Methods
  const createImage = () => {
    if(currentImage) {
      const key = objectIndex;
      setObjectIndex(key+1);

      let newImage = new Image();
    
      newImage.src = currentImage;

      newImage.onload = function() {
        var height = newImage.height;
        var width = newImage.width;

        setImageObjects(imageObjects.concat(newImage));

        setObjectState(objectState.concat({
            index: key,
            type: "Image",
            imgURL: currentImage,
            width: width/2,
            height: height/2,
            image: newImage,
            x: 50,
            y: 50,
            rotation: 0
        }))
      }
    }
  }

  const onImageSelect = (e, key) => {
    setSelectedImageKey(key);

    setCurrentTextKey(null);

    setCurrentlySelected({ type: "EditableImage", index: key });
  }

  const onImageChange = (key, updatedVars) => {
    let newState = objectState;

    for(let i=0; i<newState?.length; i++) {
        if(newState[i]?.index == key) {
            newState[i].x = updatedVars.x;
            newState[i].y = updatedVars.y;
            newState[i].width = updatedVars.width;
            newState[i].height = updatedVars.height;
            newState[i].rotation = updatedVars.rotation;
        }
    }

    setObjectState([...newState]);
  }

  const createNewTemplate = () => {
    setTemplateState(null);

    setEditTemplate(false)
    setTemplateName("")

    setObjectState([]);
    setExtendImageIntoBleed(false);
    // setBleedColor('FFFFF');
    setPredefinedSize('A4');
    setCanvasSize({width: 297, height: 210});
    setCanvasDetails({canvasSize: 'A4', width: 297, height: 210})
    setBackgroundImage(null);
    setOrientation("landscape");
    setFilenameColumn("");
    setLotSize(100);
    setDpi(300);
    setBleedColor("#FFFFFF");
    setObjectIndex(0);
    setBgImageObject(null);
    setBleed({top: 0, left: 0, bottom: 0, right: 0})
  }

  // Add/Delete background image
  const setBackgroundImage = (add) => {
    if(currentImage) {
      if(add) {
        let newImage = new Image();
        newImage.src = currentImage;

        setBgImageObject(newImage);
        setBackground({ imgURL: currentImage });
        setIsBgAdded(true);
        }
        else {
          setBgImageObject(null);
          setBackground({ imgURL: "" })
          setIsBgAdded(false);
        }  
    }
    else {
      setBgImageObject(null);
      setBackground({ imgURL: "" })
      setIsBgAdded(false);
    }  
  }

  // Shift elements up and down methods
  const shiftUp = () => {
    let currentIndex = currentlySelected?.index;
    let arrayIndex = null;

    for(let i=0; i<objectState?.length; i++) {
      if(objectState[i]?.index == currentIndex) {
        arrayIndex = i;
      }
    }

    let newState = objectState;

    if(!(arrayIndex+1 > newState.length +1)) {
      let temp = newState[arrayIndex];
      newState[arrayIndex] = newState[arrayIndex+1];
      newState[arrayIndex+1] = temp; 
    }

    setObjectState([...newState]);
  }

  const shiftDown = () => {
    let currentIndex = currentlySelected?.index;
    let arrayIndex = null;

    for(let i=0; i<objectState?.length; i++) {
      if(objectState[i]?.index == currentIndex) {
        arrayIndex = i;
      }
    }

    let newState = objectState;

    if(!(arrayIndex-1 < 0)) {
      let temp = newState[arrayIndex];
      newState[arrayIndex] = newState[arrayIndex-1];
      newState[arrayIndex-1] = temp; 
    }

    setObjectState([...newState]);
  }

  // Delete and Unselect Methods
  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target.attrs.id === "bg-image"; 
    if(clickedOnEmpty) {
      setSelectedImageKey(null);
      setCurrentTextKey(null);
      setTextAreaVisibility("none");
      setCurrentlySelected({type: null, index: null});
      if(currentTextRef !== null) {
        currentTextRef.current.show();
        setCurrentTextRef(null);
      }
    }
  }

  const deleteItem = () => {
    if(currentlySelected.type === "EditableText") {
      let newState = [];
           
      for(let i=0; i<objectState?.length; i++) {
          if(objectState[i]?.index != currentlySelected.index) {
              newState.push(objectState[i]);
            }
        }
      setObjectState([...newState]);
      
      setTextAreaVisibility("none");
      setCurrentTextRef(null);

      setCurrentlySelected({type: null, index: null});
      setCurrentTextKey(null);
    }
    else if(currentlySelected.type === "EditableImage") {
      let newState = [];

    for(let i=0; i<objectState?.length; i++) {
        if(objectState[i]?.index != currentlySelected.index) {
            newState.push(objectState[i]);
          }
      }
      setObjectState([...newState]);
      setCurrentlySelected({type: null, index: null});
    }
    else {

    }
  }

  const loadTemplate = (tempSelectedTemplate) => {
    try {
      let currentlySelectedTemplate;

      if(checkIfNotNullOrUndefinedOrEmpty(tempSelectedTemplate)) {
        setSelectedTemplate(tempSelectedTemplate);
        currentlySelectedTemplate = tempSelectedTemplate;
      }
      else {
        if(checkIfNotNullOrUndefinedOrEmpty(selectedTemplate)) {
          currentlySelectedTemplate = selectedTemplate;
        }
        else {
          toast.error("Please select a template to open.", {
            position: 'top-left',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false
          })
          return;
        }
      }
  
      let state;

      setBackgroundImage(null);

      for(let j=0; j<templates?.length; j++) {
        if(templates[j]?.id == currentlySelectedTemplate) {
          if(typeof templates[j]?.meta === 'string') {
            state = JSON.parse(templates[j]?.meta);
          }
          else {
            state = templates[j]?.meta;
          }
        }
      }

      setPredefinedSize(state?.canvasDetails?.canvasSize)
      setCanvasDetails(state?.canvasDetails)
  
      for(let i=0; i<state?.objectState.length; i++) {
        if(state.objectState[i].type === "TextBox") {
          state.objectState[i].fontFamily = state.objectState[i].fontFamily.replaceAll("-", " ");
        }
  
        if(state.objectState[i].type === "Image") {
          let newImage = new Image();
  
          newImage.src = state.objectState[i].imgURL;
  
          state.objectState[i].image = newImage;
        }
  
        if(i == state?.objectState.length - 1) {
          setObjectIndex(state?.objectState[i]?.index + 1);
        }
  
        for(let i=0; i<templates?.length; i++) {
          if(templates[i]?.id === currentlySelectedTemplate) {
            setTemplateName(templates[i]?.templateName);
          }
        }
      }
  
      if(state?.background?.imgURL !== "" && state?.background?.imgURL !== null && state?.background?.imgURL !== undefined) {
        let bgImage = new Image();

        bgImage.src = state?.background?.imgURL;
        setBgImageObject(bgImage);
        // setBackground(true);
        setIsBgAdded(true);
        // setCurrentImage(state?.background?.imgURL);
      }
  
      setEditTemplate(true);
      
      setPredefinedSize(state?.canvasDetails?.canvasSize);
      setCanvasDetails({
        canvasSize: state?.canvasDetails?.canvasSize, 
        width: state?.canvasDetails?.width,
        height: state?.canvasDetails?.height,
      })
      setCanvasSize({width: state?.canvasDetails?.width/10, height: state?.canvasDetails?.height/10});
      setObjectState(state?.objectState);
      setBackground(state?.background);
      setOrientation(state?.orientation);
      setFilenameColumn(state?.canvasDetails?.filenameColumn);
      if(checkIfNotNullOrUndefinedOrEmpty(state?.canvasDetails?.dpi)) {
        setDpi(state?.canvasDetails?.dpi);
      }
      else {
        setDpi(300);
      }
      if(checkIfNotNullOrUndefinedOrEmpty(state?.canvasDetails?.extendImageIntoBleed)) {
        setExtendImageIntoBleed(state?.canvasDetails?.extendImageIntoBleed);
      }
      else {
        setExtendImageIntoBleed(false);
      }
      setLotSize(state?.canvasDetails?.lotSize);
      setOutputType(state?.canvasDetails?.outputType);
      if(checkIfNullOrUndefinedOrEmpty(state?.canvasDetails?.bleed)) {
        setBleed({top: 0, left: 0, right: 0, bottom: 0});
      }
      else {
        setBleed(state?.canvasDetails?.bleed);
      }

      if(checkIfNullOrUndefinedOrEmpty(state?.canvasDetails?.bleedColor)) {
        setBleedColor('#FFFFFF');
        console.log("first");
      }
      else {
        setBleedColor(state?.canvasDetails?.bleedColor);
        console.log("second");
      }
    }
    catch(error) {
      toast.error("Error in loading template", {
        position: 'top-left',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false
      })
    }
  }

  const saveProjectSettings = (settings, resetCanvas) => {
    setOrientation(settings.orientation);
    setColorspace(settings.colorspace);
    setLotSize(settings.lotSize);
    setDpi(settings.dpi);
    setOutputType(settings.outputType);
    setPredefinedSize(settings.predefinedSize);
    setFilenameColumn(settings.filenameColumn);
    setBleed(settings.bleed);
    setBleedColor(settings.bleedColor);

    if(settings.predefinedSize === "Custom") {
      setCanvasDetails({canvasSize: 'Custom', width: (settings?.canvasSize.width)*10, height: (settings?.canvasSize.height)*10}); 
      changeFontSizeAccordingToCanvas(settings?.canvasSize);
    }
    else {
      let sizes = Config.predefinedCanvasSizes;
      if(settings.orientation === 'landscape') {
        setCanvasDetails({canvasSize: settings.predefinedSize, width: sizes[settings.predefinedSize].width, height: sizes[settings.predefinedSize].height});
      }
      else {
        setCanvasDetails({canvasSize: settings.predefinedSize, width: sizes[settings.predefinedSize].height, height: sizes[settings.predefinedSize].width});
      }
      changeFontSizeAccordingToCanvas(settings?.predefinedSize);
    }

    // let newDetails = {canvasSize: settings.predefinedSize, height: settings.canvasSize.height*10, width: settings.canvasSize.width*10};
    // setCanvasDetails({...newDetails});

    if(resetCanvas) {
      setObjectState([]);
      setBackground(null);
      setBgImageObject(null);
      setBackgroundImage(false);
    }
  }

  // Log JSON coordinates and other attributes of the canvas
  const saveTemplate = (newTemplateName) => {
    let newCanvasDetails = canvasDetails;
    newCanvasDetails['colorspace'] = colorspace;
    newCanvasDetails['outputType'] = outputType;
    newCanvasDetails['lotSize'] = lotSize;
    newCanvasDetails['DPI'] = dpi;
    newCanvasDetails['filenameColumn'] = (lotSize == 1 ? filenameColumn : '');
    newCanvasDetails['bleed'] = bleed;
    newCanvasDetails['bleedColor'] = bleedColor;

    let newObjectState = [];

    for(let i=0; i<objectState.length; i++) {
      if(objectState[i].type === "TextBox" && objectState[i].text !== '' 
        && objectState[i].text !== undefined && objectState[i].text !== null) 
      {
        let stateObj = objectState[i];
        stateObj.fontFamily.replaceAll(" ", "-");
        if(stateObj.fontFamily === "Monotype Corsiva") {
          stateObj.fontStyle = "";
        }
        newObjectState.push(stateObj);
      }
    }

    const tempState = {canvasDetails: newCanvasDetails, objectState: newObjectState, background, orientation}    
    const state = JSON.parse(JSON.stringify(tempState));

    setTemplateState({newObjectState});

    if(templateName === "" || templateName === null || templateName === undefined) {
      if(checkIfNullOrUndefinedOrEmpty(newTemplateName)) {
        toast.error("Please add a template name before saving.", {
          position: 'top-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false
        })
        setShowRenameModal(true);
        return;  
      }
    }

    let finalTemplateName = checkIfNullOrUndefinedOrEmpty(templateName) ? newTemplateName : templateName;

    if(editTemplate) {
      // return;
      updateCertificateTemplate(token, clientId, selectedTemplate, finalTemplateName, state)
        .then(response => {
          let newTemplatesData = templates;
          for(let i=0; i<newTemplatesData?.length; i++) {  
            if(newTemplatesData[i]?.id === selectedTemplate) {
              newTemplatesData[i].templateName = finalTemplateName;
              newTemplatesData[i].meta = JSON.stringify(state);
            }
          }

          setTemplateState({ templateDetails: {id: selectedTemplate, templateName: finalTemplateName}, state});
          setTemplates([...newTemplatesData])

          toast.success("Template updated with name '"+templateName+"'", {
            position: 'top-left',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false
          })

          history.push({pathname: "/preview/"+selectedTemplate})
        })
        .catch(err => {
          toast.error("Error while updating template.", {
            position: 'top-left',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false
          })
        })
    } else {
          // return;
          createCertificateTemplate(token, clientId, finalTemplateName, state)
          .then(response => {
            toast.success("Template saved with name '"+finalTemplateName+"'", {
              position: 'top-left',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: false
            })

            setTemplateState({ templateDetails: {id: response?.data?.data?.id, templateName: finalTemplateName}, state});
            setTemplates([...templates.concat({id: response?.data?.data?.id, templateName: finalTemplateName})])

            history.push({pathname: "/preview/"+response?.data?.data?.id})
          })
          .catch(err => {
            toast.error("Error while saving template.", {
              position: 'top-left',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: false
            })
          })
    }
  }

  return (
    <div className='bg-white h-[90vh]'>
      <EditorHeader
        saveTemplate={saveTemplate}
        templates={templates}
        savedData={savedData}
        setSelectedTemplate={setSelectedTemplate}
        templateName={templateName}
        setTemplateName={setTemplateName}
        setSavedData={setSavedData}
        setEditTemplate={setEditTemplate}
        loadTemplate={loadTemplate}
        createNewTemplate={createNewTemplate}
        showRenameModal={showRenameModal}
        setShowRenameModal={setShowRenameModal}
        editTemplate={editTemplate}
        loadOptions={loadOptions}
        templateOptions={templateOptions}
        // setShowSettingsModal={setShowSettingsModal}
      />
      <main className="main-container flex mx-0"> 
        <section className="w-3/12 px-0 h-5/6">
          <Sidebar
            setObjectState={setObjectState}
            createTextBox={createTextBox}
            fontSize={fontSize}
            fontFamily={fontFamily}
            isBold={isBold}
            isItalic={isItalic}
            textDecoration={textDecoration}
            setFontSize={setFontSize}
            setFontFamily={setFontFamily}
            setTextBold={setTextBold}
            setTextItalic={setTextItalic}
            setTextDecoration={setTextDecoration}

            currentImageId={currentImageId}
            setCurrentImageId={setCurrentImageId}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
            createImage={createImage}

            background={background}
            bgImageObject={bgImageObject}
            setBackgroundImage={setBackgroundImage} 

            fontList={fontList}

            orientation={orientation}
            setOrientation={setOrientation}

            colorspace={colorspace}
            setColorspace={setColorspace}

            canvasSize={canvasSize}
            setCanvasSize={setCanvasSize}

            predefinedSize={predefinedSize}
            setPredefinedSize={setPredefinedSize}

            shiftUp={shiftUp}
            shiftDown={shiftDown}

            isBgAdded={isBgAdded}
          />
        </section>
        <section className="w-9/12 px-0">
        <Topbar 
          fontList={fontList}
          createTextBox={createTextBox}
          deleteItem={deleteItem}
          fontSize={fontSize}
          fontFamily={fontFamily}
          isBold={isBold}
          isItalic={isItalic}
          textColor={textColor}
          textDecoration={textDecoration}
          setFontSize={setFontSize}
          setFontFamily={setFontFamily}
          setTextBold={setTextBold}
          setTextItalic={setTextItalic}
          setTextDecoration={setTextDecoration}
          setTextColor={setTextColor}
          shiftDown={shiftDown}
          shiftUp={shiftUp}
          textAlignment={textAlignment}
          setTextAlignment={setTextAlignment}
          setShowSettingsModal={setShowSettingsModal}
        />
        <Canvas 
            stageRef={stageRef}
            canvasDetails={canvasDetails}
            onTextBoxSelect ={ onTextBoxSelect } 
            onTextBoxChange={onTextBoxChange}
            currentTextKey={currentTextKey} 
            selectedImageKey = {selectedImageKey}
            onImageSelect={onImageSelect} 
            imageObjects={ imageObjects } 
            onImageChange={ onImageChange } 
            background={ background } 
            bgImageObject = { bgImageObject }
            checkDeselect = {checkDeselect}
            addTextArea={addTextArea}
            textAreaVisibility={textAreaVisibility}
            setTextAreaVisibility={setTextAreaVisibility}
            textAlignment={textAlignment}
            textAreaStyle = {textAreaStyle}
            setTextAreaStyle={setTextAreaStyle}
            currentTextBoxText={currentTextBoxText}
            setCurrentTextBoxText = {setCurrentTextBoxText}
            orientation={orientation}
            objectState={objectState}
            fontFamily={fontFamily}
            fontSize={fontSize}
            isBold={isBold}
            isItalic={isItalic}
            textBoxRotation={textBoxRotation}
            textColor={textColor}
            bleed={bleed}
            bleedColor={bleedColor}
            />
        </section>
        {
          showSettingsModal && 
          <SettingsModal
          objectState={objectState}
          canvasDetails={canvasDetails}
          showSettingsModal={showSettingsModal}
          setShowSettingsModal={setShowSettingsModal}
          colorspace={colorspace}
          outputType={outputType}
          lotSize={lotSize}
          dpi={dpi}
          predefinedSize={predefinedSize}
          orientation={orientation}
          filenameColumn={filenameColumn}
          bleed={bleed}
          bleedColor={bleedColor}
          saveProjectSettings={saveProjectSettings}
        />
        }
        
      </main>
      <div className='py-5 my-2 bg-white'>
          
        </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    setTemplateState: (value) => {
      dispatch(setTemplateMeta(value));
    }
  } 
}

export default connect(mapStateToProps, mapDispatchToState)(TemplateEditor);
 
// export default TemplateEditor;