import { Stage, Layer, Image } from 'react-konva';
import EditableText from '../EditableText/EditableText';
import EditableImage from '../EditableImage/EditableImage';

import { useRef, useState, useEffect } from 'react';

const Canvas = ({stageRef, canvasDetails, onTextBoxSelect, currentTextKey, selectedImageKey, onImageSelect, checkDeselect,
                onTextBoxChange, onImageChange, bgImageObject, addTextArea, textAreaVisibility, setTextAreaVisibility, 
                textAreaStyle, currentTextBoxText, setCurrentTextBoxText, 
                objectState, fontFamily, fontSize, textAlignment, isBold, isItalic, textBoxRotation, textColor, bleed, bleedColor}) => {

    let objects = [];
    const bgImageRef = useRef();
    const containerRef = useRef();

    const [baseWidth, setBaseWidth] = useState(canvasDetails?.width);
    const [baseHeight, setBaseHeight] = useState(canvasDetails?.height);

    const [widthByHeight, setWidthByHeight] = useState((canvasDetails.width/canvasDetails.height).toFixed(4));
    const [heightByWidth, setHeightByWidth] = useState((canvasDetails.height/canvasDetails.width).toFixed(4));

    const initializeSize = () => {
        if(canvasDetails.width >= canvasDetails?.height) {
            if(canvasDetails.width/canvasDetails?.height < 1.3) {
                return {
                    width: (window.innerWidth/3),
                    height: (window.innerWidth/3)/((canvasDetails.width/canvasDetails.height).toFixed(4))
                }
            }
            else {
                return {
                    width: (window.innerWidth/2),
                    height: (window.innerWidth/2)/((canvasDetails.width/canvasDetails.height).toFixed(4))
                }
            }
        }
        else {
            return {
                width: (window.innerHeight/1.4)/((canvasDetails.height/canvasDetails.width).toFixed(4)),
                height: (window.innerHeight/1.4)
            }
        }
    }

    const [size, setSize] = useState(initializeSize());

    let scaleX, scaleY;

    scaleX = (size.width / baseWidth);
    scaleY = (size.height / baseHeight);

    useEffect(() => {
        setWidthByHeight((canvasDetails.width/canvasDetails.height).toFixed(4))
        setHeightByWidth((canvasDetails.height/canvasDetails.width).toFixed(4))

        setBaseHeight(canvasDetails?.height);
        setBaseWidth(canvasDetails?.width);
    }, [canvasDetails])

    useEffect(() => {
        const checkSize = () => {
            if(canvasDetails?.width >= canvasDetails?.height) {
                if(canvasDetails.width/canvasDetails?.height < 1.3) {
                    setSize({
                        width: window.innerWidth/3,
                        height: (window.innerWidth/3)/widthByHeight
                    })            
                }
                else {
                    setSize({
                        width: window.innerWidth/2,
                        height: (window.innerWidth/2)/widthByHeight
                    })            
                }
            }
            else {
                setSize({
                    width: (window.innerHeight/1.4)/heightByWidth,
                    height: (window.innerHeight/1.4)
                })        
            }
        }

        checkSize();

        window.addEventListener("resize", checkSize)
        return () => window.removeEventListener("resize", checkSize)
    }, [baseHeight, baseWidth, widthByHeight, heightByWidth, canvasDetails])


    for(let i=0; i<objectState?.length; i++) {
        if(objectState[i]?.type === "Image") {
            objects.push(
                <EditableImage
                    key={ objectState[i].index}
                    index={ objectState[i].index }
                    stage={stageRef}
                    stageSize={size}
                    image={ objectState[i].image }
                    onSelect={ (e) => onImageSelect(e, objectState[i].index) }
                    isSelected={ objectState[i].index === selectedImageKey }
                    onImageChange={onImageChange}
                    width={objectState[i].width}
                    height={objectState[i].height}
                    rotation={objectState[i].rotation}
                    x={objectState[i].x}
                    y={objectState[i].y}
                    draggable
                />);
        } else if(objectState[i]?.type === "TextBox") {
            objects.push(
                <EditableText 
                    key={objectState[i].index} 
                    index={objectState[i].index}
                    stage={stageRef}
                    stageSize={size}
                    stageScaleX={scaleX}
                    stageScaleY={scaleY}
                    text={objectState[i].text}
                    fontFamily={objectState[i].fontFamily}
                    fontSize={objectState[i].fontSize}
                    fontStyle={objectState[i].fontStyle}
                    textDecoration={objectState[i].textDecoration}
                    textColor={objectState[i].textColor}
                    isSelected={ objectState[i].index === currentTextKey }
                    onSelect={ (e) => onTextBoxSelect(e.target, objectState[i].index) }
                    onTextBoxChange={onTextBoxChange}
                    rotation={objectState[i].rotation}
                    x={objectState[i].x}
                    y={objectState[i].y}
                    addTextArea={addTextArea}
                    width={objectState[i].width}
                    textAlignment={objectState[i].textAlignment}
                    bleed={bleed}
                />
            );
        }
    }

    return (
        <section id="canvas" ref={containerRef} className="flex flex-column justify-center items-center bg-white mt-5">
            <div id="stage-container" ref={bgImageRef}>
                <div
                    className={ bleed?.top != 0 || bleed?.left != 0 || bleed?.bottom != 0 || bleed?.right != 0 ? 'border-2 border-sky-500 bg-white' : ''} 
                    style={{
                        backgroundColor: bleedColor,
                        paddingTop: bleed?.top*scaleY +'px',
                        paddingLeft: bleed?.left*scaleX +'px',
                        paddingBottom: bleed?.bottom*scaleY+'px',
                        paddingRight: bleed?.right*scaleX+'px',
                    }}
                >
                    <Stage 
                        id="stage"
                        width={ size.width }
                        height={ size.height }
                        scaleX={ scaleX }
                        scaleY={ scaleY }
                        onMouseDown={checkDeselect}
                        ref={stageRef}
                    >
                        <Layer>
                            <Image 
                                id="bg-image"
                                image={ bgImageObject }
                                width={ size.width/scaleX }
                                height={ size.height/scaleY }
                                x={0}
                                y={0}
                            />
                            { objects.map(element => element )}
                        </Layer>
                    </Stage>
                </div>
            </div>
           
            <textarea
                className='z-10 outline-none bg-transparent p-0 m-0'
                style={{ 
                        position: "absolute", 
                        display: textAreaVisibility, 
                        width:  (textAreaStyle.width*scaleX)+2+"px",
                        height: (textAreaStyle.height*scaleY)+2+"px",
                        fontSize: fontSize*scaleX+"px",
                        fontFamily: fontFamily,
                        resize: "none",
                        color: textColor,
                        // transform: "rotate("+ textBoxRotation +"deg)",
                        transformOrigin: "top left",
                        lineHeight: textAreaStyle.lineHeight,
                        border: "1px solid teal",
                        textAlign: textAlignment,
                        overflow: 'hidden',
                        fontWeight: isBold ? 'bold' : '',
                        fontStyle: isItalic ? 'italic' : '',
                        left: ( parseInt(textAreaStyle.x) + (bleed?.left*scaleX) + 1 + (bgImageRef.current != undefined ? parseInt(bgImageRef.current.offsetLeft) : 0))+"px", 
                        top: ( parseInt(textAreaStyle.y) + (bleed?.top*scaleY) + 1 + (bgImageRef.current != undefined ? parseInt(bgImageRef.current.offsetTop) : 0))+"px",
                    }}
                value={currentTextBoxText}
                onChange={(e) => {
                    setCurrentTextBoxText(e.target.value);
                }}  
                onKeyDown={(e) => {
                    if(e.key === 'Enter' && !e.shiftKey) {
                        setTextAreaVisibility("none");
                        textAreaStyle?.currentTextRef?.current?.show()
                    }
                }}
            />
            
        </section>
    )
}

export default Canvas;