import { Fragment, useRef, useEffect } from "react";
import { Image, Transformer } from "react-konva";

const EditableImage = ({ index, width, height, x, y, image, onSelect, isSelected, rotation, imgProps, onImageChange}) => {

    const editableImageRef = useRef();
    const transformerRef = useRef();

    useEffect(() => {
        if (isSelected) {
          // we need to attach transformer manually
          transformerRef.current.nodes([editableImageRef.current]);
          transformerRef.current.getLayer().batchDraw();
        }
      }, [isSelected]);

    return (
        <Fragment>
            <Image
                key={ index }
                image={image}
                ref={editableImageRef}
                onClick={ onSelect }
                onTap={ onSelect }
                width={width}
                height={height}
                rotation = {rotation}
                x={x}
                y={y}
                onTransformEnd = {(e) => {
                    const node = editableImageRef.current;
                    const trNode = transformerRef.current
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onImageChange(index, {
                        ...imgProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                        rotation: node.rotation()
                    });
                }}
                onDragEnd = {(e) => {
                    const node = editableImageRef.current;
                    const trNode = transformerRef.current
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    // node.scaleX(1);
                    // node.scaleY(1);
                    onImageChange(index, {
                        ...imgProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                        rotation: node.rotation()
                    });
                }}
                draggable
            />
            {   isSelected && (
                <Transformer
                        ref={transformerRef}
                        borderStroke={"teal"}
                        anchorStroke={"teal"}
                        centredScaling={true}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                              return oldBox;
                            }
                            return newBox;
                        }}
                />
            )
            }
        </Fragment>
    );
}

export default EditableImage;