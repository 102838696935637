import React from 'react'

function Payments() {
    return (
        <>
            <div className='container 2xl:max-w-7xl mx-auto'>
                <div className="container mx-auto py-6 px-4">
                    <h1 className="text-3xl py-4 border-b mb-10">Payment history</h1>
                    <div className="mb-4 flex justify-between items-center">
                        <div className="flex-1 pr-4">
                            <div className="relative md:w-1/3">
                                <input type="search"
                                    className="w-full pl-10 pr-4 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                    placeholder="Search..." />
                                <div className="absolute top-0 left-0 inline-flex items-center p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                        <circle cx="10" cy="10" r="7" />
                                        <line x1="21" y1="21" x2="15" y2="15" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <button className="border rounded-md shadow-md px-3 py-1 text-gray-600 font-medium bg-white hover:bg-gray-200">Add Credits</button>
                        </div>
                    </div>

                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Sr.no
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Transaction Name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Transaction Id
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Transaction Date
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Transaction Amount
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Transaction Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border-dashed border-t border-gray-200 userId">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">01</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">Mukesh Swamy</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 lastName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">10A343F67HG</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 emailAddress">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">14/01/2021</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 gender">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">5,000</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">success</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payments
