import { Text, TextPath, Transformer, Rect } from 'react-konva';
import { Fragment, useRef, useEffect } from 'react';

const EditableText = ({index, stageSize, stageScaleX, stageScaleY, onSelect, x, y, text, fontSize, fontFamily, fontStyle, 
                    textDecoration, isSelected, rotation, onTextBoxChange, addTextArea, textColor, width, textAlignment, bleed}) => {

    const editableTextRef = useRef();
    const transformerRef = useRef();

    useEffect(() => {
        if (isSelected) {
          // we need to attach transformer manually
          transformerRef.current.nodes([editableTextRef.current]);
          transformerRef.current.getLayer().batchDraw();
        }
      }, [isSelected]);

    const onTextDblClick = () => {
        let node = editableTextRef.current;
        let tr = transformerRef.current;

        const textPosition = node.absolutePosition();

        let areaPosition = {
            x: textPosition.x,
            y: textPosition.y,
            width: node.width() * node.scaleX(),
            height: node.height() * node.scaleY(),
            textAlignment: node.align(),
            fontSize: node.fontSize(),
            textColor: node.fill(),
            lineHeight: node.lineHeight(),
            textRef: editableTextRef,
        }

        addTextArea(areaPosition);

        node.scaleX(1);
        node.scaleY(1);
    }

    const onTextTransform = () => {
        const node = editableTextRef.current;

        onTextBoxChange(index, {
            x: node.x(),
            y: node.y(),
            width: node.width() * node.scaleX(),
            height: node.height() * node.scaleY(),
            textAlignment: node.align(),
            fontSize: node.fontSize(),
            rotation: node.rotation()
        });

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);
    }

    const onTextDragMove = () => {
        const node = editableTextRef.current;

        const absPos = node.getAbsolutePosition();

        const newAbsPos = { ...absPos };

        if (absPos.x < (0)) {
            newAbsPos.x = 0;
        }
        if (node.y() < (0)) {
            newAbsPos.y = 0;
        }
        if (absPos.x + (node.width()*stageScaleX) > (stageSize.width)) {
            newAbsPos.x = (stageSize.width) - (node.width()*stageScaleX);
        }
        if (absPos.y + (node.height()*stageScaleY) > (stageSize.height)) {
            newAbsPos.y = (stageSize.height) - (node.height()*stageScaleY);
        }

        node.setAbsolutePosition(newAbsPos);

        onTextBoxChange(index, {
            x: node.x(),
            y: node.y(),
            width: node.width(),
            height: node.height(),
            textAlignment: node.align(),
            fontSize: node.fontSize(),
            rotation: node.rotation()
        });
    }

    const onTextDragEnd = () => {
        const node = editableTextRef.current;

        onTextBoxChange(index, {
            x: node.x(),
            y: node.y(),
            width: node.width(),
            height: node.height(),
            textAlignment: node.align(),
            fontSize: node.fontSize(),
            rotation: node.rotation()
        });

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);
    }

    return (
        <Fragment>
            <Text
                key={ index }
                ref={editableTextRef}
                onClick={ onSelect }
                onTap={ onSelect }
                text = {text}
                align = {textAlignment}
                keepRatio={true}
                width={width}
                rotation = {rotation}
                fontSize = {fontSize}
                fontStyle = {fontFamily === "Monotype Corsiva" ? 'normal' : fontStyle}
                fontFamily = {fontFamily}
                textDecoration={textDecoration}
                fill={textColor}
                draggable={true}

                x={x}
                y={y}

                onDblClick={(e) => { onTextDblClick() }}
                onTransform = {(e) => { onTextTransform() }}
                onDragMove= {(e) => { onTextDragMove() }}
                onDragEnd = {(e) => { onTextDragEnd() }}
            />
            {   isSelected && (
                <Transformer
                    ref={transformerRef}
                    enabledAnchors={['middle-right', 'middle-left']}
                    rotateEnabled={false}
                    borderStroke={"teal"}
                    anchorStroke={"teal"}
                    centredScaling={true}
                    keepRatio={true}
                    boundBoxFunc={(oldBox, newBox) => {
                        const node = editableTextRef.current;
                        const absPos = node.getAbsolutePosition();

                        const isOut =
                            absPos.x < 0 ||
                            absPos.y < 0 ||
                            (absPos.x + (node.width()*stageScaleX)) > (stageSize.width) ||
                            (absPos.y + (node.height()*stageScaleY)) > (stageSize.height);

                        // limit resize
                        if (newBox.width < 50 || newBox.height < 5 || isOut) {
                            node.setAbsolutePosition(absPos);
                            return oldBox;
                        } else {
                            return newBox;
                        }
                    }}
                />
            )
            }
        </Fragment>
    )
}

export default EditableText;