import { SET_USER_DATA } from "../constants";

const initialUserData = {
    userData: {}
};

const userData = (state = initialUserData, action) => {
    switch(action.type) {
        case SET_USER_DATA: {
            // console.log("State preserved. "+ action.payload)
            return {...state, userData:action.payload};
        }
        default:
            return state;
    }
    // console.log("Redux connected!")
    // return state;
};

export default userData;
  