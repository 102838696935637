import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './App.css';
import Home from './pages/Home/Home';
import TemplateEditor from './pages/TemplateEditor/TemplateEditor';
import PageNotFound from './pages/PageNotFound/PageNotFound';

import TemplatePreview from './pages/TemplatePreview/TemplatePreview';
import Header from './components/Header/Header';
import Login from "./pages/Authentication/Login";
import Payments from "./pages/Payments/Payments";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import Vendor from "./pages/vendor/Vendor";
import Agents from './pages/agents/Agents'
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import VendorAdmin from "./pages/AdminDashboard/Vendor";
import AgentAdmin from "./pages/AdminDashboard/Agent";
import ShopAdmin from "./pages/AdminDashboard/Shops";
import Footer from "./components/Footer/Footer";
import Contact from "./pages/Contact/Contact";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import MobileViewNotAvailable from "./pages/MobileViewNotAvailable/MobileViewNotAvailable";


function App() {
  let path = window.location.pathname;
  path = path.substring(1);
  let pagePath = path.startsWith("editor")
  // const AppContext = createContext(null);

  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path="/home">
          <Header />
          <Home />
          <Footer/>
        </Route>
        <Route exact path="/payments">
          <Header />
          <Payments />
          <Footer/>
        </Route>
        <Route exact path="/settings">
          <Header />
          <AccountSettings />
          <Footer/>
        </Route>
        <Route exact path="/editor" >
          <Header largeWidth={true} />
          <TemplateEditor />
          <Footer/>
        </Route>
        <Route exact path="/preview/:id" >
          <Header />
          <TemplatePreview />
          <Footer/>
        </Route>
        <Route exact path="/admin">
          <Header />
          <AdminDashboard view={'agent'}>
            <AgentAdmin />
          </AdminDashboard>
          <Footer/>
        </Route>
        <Route exact path="/admin/vendor">
          <Header />
          <AdminDashboard view={'vendor'}>
            <VendorAdmin />
          </AdminDashboard>
          <Footer/>
        </Route>
        <Route exact path="/admin/shop">
          <Header />
          <AdminDashboard view={'shop'}>
            <ShopAdmin />
          </AdminDashboard>
          <Footer/>
        </Route>
        <Route exact path="/vendor/client">
          <Header />
          <Vendor />
          <Footer/>
        </Route>
        <Route exact path="/vendor/agent">
          <Header />
          <Vendor />
          <Footer/>
        </Route>
        <Route exact path="/agents">
          <Header />
          <Agents />
          <Footer/>
        </Route>
        <Route exact path="/contact">
          <Header />
          <Contact />
          <Footer/>
        </Route>
        <Route exact path='/unauthorized'>
          <Header/>
          <Unauthorized/>
          <Footer/>
        </Route>
        <Route exact path='/mobileViewNotAvailable'>
          <Header />
          <MobileViewNotAvailable />
          <Footer/>
        </Route>
        <Route exact path="*" >
          <Header />
          <PageNotFound />
          <Footer/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
