import React, { useState, useEffect, useCallback } from 'react'
import { EyeIcon, RefreshIcon, SupportIcon, TrashIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'
import { OfficeBuildingIcon, PencilIcon, UserGroupIcon } from '@heroicons/react/solid'
import { getShopList, getOTP, verifyOTP, updateAgentStatus, assignAgent, createShop } from '../../apis/vendors/vendors'
import { createAgent, getAgent } from '../../apis/Agents/agent'
import { getUserDetails } from '../../apis/users'
import { debounce } from "lodash";
import ReactPaginate from 'react-paginate';
import { Link, useLocation, useHistory } from 'react-router-dom'
import Spinner from '../../components/spinner/Spinner'
// import CountDownTimer from '../../components/Timer/CountDownTimer's
import Cookies from "universal-cookie";
import Modal from '../../components/Modal/Modal'
import { deleteUser, getSingleUserData, updateData } from '../../apis/admin'

const cookies = new Cookies();

function Vendor() {
    const location = useLocation()
    const history = useHistory();
    const url = window.location.pathname.split('/')
    const [showClientAddModal, setShowClientAddModal] = useState(false);
    const [showAgentAddModal, setShowAgentAddModal] = useState(false);
    const [openDropDown, setOpenDropDown] = useState(false);
    const [view, setView] = useState('AGENT');
    const [clientData, setClientData] = useState({ firstName: '', lastName: '', company: '', address: '', email: '', pincode: '', gst: '', phone: '' });
    const [agentData, setAgentData] = useState({ firstName: '', lastName: '', email: '', phone: '' });
    const [shopList, setShopList] = useState([])
    const [agentList, setAgentList] = useState([])
    const [searchAgent, setSearchAgent] = useState('');
    const [searchClient, setSearchClient] = useState('');
    const debounceFn = useCallback(debounce(handleDebounceFn, 500), []);
    const debounceClientFn = useCallback(debounce(handleDebounceClient, 500), []);
    const [userCount, setUserCount] = useState('');
    const [isAgentLoading, setAgentLoading] = useState(false);
    const [isShopLoading, setShopLoading] = useState(false);

    const [pageNo, setPageNo] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);

    const PAGE_OFFSET = 10;

    let token, member;

    try {
        token = JSON.parse(atob(cookies.get("GG_token")));
        member = atob(cookies.get("__User_Ver"));    
    }
    catch(err) {}

    const resetAgentData = () => {
        setAgentData({ firstName: '', lastName: '', email: '', phone: '' })
    }
    const resetShopData = () => {
        setClientData({ firstName: '', lastName: '', company: '', address: '', email: '', pincode: '', gst: '', phone: '' })
    }

    useEffect(() => {
        if (token === undefined || token === null) {             
            console.log("HERE");
        history.push("/"); 
    }
        else if(member !== '"vendor"') {
            console.log("HERE");
            history.push("/unauthorized");
        }
    }, [])
 

    useEffect(() => {
        getUserCount()
        if (url[2] === 'agent') {
            handleGetAgentList();
            setView('AGENT')
        } else if (url[2] === 'client') {
            handleGetShopList();
            setView('CLIENT')
        }
    }, [location])

    const getUserCount = () => {
        getUserDetails()
            .then((res) => {
                setUserCount(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const searchAgentResult = (e) => {
        setSearchAgent(e.target.value);
        debounceFn(e.target.value);
    };
    function handleDebounceFn(inputValue) {
        handleGetAgentList(inputValue)
    }
    const searchClientResult = (e) => {
        setSearchClient(e.target.value);
        debounceClientFn(e.target.value);
    };
    function handleDebounceClient(inputValue) {
        handleGetShopList(inputValue)
    }

    const handleGetAgentList = (inputValue) => {
        setAgentLoading(true)
        getAgent(inputValue)
            .then((res) => {
                setAgentList(res.data.data.data)
                setAgentLoading(false)
            }
            )
            .catch((err) => {
                setAgentLoading(false)
            })
    }
    const handleGetShopList = (inputValue) => {
        setShopLoading(true)
        getShopList(inputValue)
            .then((res) => {
                if(res.data.data.count%PAGE_OFFSET == 0) {
                    setNumberOfPages(Math.floor(res.data.data.count/PAGE_OFFSET))
                }
                else {
                    setNumberOfPages(Math.floor(res.data.data.count/PAGE_OFFSET) + 1)
                }
                setShopList(res.data.data.data)
                setShopLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setShopLoading(false)
            })
    }

    const handleCreateVendor = (e) => {
        e.preventDefault();
        createShop(clientData)
            .then(res => {
                resetShopData()
                setShowClientAddModal(false)
                handleGetShopList()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleCreateAgent = (e) => {
        e.preventDefault();
        if (agentValidation()) {
            createAgent(agentData)
                .then(res => {
                    resetAgentData()
                    setShowAgentAddModal(false)
                    handleGetAgentList()
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const [showPayment, setShowPayment] = useState(false)
    const [amount, setAmount] = useState('')
    const [userId, setUserId] = useState('')
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isShowVerify, setIsShowVerfy] = useState(false);
    const [otp, setOtp] = useState('');
    const generateOTP = () => {
        setIsOtpSent(true)
        setIsShowVerfy(true)
        getOTP(userId, amount)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => { console.log(err) })
        setTimeout(() => {
            setIsOtpSent(false)
        }, 20000)
    }
    const otpHandler = (id) => {
        setShowPayment(true)
        setUserId(id)
    }
    const handleVerifyOtp = () => {
        if (otp !== '' && otp !== null && otp !== undefined) {
            verifyOTP(otp)
                .then((res) => {
                    if (res.data.message === 'Task Completed') {
                        setShowPayment();
                        handleGetShopList();
                    }
                })
                .catch((err) => { console.log(err) })
        }
    }

    // validation Agent
    const agentValidation = () => {
        let { firstName, lastName, email, phone } = agentData;
        let valid = true;
        if (firstName === '' || firstName === null || firstName === undefined) {
            valid = false;
        }
        if (lastName === '' || lastName === null || lastName === undefined) {
            valid = false;
        }
        if (email === '' || email === null || email === undefined) {
            valid = false;
        }
        if (phone === '' || phone === null || phone === undefined) {
            valid = false;
        }
        return valid;
    }
    const hoursMinSecs = { hours: '0', minutes: 2, seconds: 0 }
    const [agentStatusUpdating, setAgentStatusUpdating] = useState(false)
    const handleAgentStatus = (id, value) => {
        setAgentStatusUpdating(true)
        updateAgentStatus(id, value)
            .then((res) => {
                if (res.data.message === 'Agent record updated') {
                    setAgentStatusUpdating(false)
                    handleGetAgentList();
                }
            })
            .catch((err) => {
                console.log(err)
                setAgentStatusUpdating(false)
            })
    }

    const [shopsList, setShopsList] = useState([])
    const [openShopListModal, setShopListModal] = useState(false)
    const getSingleShopLists = (userId) => {
        // console.log(userId)
        setShopListModal(true)
        getShopList()
            .then((res) => {
                setShopsList(res.data.data)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const [showAssignAgentModal, setShowAssignAgentModal] = useState();
    const [shopId, setShopId] = useState('')
    const [agentId, setagentId] = useState('')
    const [agentLists, setAgentLists] = useState([])
    const getAgentList = () => {
        setShowAssignAgentModal(true)
        getAgent()
            .then((res) => {
                setAgentLists(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleAssignUser = () => {
        assignAgent(shopId, agentId, 'ASSIGN')
            .then((res) => {
                handleGetShopList('');
                setShowAssignAgentModal(false)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    // Agent Edit and Delete logic
    const [showeditModal, setShowEditModal] = useState(false);
    const [singleUserData, setSingleUserData] = useState({})
    const [loadingSingleData, setLoadingSingleData] = useState(false)
    const [currentUserId, setCurrentUserId] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)
    const getSingleData = (userId) => {
        setLoadingSingleData(true)
        setCurrentUserId(userId)
        getSingleUserData(userId)
            .then((res) => {
                setLoadingSingleData(false)
                setShowEditModal(true)
                setSingleUserData(res.data.data)
            })
            .catch((err) => {
                console.error(err)
                setLoadingSingleData(false)
            })
    }
    const handleUpdate = () => {
        setIsUpdating(true)
        updateData(singleUserData, currentUserId)
            .then((res) => {
                setIsUpdating(false)
                handleGetAgentList();
                setShowEditModal(false)
            })
            .catch((err) => {
                setIsUpdating(false)
            })
    }

    const [deleteShowModal, setShowDeleteModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [status, setStatus] = useState('')
    const handleUserDelete = (id, action) => {
        setStatus(action)
        setShowDeleteModal(true)
        setCurrentUserId(id)
    }
    const deleteSingleUser = () => {
        setIsDeleting(true)
        deleteUser(currentUserId, status)
            .then((res) => {
                setIsDeleting(false)
                handleGetAgentList();
                setShowDeleteModal(false)
            })
            .catch((err) => {
                setIsDeleting(false)
                console.log(err)
            })
    }

    // Shop Edit and Delete logic
    const [showeditShopModal, setShowEditShopModal] = useState(false);
    const [singleShopUserData, setSingleShoppUserData] = useState({})
    const [loadingSingleShopData, setLoadingSingleShopData] = useState(false)
    const [currentShopUserId, setCurrentShopUserId] = useState('')
    const [isShopUpdating, setShopIsUpdating] = useState(false)
    const getSingleShopData = (userId) => {
        setLoadingSingleShopData(true)
        setCurrentShopUserId(userId)
        getSingleUserData(userId)
            .then((res) => {
                setLoadingSingleShopData(false)
                setShowEditShopModal(true)
                setSingleShoppUserData(res.data.data)
            })
            .catch((err) => {
                console.error(err)
                setLoadingSingleShopData(false)
            })
    }
    const handleShopUpdate = () => {
        setShopIsUpdating(true)
        updateData(singleShopUserData, currentShopUserId)
            .then((res) => {
                setShopIsUpdating(false)
                handleGetShopList();
                setShowEditShopModal(false)
            })
            .catch((err) => {
                setShopIsUpdating(false)
            })
    }
    const handlePageClick = (e) => { 
        setPageNo(e.selected); 
    }

    return (
        <>
            <div className="relative container 2xl:max-w-7xl mx-auto">
                {agentStatusUpdating && <div className="flex items-center justify-center absolute w-full h-screen backdrop-blur z-50">
                    <Spinner width="50" height="50" />
                </div>}
                <div className="container mx-auto py-6 px-4">
                    <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-1'>
                        <div className="w-40 h-fit overflow-hidden rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white">
                            <button onClick={() => setShowAgentAddModal(true)} className="text-gray-700 flex items-center  w-full text-left px-4 py-4 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-3">
                                <UserGroupIcon className='w-6 mr-2' /> Create agent
                            </button>
                            <button onClick={() => setShowClientAddModal(true)} className="text-gray-700  flex items-center w-full text-left px-4 py-4 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-3">
                                <OfficeBuildingIcon className='w-6 mr-2' /> Create shop
                            </button>
                        </div>
                        <div className="w-40 rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white pointer-events-none">
                            <div className='py-8'>
                                <span className="text-gray-800 text-5xl block font-semibold">{userCount?.total_agents}</span>
                                <small className='text-gray-700 text-sm pt-4 inline-block'>Total agent</small>
                            </div>
                        </div>
                        <div className="w-40 rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white pointer-events-none">
                            <div className='py-8'>
                                <span className="text-gray-800 text-5xl block font-semibold">{userCount?.total_shops}</span>
                                <small className='text-gray-700 text-sm pt-4 inline-block'>Total shop</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="container mx-auto py-6 px-4">
                        <h1 className="text-3xl py-4 border-b mb-10">{view === 'AGENT' ? 'Agents' : 'Shop'}</h1>
                        <div className="mb-4 flex space-x-4 items-center">
                            <div className="relative inline-block text-left">
                                <div>
                                    <button onClick={() => setOpenDropDown(true)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none capitalize " id="menu-button" aria-expanded="true" aria-haspopup="true">
                                        {view === 'AGENT' ? 'Agents' : 'Shop'}
                                        <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                                {openDropDown && <div className="origin-top-right absolute z-40 left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                    <div className="py-1" role="none">
                                        <Link to="/vendor/agent" onClick={() => setOpenDropDown(false)} className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-3">
                                            Agent
                                        </Link>
                                        <Link to="/vendor/client" onClick={() => setOpenDropDown(false)} className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-3">
                                            Shop
                                        </Link>
                                    </div>
                                </div>}
                            </div>
                            {url[2] === 'agent' && <div className="pr-4">
                                <div className="relative">
                                    <input type="search"
                                        className="w-full pl-10 pr-4 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="Search..." value={searchAgent} onChange={(e) => searchAgentResult(e)} />
                                    <div className="absolute top-0 left-0 inline-flex items-center p-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                                            strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                            strokeLinejoin="round">
                                            <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                            <circle cx="10" cy="10" r="7" />
                                            <line x1="21" y1="21" x2="15" y2="15" />
                                        </svg>
                                    </div>
                                </div>
                            </div>}
                            {url[2] === 'agent' && <div>
                                <button onClick={() => handleGetAgentList('')} className='px-4 py-1 bg-sky-900 group rounded hover:bg-gray-200 hover:ring-1 hover:ring-gray-800'>
                                    <RefreshIcon className='w-7 text-white group-hover:text-gray-700' />
                                </button>
                            </div>}
                            {url[2] === 'client' && <div className="pr-4">
                                <div className="relative">
                                    <input type="search"
                                        className="w-full pl-10 pr-4 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="Search..." value={searchClient} onChange={(e) => searchClientResult(e)} />
                                    <div className="absolute top-0 left-0 inline-flex items-center p-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                                            strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                            strokeLinejoin="round">
                                            <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                            <circle cx="10" cy="10" r="7" />
                                            <line x1="21" y1="21" x2="15" y2="15" />
                                        </svg>
                                    </div>
                                </div>
                            </div>}
                            {url[2] === 'client' && <div>
                                <button onClick={() => handleGetShopList('')} className='px-4 py-1 bg-sky-900 group rounded hover:bg-gray-200 hover:ring-1 hover:ring-gray-800'>
                                    <RefreshIcon className='w-7 text-white group-hover:text-gray-700' />
                                </button>
                            </div>}
                        </div>
                        {isAgentLoading ? <div className='flex items-center justify-center mt-5'><Spinner width={40} height={40} /></div> : url[2] === 'agent' && <div className="">
                            {agentList.length > 0 ? <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Sr.no
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Agent name
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                E-mail
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Shops
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Status
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agentList?.map((agentData, idx) => {
                                            return <tr key={idx}>
                                                <td className="border-dashed border-t border-gray-200 userId">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{agentData.fullName}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{agentData.email}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <button className="px-4 py-1 mx-auto rounded-md bg-white hover:bg-slate-100 border flex items-center space-x-2"
                                                        onClick={() => getSingleShopLists(agentData.user_id)}
                                                    >
                                                        <span className="text-gray-700 text-sm">View</span>
                                                        <EyeIcon className=' w-4 mr-2' />
                                                    </button>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <div className="px-5">
                                                        {agentData.active === '1' && <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                                            <span aria-hidden
                                                                className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                                                            <span className="relative text-sm">Active</span>
                                                        </span>}
                                                        {agentData.active === '4' && <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                                                            <span aria-hidden
                                                                className="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                                                            <span className="relative text-sm">Inactive</span>
                                                        </span>}
                                                    </div>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-2">
                                                        {loadingSingleData ? <div className='px-4'><Spinner width={20} height={20} /></div> : <button onClick={() => getSingleData(agentData.user_id)} className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border flex items-center space-x-2">
                                                            <PencilIcon className=' w-4 mr-2' />
                                                            Edit
                                                        </button>}
                                                        {agentData.active === '1' ? <button onClick={() => handleUserDelete(agentData.user_id, 'inactive')} className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border flex items-center space-x-2">
                                                            <TrashIcon className=' w-4 mr-2' />
                                                            Delete
                                                        </button> : <button onClick={() => handleUserDelete(agentData.user_id, 'active')} className="px-4 py-1 rounded-md bg-green-100 text-green-500 hover:bg-green-200 border border-green-700 flex items-center space-x-2">
                                                            <SupportIcon className=' w-4 mr-2' />
                                                            Activate
                                                        </button>}
                                                    </span>
                                                </td>
                                            </tr>
                                        })}

                                    </tbody>
                                </table>
                            </div> : <div className='text-center text-gray-700 text-2xl mt-5'><span>No data found</span></div>}

                        </div>}
                        {isShopLoading ? <div className='flex items-center justify-center mt-5'><Spinner width={40} height={40} /></div> : url[2] === 'client' && <div className="">
                            {shopList.length > 0 ? <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Sr.no
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Shop name
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                E-mail
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Credits
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Assign agent
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Recharge
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shopList?.map((shopData, idx) => {
                                            return <tr key={idx}>
                                                <td className="border-dashed border-t border-gray-200 userId">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{shopData.fullName}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{shopData.email}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{shopData.credits}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <button title={shopData.agent_name ? shopData.agent_name : `assign`} className="px-4 py-1 mx-auto rounded-md bg-white hover:bg-slate-100 border flex items-center space-x-2"
                                                        onClick={(e) => { getAgentList(); setShopId(shopData.user_id) }}
                                                    >
                                                        <span className="text-gray-700 text-xs line-clamp-1">{shopData.agent_name ? shopData.agent_name : `assign`}</span>
                                                        <EyeIcon className=' w-4 mr-2' />
                                                    </button>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        <button className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={() => otpHandler(shopData.user_id)}>
                                                            Recharge
                                                        </button>
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-2">
                                                        {loadingSingleShopData ? <div className='px-4'><Spinner width={20} height={20} /></div> : <button onClick={() => getSingleShopData(shopData.user_id)} className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border flex items-center space-x-2">
                                                            <PencilIcon className=' w-4 mr-2' />
                                                            Edit
                                                        </button>}
                                                        {/* <button className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border flex items-center space-x-2">
                                                            <TrashIcon className=' w-4 mr-2' />
                                                            Delete
                                                        </button> */}
                                                    </span>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div> : <div className='text-center text-gray-700 text-2xl mt-5'><span>No data found</span></div>}
                        </div>}
                    </div>

                    {
                        (numberOfPages > 1) &&
                        <ReactPaginate
                            breakLabel='...'
                            nextLabel='Next'
                            previousLabel='Previous'
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            pageCount={numberOfPages}
                            renderOnZeroPageCount={"ABC"}
                            containerClassName='flex p-3 w-full justify-center'
                            pageClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                            activeClassName='px-3 py-1 rounded-md border mr-3 bg-sky-300 hover:bg-sky-400'
                            previousClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                            nextClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                            breakClassName='px-3 py-1 mr-3'
                        />
                    }
                    <div className="mb-16"></div>
                </div>
            </div>

            {showClientAddModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">Add shop</h3>
                                </div>
                                <div className="relative flex-auto" style={{ width: '35rem' }}>
                                    <div className="px-5 py-4">
                                        <form className="grid gap-4">
                                            <div className='col-start-1 col-end-7'>
                                                <div className="grid grid-cols-6 gap-4">
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">First name <span className="text-red-500">*</span></label>
                                                        <input type="text" name="first-name" value={clientData.firstName} onChange={(e) => setClientData((prevData) => { prevData.firstName = e.target.value; return { ...prevData } })} id="first-name" autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">Last name <span className="text-red-500">*</span></label>
                                                        <input type="text" name="last-name" value={clientData.lastName} onChange={(e) => setClientData((prevData) => { prevData.lastName = e.target.value; return { ...prevData } })} id="last-name" autoComplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Company name <span className="text-red-500">*</span></label>
                                                        <input type="text" name="company-name" value={clientData.company} onChange={(e) => setClientData((prevData) => { prevData.company = e.target.value; return { ...prevData } })} id="first-name" autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div className="grid grid-cols-6 gap-4">
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
                                                        <input type="text" name="address" value={clientData.address} onChange={(e) => setClientData((prevData) => { prevData.address = e.target.value; return { ...prevData } })} id="first-name" autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label htmlFor="pincode" className="block text-sm font-medium text-gray-700">Pincode</label>
                                                        <input type="text" name="pincode" value={clientData.pincode} onChange={(e) => setClientData((prevData) => { prevData.pincode = e.target.value; return { ...prevData } })} id="last-name" autoComplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email <span className="text-red-500">*</span></label>
                                                        <input type="email" name="email" id="first-name" value={clientData.email} onChange={(e) => setClientData((prevData) => { prevData.email = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                                                        <input type="tel" name="phone" id="first-name" value={clientData.phone} onChange={(e) => setClientData((prevData) => { prevData.phone = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="gst" className="block text-sm font-medium text-gray-700">GST ID</label>
                                                        <input type="text" name="gst" id="first-name" value={clientData.gst} onChange={(e) => setClientData((prevData) => { prevData.gst = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="bg-sky-900 text-white rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleCreateVendor}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => {setShowClientAddModal(false);resetShopData()}}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            {showAgentAddModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">Add Agent</h3>
                                </div>
                                <div className="relative flex-auto" style={{ width: '35rem' }}>
                                    <div className="px-5 py-4">
                                        <form className="grid gap-4">
                                            <div className='col-start-1 col-end-7'>
                                                <div className="grid grid-cols-6 gap-4">
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">First name <span className="text-red-500">*</span></label>
                                                        <input type="text" name="first-name" id="first-name" value={agentData.firstName} onChange={(e) => setAgentData((prevData) => { prevData.firstName = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none" required />
                                                    </div>
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">Last name <span className="text-red-500">*</span></label>
                                                        <input type="text" name="last-name" id="last-name" value={agentData.lastName} onChange={(e) => setAgentData((prevData) => { prevData.lastName = e.target.value; return { ...prevData } })} autoComplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Email <span className="text-red-500">*</span></label>
                                                        <input type="email" name="email" id="first-name" value={agentData.email} onChange={(e) => setAgentData((prevData) => { prevData.email = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Phone <span className="text-red-500">*</span></label>
                                                        <input type="email" name="tel" id="first-name" value={agentData.phone} onChange={(e) => setAgentData((prevData) => { prevData.phone = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="bg-sky-900 text-white rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                        onClick={handleCreateAgent}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => {setShowAgentAddModal(false);resetAgentData()}}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {showPayment ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">Recharge</h3>
                                </div>
                                <div className="relative flex-auto" style={{ width: '35rem' }}>
                                    <div className="px-5 py-4">
                                        <form className="grid gap-4">
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Amount</label>
                                                        <input type="text" name="company-name" value={amount} onChange={(e) => setAmount(e.target.value)} id="first-name" autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div>
                                                <CountDownTimer hoursMinSecs={hoursMinSecs}/>
                                            </div> */}
                                            <div hidden={!isShowVerify} className='col-start-1 col-end-7'>
                                                <div className='grid grid-cols-4 gap-4 items-end'>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">OTP</label>
                                                        <input type="text" name="company-name" id="first-name" value={otp} onChange={(e) => setOtp(e.target.value)} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                    <div className="">
                                                        <div>
                                                            <button type='button' className='bg-gray-200 hover:bg-gray-300 px-6 py-2 text-sm rounded-md w-full' onClick={handleVerifyOtp}>verify</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7' hidden={isOtpSent}>
                                                <div>
                                                    <button type='button' className='bg-gray-200 hover:bg-gray-300 px-6 py-2 text-sm rounded-md' onClick={generateOTP}>Generate OTP</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end px-6 py-3 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowPayment(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {/* View Shopes */}
            <Modal isOpen={openShopListModal} onClose={() => setShopListModal(false)}>
                <div className='border-b border-gray-300 -mx-3'>
                    <div className='flex justify-between px-3'>
                        <h3 className='mb-2 font-semibold font text-xl '>Shops management</h3>
                        <div className=' cursor-pointer' onClick={() => setShopListModal(false)}>
                            <XIcon className='w-6' />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="w-full py-2">
                        <div className="relative">
                            <input type="search"
                                className="w-full pl-10 pr-4 py-2 rounded-lg shadow border-none ease-in duration-300 shadow-sky-200 focus:outline-none  focus:shadow-sky-400 text-gray-600 font-medium"
                                placeholder="Search vendor..." value={""} onChange={(e) => console.log("TODO: ")} />
                            <div className="absolute top-0 left-0 inline-flex items-center p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                    <circle cx="10" cy="10" r="7" />
                                    <line x1="21" y1="21" x2="15" y2="15" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    {shopsList.length > 0 ? <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative max-h-96">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Shop name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Shop email
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {shopsList?.map((vendorList, idx) => {
                                    return <tr key={idx}>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{vendorList.fullName}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{vendorList.email}</span>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div> : <div className='text-center text-gray-700 text-2xl mt-5'><span>No data found</span></div>}
                </div>
            </Modal>

            {/* assign agent */}
            <Modal isOpen={showAssignAgentModal} onClose={() => setShowAssignAgentModal(false)}>
                <div className='border-b border-gray-300 -mx-3'>
                    <div className='flex justify-between px-3'>
                        <h3 className='mb-2 font-semibold font text-xl '>Assign agent</h3>
                        <div className=' cursor-pointer' onClick={() => setShowAssignAgentModal(false)}>
                            <XIcon className='w-6' />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="w-full py-2">
                        <div className="relative">
                            <input type="search"
                                className="w-full pl-10 pr-4 py-2 rounded-lg shadow border-none ease-in duration-300 shadow-sky-200 focus:outline-none  focus:shadow-sky-400 text-gray-600 font-medium"
                                placeholder="Search agent..." value={""} onChange={(e) => console.log("TODO: ")} />
                            <div className="absolute top-0 left-0 inline-flex items-center p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                    <circle cx="10" cy="10" r="7" />
                                    <line x1="21" y1="21" x2="15" y2="15" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative max-h-96">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Agent name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Agent email
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Assign
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {agentLists?.map((data, idx) => {
                                    return <tr key={idx}>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{data.fullName}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{data.email}</span>
                                        </td>
                                        {/* value={data.user_id} onChange={(e) => setVendorData(e.target.value, data.vendor_id)}s */}
                                        <td className="border-dashed border-t border-gray-200 lastName">
                                            <input type="radio" value={data.user_id} onChange={(e) => setagentId(e.target.value)} name={`agent`} className=' default:ring-2 ml-10' />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-right space-x-5 mt-3'>
                        <button className='px-3 py-2 rounded bg-gray-100 text-gray-700' onClick={() => setShowAssignAgentModal(false)}>Cancel</button>
                        <button disabled={agentId === '' ? true : false} className='px-3 py-2 rounded bg-sky-900  text-white disabled:opacity-75' onClick={handleAssignUser}>Save</button>
                    </div>
                </div>
            </Modal>

            {/* Edit Agent modal */}
            <Modal isOpen={showeditModal} onClose={() => setShowEditModal(false)}>
                <div>
                    <div className='border-b border-gray-300 -mx-3'>
                        <div className='flex justify-between px-3'>
                            <h3 className='mb-2 font-semibold font text-xl '>Edit Agent</h3>
                            <div className=' cursor-pointer' onClick={() => setShowEditModal(false)}>
                                <XIcon className='w-6' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative flex-auto">
                    <div className="py-4">
                        <form className="grid gap-4">
                            <div className='col-start-1 col-end-7'>
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">First name <span className="text-red-500">*</span></label>
                                        <input type="text" name="first-name" id="first-name" value={singleUserData.first_name} onChange={(e) => setSingleUserData((prevData) => { prevData.first_name = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none" required />
                                    </div>
                                    <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">Last name <span className="text-red-500">*</span></label>
                                        <input type="text" name="last-name" id="last-name" value={singleUserData.last_name} onChange={(e) => setSingleUserData((prevData) => { prevData.last_name = e.target.value; return { ...prevData } })} autoComplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-start-1 col-end-7'>
                                <div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Email <span className="text-red-500">*</span></label>
                                        <input type="email" name="email" id="first-name" value={singleUserData.email} onChange={(e) => setSingleUserData((prevData) => { prevData.email = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-start-1 col-end-7'>
                                <div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Phone <span className="text-red-500">*</span></label>
                                        <input type="email" name="tel" id="first-name" value={singleUserData.phone} onChange={(e) => setSingleUserData((prevData) => { prevData.phone = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="flex items-center justify-end pt-3 border-t border-solid border-blueGray-200 rounded-b -mx-3">
                    {isUpdating ? <div className='px-6'><Spinner width={30} height={30} /></div> : <button
                        className="bg-sky-900 text-white rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                        onClick={handleUpdate}
                    >
                        Save
                    </button>}
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowEditModal(false)}
                    >
                        Close
                    </button>
                </div>
            </Modal>


            {/* Delete modal */}
            <Modal isOpen={deleteShowModal} onClose={() => setShowDeleteModal(false)}>
                <div className='flex flex-col items-center'>
                    <div>
                        <XCircleIcon className='text-red-600 w-20' />
                    </div>
                    <div>
                        <span className='block text-gray-700 text-2xl text-center my-4'>
                            Are you sure ?
                        </span>
                        <span className='block text-gray-700 text-base text-center my-3'>
                            {`Do you really want to ${status} this record ?`}
                        </span>
                        <div className='text-center space-x-5 flex items-center justify-center'>
                            <button className='px-3 py-2 rounded bg-gray-100 text-gray-700' onClick={() => setShowDeleteModal(false)}>Cancel</button>
                            {isDeleting ? <div className='px-6'><Spinner width={30} height={30} /></div> : <button className='px-3 py-2 rounded text-red-700 bg-red-100' onClick={deleteSingleUser}>{status === 'active' ? "Activate" : "Delete"}</button>}
                        </div>
                    </div>
                </div>
            </Modal>


            {/* Edit Shop modal */}
            <Modal isOpen={showeditShopModal} onClose={() => setShowEditShopModal(false)}>
                <div>
                    <div className='border-b border-gray-300 -mx-3'>
                        <div className='flex justify-between px-3'>
                            <h3 className='mb-2 font-semibold font text-xl '>Edit Shop</h3>
                            <div className=' cursor-pointer' onClick={() => setShowEditShopModal(false)}>
                                <XIcon className='w-6' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative flex-auto">
                    <div className="py-4">
                        <form className="grid gap-4">
                            <div className='col-start-1 col-end-7'>
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">First name <span className="text-red-500">*</span></label>
                                        <input type="text" name="first-name" value={singleShopUserData.first_name} onChange={(e) => setSingleShoppUserData((prevData) => { prevData.first_name = e.target.value; return { ...prevData } })} id="first-name" autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                    <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">Last name <span className="text-red-500">*</span></label>
                                        <input type="text" name="last-name" value={singleShopUserData.last_name} onChange={(e) => setSingleShoppUserData((prevData) => { prevData.last_name = e.target.value; return { ...prevData } })} id="last-name" autoComplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-start-1 col-end-7'>
                                <div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Company name <span className="text-red-500">*</span></label>
                                        <input type="text" name="company-name" value={singleShopUserData.company} onChange={(e) => setSingleShoppUserData((prevData) => { prevData.company = e.target.value; return { ...prevData } })} id="first-name" autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-start-1 col-end-7'>
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
                                        <input type="text" name="address" value={singleShopUserData.address} onChange={(e) => setSingleShoppUserData((prevData) => { prevData.address = e.target.value; return { ...prevData } })} id="first-name" autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                    <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="pincode" className="block text-sm font-medium text-gray-700">Pincode</label>
                                        <input type="text" name="pincode" value={singleShopUserData.pincode} onChange={(e) => setSingleShoppUserData((prevData) => { prevData.pincode = e.target.value; return { ...prevData } })} id="last-name" autoComplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-start-1 col-end-7'>
                                <div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email <span className="text-red-500">*</span></label>
                                        <input type="email" name="email" id="first-name" value={singleShopUserData.email} onChange={(e) => setSingleShoppUserData((prevData) => { prevData.email = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-start-1 col-end-7'>
                                <div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                                        <input type="tel" name="phone" id="first-name" value={singleShopUserData.phone} onChange={(e) => setSingleShoppUserData((prevData) => { prevData.phone = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-start-1 col-end-7'>
                                <div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="gst" className="block text-sm font-medium text-gray-700">GST ID</label>
                                        <input type="text" name="gst" id="first-name" value={singleShopUserData.gst_id} onChange={(e) => setSingleShoppUserData((prevData) => { prevData.gst_id = e.target.value; return { ...prevData } })} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="flex items-center justify-end pt-3 border-t border-solid border-blueGray-200 rounded-b -mx-3">
                    {isShopUpdating ? <div className='px-6'><Spinner width={30} height={30} /></div> : <button
                        className="bg-sky-900 text-white rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                        onClick={handleShopUpdate}
                    >
                        Save
                    </button>}
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowEditShopModal(false)}
                    >
                        Close
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default Vendor
