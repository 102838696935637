import React, { useState, useEffect } from 'react'
import { getUserAccount } from '../../apis/users'

function AccountSettings() {
    const [userData, setUserData] = useState({ avatar: '', firstName: '', lastName: '', company: '', email: '' });
    useEffect(() => {
        getUserData();
    }, [])
    const getUserData = () => {
        getUserAccount()
            .then(res => setUserData({
                avatar: res.data.data[0].avatar,
                firstName: res.data.data[0].first_name,
                lastName: res.data.data[0].last_name,
                company: res.data.data[0].company,
                email: res.data.data[0].email
            }))
            .catch(err => console.error(err));
    }
    const saveUserData = () => {
        console.log(userData)
    }
    
    return (
        <div>
            <div className="max-w-lg my-0 mx-auto shadow-md rounded-md overflow-hidden bg-white px-3 py-4 mt-10">
                <form className="grid gap-4">
                    <div className='col-start-1 col-end-7 mb-5'>
                        <div className=" w-40 h-40 overflow-hidden ring-2 ring-gray-400 rounded-full mx-auto bg-gray-100">
                            <img src={userData.avatar} alt="profile image" className='object-cover w-full h-full' />
                        </div>
                    </div>
                    <div className='col-start-1 col-end-7'>
                        <div className="grid grid-cols-6 gap-4">
                            <div className="col-span-3 sm:col-span-3">
                                <label for="first-name" className="block text-sm font-medium text-gray-700">First name</label>
                                <input type="text" name="first-name" id="first-name" value={userData.firstName} onChange={(e) => setUserData((prevData) => { prevData.firstName = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                            </div>
                            <div className="col-span-3 sm:col-span-3">
                                <label for="last-name" className="block text-sm font-medium text-gray-700">Last name</label>
                                <input type="text" name="last-name" id="last-name" value={userData.lastName} onChange={(e) => setUserData((prevData) => { prevData.lastName = e.target.value; return { ...prevData } })} autocomplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                            </div>
                        </div>
                    </div>
                    <div className='col-start-1 col-end-7'>
                        <div>
                            <div className="col-span-6 sm:col-span-3">
                                <label for="first-name" className="block text-sm font-medium text-gray-700">Company name</label>
                                <input type="text" name="company-name" id="first-name" value={userData.company} onChange={(e) => setUserData((prevData) => { prevData.company = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                            </div>
                        </div>
                    </div>
                    <div className='col-start-1 col-end-7'>
                        <div>
                            <div className="col-span-6 sm:col-span-3">
                                <label for="first-name" className="block text-sm font-medium text-gray-700">Email</label>
                                <input type="email" readOnly disabled name="email" id="first-name" value={userData.email} onChange={(e) => setUserData((prevData) => { prevData.email = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                            </div>
                        </div>
                    </div>
                    <div className='col-start-1 col-end-7'>
                        <div>
                            <button type='button' onClick={saveUserData} className='bg-gray-200 hover:bg-gray-300 px-6 py-2 text-sm rounded-md'>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AccountSettings
