import { SET_TEMPLATE_META } from "../constants";
import { SET_USER_DATA } from "../constants";

export const setTemplateMeta = (value) => {
    return {
      type: SET_TEMPLATE_META,
      payload: value,
    };
};

export const setUserData = (value) => {
  return {
    type: SET_USER_DATA,
    payload: value,
  };
};