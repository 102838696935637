import { useState, useEffect } from 'react'
import { withRouter, Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from '../Dropdown/Dropdown'
import { checkIfNotNullOrUndefinedOrEmpty, logout, pushToLogin } from '../../utilities/general'
import { decode } from "base-64";
import Cookies from "universal-cookie";
import { CogIcon, CurrencyRupeeIcon, LogoutIcon, XIcon } from '@heroicons/react/outline'
import { getUserAccount } from '../../apis/users';
import renderlyWhite from '../../assets/images/logo/renderly_white.png';

// Redux based state 
import { connect } from "react-redux";

// Redux action
import {setUserData} from "../../services/actions/actions";

const cookies = new Cookies();
const Header = (props) => {
    let token, user, member;

    try {
        token = cookies.get("GG_token");
        user = cookies.get("__DP_PG");
        member = atob(cookies.get("__User_Ver"));
    }
    catch(err) {

    }

    const [userProfile, setUserProfile] = useState({});

    useEffect(() => {
        // if (!token) {
        //     pushToLogin();
        // }
        fetchUserDetails();
    }, [])
    
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    let isHeaderSticky = true;

    if (props.location.pathname === "/editor") { isHeaderSticky = false; }

    const handleLogout = () => {
        logout()
        window.location.replace('/')
    }

    const fetchUserDetails = () => {
        getUserAccount()
            .then(res => {
                if(res?.data?.data)
                    setUserProfile(res.data.data[0]);
                    props.setUserState(res.data.data[0])
            })
            .catch(err => {

            })
    }

    // let userProfile;
    // if (token) {
    //     userProfile = JSON.parse(decode(user));
    // }

    return (
        <header className={"top-0 bg-sky-900 py-2 z-50 " + ( isHeaderSticky ? "sticky" : '')}>
            <div className={'container mx-auto px-4 flex justify-between items-center' + (props.largeWidth ? ' ' : ' 2xl:max-w-7xl')}>
                <Link className="text-white px-3 logo-font flex" to={member === '"members"' ? '/home' : member === '"vendor"' ? '/vendor/agent' : member === '"admin"' ? '/admin' : '/agents' }>
                   <img src={renderlyWhite} alt="Renderly logo" width={24} height={24}/> 
                   <span className='ml-2'> Renderly </span>
                </Link>
                <div className="flex items-center">
                    {member === '"members"' && <div className="flex items-center bg-gray-300 rounded px-3 py-1 mr-5">
                        <CurrencyRupeeIcon className="w-6 items-center" />
                        <span className="pl-3 pr-6 text-sm">
                            {+userProfile?.credits}
                        </span>
                    </div>}
                    {
                        checkIfNotNullOrUndefinedOrEmpty(token) &&
                        <div className='cursor-pointer relative'>
                            <div className=" w-10 h-10 bg-gray-300 rounded-full overflow-hidden" onClick={() => setIsPopupOpen(!isPopupOpen)}>
                                <img src={userProfile?.avatar} alt="profile avatar" className="w-full h-full object-cover" />
                            </div>
                            <Dropdown className={'right-0'} show={isPopupOpen} onHide={() => setIsPopupOpen(false)}>
                                {/* <Link className="text-gray-700 flex w-full text-left px-4 py-2 text-sm hover:bg-gray-200" to="/payments">
                                    <CurrencyRupeeIcon className=' w-5 mr-2' /> Payments
                                </Link> */}
                                <Link className="text-gray-700 flex w-full text-left px-4 py-2 text-sm hover:bg-gray-200" to="/settings">
                                    <CogIcon className=' w-5 mr-2' />Account Settings
                                </Link>
                                <button onClick={handleLogout} type="button" className="text-gray-700 flex w-full text-left px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabIndex="-1" id="menu-item-3">
                                    <LogoutIcon className=' w-5 mr-2' />Logout
                                </button>
                                <button onClick={() => setIsPopupOpen(false)} type="button" className="text-gray-700 flex w-full text-left px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabIndex="-1" id="menu-item-3">
                                    <XIcon className=' w-5 mr-2' />Cancel
                                </button>
                            </Dropdown>
                        </div>
                    }
                </div>
            </div>
        </header>);
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToState = (dispatch) => {
    return {
        setUserState: (value) => {
            dispatch(setUserData(value));
        }
    } 
}

export default connect(mapStateToProps, mapDispatchToState)(withRouter(Header));