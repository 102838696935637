import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from "universal-cookie";

import writeXlsxFile from 'write-excel-file';
import readXlsxFile from 'read-excel-file';
import { Stage, Layer, Image, Text } from 'react-konva';

import moment from 'moment';
import Moment from 'react-moment';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';

import './templatePreview.css';

import { createNewProject } from '../../apis/TemplateAPI';

// Redux imports 
import { connect } from "react-redux";
import Modal from '../../components/Modal/Modal';
import Config from '../../Config';
import { checkIfNotNullOrUndefinedOrEmpty, checkIfNullOrUndefinedOrEmpty } from '../../utilities/general';

const TemplatePreview = ({templateData, userData}) => {

    const cookies = new Cookies();
    toast.configure();

    const [meta, setMeta] = useState();
    const [columnData, setColumnData] = useState();
    const [excelRows, setExcelRows] = useState();
    const [excelFileData, setExcelFileData] = useState();

    const [scalingRatio, setScalingRatio] = useState(1);
    const [scaleX, setScaleX] = useState();
    const [scaleY, setScaleY] = useState();

    const [size, setSize] = useState({width: 10, height: 10});

    const [projectTitle, setProjectTitle] = useState("");

    const [pageNo, setPageNo] = useState(0);
    const [bgImageObject, setBgImageObject] = useState("");

    const [showInvoice, setShowInvoice] = useState(false);

    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);

    const [numberOfPages, setNumberOfPages] = useState(0);
    const PAGE_SIZE = 50;

    const [canvasObjects, setCanvasObjects] = useState([]);

    const history = useHistory();

    let token, member;

    try {
        token = JSON.parse(atob(cookies.get("GG_token")));
        member = atob(cookies.get("__User_Ver"));    
    }
    catch(err) {}

    const previewCanvas = useRef();
    const excelFileRef = useRef();

    const initializeSize = (canvasDetails) => {
        if(canvasDetails.width >= canvasDetails?.height) {
            return {
                width: (window.innerWidth/2.5),
                height: (window.innerWidth/2.5)/((canvasDetails.width/canvasDetails.height).toFixed(4))
            }
        }
        else {
            return {
                width: (window.innerHeight/1.4)/((canvasDetails.height/canvasDetails.width).toFixed(4)),
                height: (window.innerHeight/1.4)
            }
        }
    }

    useEffect(() => {
        if(checkIfNullOrUndefinedOrEmpty(token)) {
            history.push("/");
        } else if(member !== '"members"') {
            history.push("/unauthorized");
        }
        if(templateData?.templateMeta) {
            setMeta(templateData?.templateMeta?.state)
        }
    }, [])

    useEffect(() => {
        if(meta !== null && meta !== undefined) {
            extractDynamicFields();
            setScalingRatio((meta?.canvasDetails.width/meta?.canvasDetails.height).toFixed(4))
            // setSize({
            //     width: window.innerWidth/2.2,
            //     height: (window.innerWidth/2.2)/((meta?.canvasDetails.width/meta?.canvasDetails.height).toFixed(4))
            // })
            setSize(initializeSize(meta?.canvasDetails))
        }
    }, [meta])

    useEffect(() => {
        if(meta?.orientation === "landscape") {
            setScaleX(size.width / meta?.canvasDetails?.width);
            setScaleY(size.height / meta?.canvasDetails?.height);    
        } else if(meta?.orientation === "portrait") {
            setScaleY(size.width / meta?.canvasDetails?.width);
            setScaleX(size.height / meta?.canvasDetails?.height);            
        }
    }, [size])

    useEffect(() => {
        const checkSize = () => {
            if(meta?.orientation === "landscape") {
                setSize({
                    width: window.innerWidth/2.2,
                    height: (window.innerWidth/2.2)/scalingRatio
                })    
            }
            else if(meta?.orientation === "portrait") {
                setSize({
                    width: window.innerHeight/2.2,
                    height: (window.innerHeight/2.2)/scalingRatio
                })
           }
        }

        window.addEventListener("resize", checkSize)
        return () => window.removeEventListener("resize", checkSize)
    }, [scalingRatio])

    const extractDynamicFields = () => {
        let objectState = meta?.objectState;

        let dynamicFields = [];

        for(let i=0; i<objectState?.length; i++) {
            if(objectState[i].type === 'TextBox') {
                dynamicFields.push(objectState[i]);
            }
        }

        let schema = [];

        for(let j=0; j<dynamicFields.length; j++) {
            schema.push({
                value: dynamicFields[j].text,
                fontWeight: 'bold'
            })
        }

        let finalColumns = [schema,];

        setColumnData(finalColumns);
    }

    const validateExcelFileHeaders = (fileHeader) => {
        let dynamicFields = columnData[0];
        let isDirty = false;

        let loopLength = Math.min(dynamicFields?.length, fileHeader?.length);

        if(fileHeader.length != dynamicFields?.length) {
            isDirty = true;
            return !isDirty;
        }

        for(let i=0; i<loopLength; i++) {
            if(fileHeader[i] !== dynamicFields[i]?.value) {
                isDirty = true;
            }
        }

        return !isDirty;
    }

    const validateExcelFileRows = (rows) => {
        let isDirty = false;

        console.log("HERE", rows)

        if(checkIfNullOrUndefinedOrEmpty(rows) || rows?.length == 0) {
            console.log("INVALID BLANKK")
            isDirty = true;
        }

        return !isDirty;
    }

    const generateExcelFile = async () => {
        await writeXlsxFile(columnData, {
            fileName: templateData?.templateMeta?.templateDetails?.templateName +".xlsx",
        })
    }

    const readExcelFile = async (file) => {
        setExcelFileData(null);
        setNumberOfPages(null);
        setExcelRows(null);   
         
        if(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
            setExcelFileData(file);
        }
        else {
            excelFileRef.current.value = "";
            toast.error("Please upload an excel file.", {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: false
              })
            return;
        }   

        try {
            readXlsxFile(file).then((rows) => {
                let header = rows?.slice(0,1);
                let firstRows = rows?.slice(1, 5);
    
                if(validateExcelFileHeaders(header && header[0])) {
                    if(validateExcelFileRows(firstRows)) {
                        setExcelRows(rows)
                        if(rows.length%PAGE_SIZE == 0) {
                            setNumberOfPages(Math.floor(rows.length/PAGE_SIZE));
                        }
                        else {
                            setNumberOfPages(Math.floor(rows.length/PAGE_SIZE) + 1);
                        }
                    }
                    else {
                        excelFileRef.current.value = "";
                        setExcelFileData(null);
                        setNumberOfPages(null);
                        setExcelRows(null);
                        toast.error("Excel file is blank. Please upload a filled excel file", {
                            position: 'top-left',
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: false
                        })
                    }
                } 
                else {
                    // console.log()
                    excelFileRef.current.value = "";
                    setExcelFileData(null);
                    setNumberOfPages(null);
                    setExcelRows(null);
                    toast.error("Excel file invalid.", {
                        position: 'top-left',
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: false
                    })
                }
              })
              .catch(err => {
                  console.log(err)
                  setExcelFileData(null);
                  setNumberOfPages(null);
                  setExcelRows([]);
                  toast.error("Error while reading excel file.", {
                    position: 'top-left',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: false
                  })
              })
        }
        catch(err) {
            setExcelFileData(null);
            setExcelRows([]);
            console.log(err)
            toast.error("Error while reading excel file.", {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: false
              })
        }
    }

    const renderPreview = async (rowNumber) => {
        let objects = [];

        let objectState = meta?.objectState;

        if(meta.background !== null) {
            let bgImg =  new window.Image();
            bgImg.src = meta.background.imgURL;

            setBgImageObject(bgImg);
        }

        let textBoxNumber = 0;

        for(let i=0; i<objectState?.length; i++) {
            if(objectState[i]?.type === "Image") {
                let img =  new window.Image();
                img.src = objectState[i].imgURL;

                objects.push(
                    <Image
                        key={ objectState[i].index}
                        index={ objectState[i].index }
                        image={img}
                        width={objectState[i].width}
                        height={objectState[i].height}
                        rotation={objectState[i].rotation}
                        x={objectState[i].x}
                        y={objectState[i].y}
                    />);
            } 
            else if(objectState[i]?.type === "TextBox") {
                let text = '';
                if(excelRows[rowNumber][textBoxNumber] instanceof Date) {
                    text = moment(excelRows[rowNumber][textBoxNumber]).format('DD/MM/YYYY');
                }
                else {
                    text = excelRows[rowNumber][textBoxNumber];
                }

                objects.push(
                    <>
                        <Text 
                            key={objectState[i].index} 
                            index={objectState[i].index}
                            text={text}
                            fontFamily={objectState[i].fontFamily}
                            fontSize={objectState[i].fontSize}
                            fontStyle={objectState[i].fontFamily === "Monotype Corsiva" ? "normal" : objectState[i].fontStyle}
                            // textDecoration={objectState[i].textDecoration}
                            fill={objectState[i].textColor}
                            // rotation={objectState[i].rotation}
                            x={objectState[i].x}
                            y={objectState[i].y}
                            width={objectState[i].width}
                            align={objectState[i].textAlignment}
                        />
                    </>
                );

                textBoxNumber += 1;
            }
        }

        setCanvasObjects(objects)
    }

    const handlePageClick = (e) => { setPageNo(e.selected); }

    const callCreateNewProject = async () => {
        setCreateButtonDisabled(true);
        createNewProject(token, templateData?.templateMeta?.templateDetails?.id, projectTitle, (excelRows?.length-1), excelFileData)
            .then(res => {
                setCreateButtonDisabled(true);
                toast.success("New project created with name: '"+res?.data?.data?.projectName+"'", {
                    position: 'top-left',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: false
                  })
                  setTimeout(() => {
                      history.push('/home');
                  }, 1000)
            })
            .catch(err => {
                setCreateButtonDisabled(false);
            })
    }

    return (<div className='flex flex-col lg:flex-row lg:h-screen'>
        <div className='lg:w-1/2 flex flex-col pt-3'>
            <button className='self-start m-3 ml-2 px-4 py-1 rounded-md bg-sky-600 hover:bg-sky-700 border text-white'
                onClick={() => history.push("/editor")}
            >
                Go back
            </button>
            <div className='flex flex-wrap'>
                <div className='flex flex-col mr-3'>
                    <label className='mx-3 my-2 font-semibold'>Enter Project Name:</label>
                    <input className="mx-3 py-0.5 border rounded-md py-1 px-2 w-full outline-none" type="text" name="" id="" value={projectTitle} onChange={(e) => setProjectTitle(e.target.value)} />
                </div>
                <button className='self-end ml-2 px-4 py-1 rounded-md bg-sky-200 hover:bg-sky-300 border my-2 md:my-0' onClick={generateExcelFile}>Download Excel Sheet</button>
            </div>

            <div className='flex flex-wrap'>
                <div className='flex flex-col'>
                    <label className='mx-3 my-2 font-semibold'>Upload Excel File (.xlsx):</label>
                    <input ref={excelFileRef} className='mx-3' type="file" onChange={e => readExcelFile(e.target.files[0])}/>
                </div>
                <button className='self-end ml-2 px-4 py-1 rounded-md bg-sky-300 hover:bg-sky-400 border my-2 md:my-0'
                        onClick={() => setShowInvoice(true)}
                >
                    Create New Project
                </button>
            </div>

            {excelRows?.length > 0 && 
            <div className='overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-3 ml-3 h-[38vh] xl:h-[44vh]'>
                <table className='border-collapse overflow-auto table-auto w-full whitespace-no-wrap bg-white table-striped relative'>
                    <thead>
                        <tr className="text-left">
                            <th className='bg-gray-100 sticky top-0 border-b border-gray-200 p-3 text-gray-600 font-bold tracking-wider uppercase text-xs sticky z-50 left-0'>
                                Sr.no
                            </th>
                            {excelRows?.slice(0,1).map((header, idx) => {
                                return  header.map((data, idx) => {
                                    return <th key={idx} className='bg-gray-100 sticky top-0 border-b border-gray-200 p-3 text-gray-600 font-bold tracking-wider uppercase text-xs'>
                                        {data}
                                    </th>
                                })
                            })}
                            <th className='bg-gray-100 sticky top-0 border-b border-gray-200 p-3 text-gray-600 font-bold tracking-wider uppercase text-xs  sticky z-50 right-0'>
                                Preview
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {excelRows?.slice(pageNo*PAGE_SIZE + 1, pageNo*PAGE_SIZE+50).map((data, idx) => {
                            return <tr key={idx} >
                                <td className="border-dashed border-t border-gray-200 userId sticky z-40 left-0">
                                    <span className="text-gray-700 p-3 flex items-center text-sm">{(pageNo*PAGE_SIZE)+ idx + 1}</span>
                                </td>
                                {data.map((header, idx) => {
                                    if (header instanceof Date) {
                                        return <td key={idx} className="border-dashed border-t border-gray-200 userId w-fit">
                                            <span className="text-gray-700 p-3 flex items-center text-sm">
                                                <Moment date={header} format='DD/MM/YYYY' />
                                            </span>
                                        </td>
                                    } else {
                                        return <td key={idx}>
                                            <span className="text-gray-700 p-3 flex items-center text-sm">
                                                {header}
                                            </span>
                                        </td>
                                    }
                                })}
                                <td className="border-dashed border-t border-gray-200 userId sticky z-40 right-0">
                                   <button onClick={() => { renderPreview((pageNo*PAGE_SIZE) + idx+1) }} className="px-2 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border">
                                       View
                                    </button> 
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>}

            {
                (numberOfPages > 1) &&
                <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next'
                    previousLabel='Previous'
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    pageCount={numberOfPages}
                    renderOnZeroPageCount={"ABC"}
                    containerClassName='flex p-3 w-full justify-center'
                    pageClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                    activeClassName='px-3 py-1 rounded-md border mr-3 bg-sky-300 hover:bg-sky-400'
                    previousClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                    nextClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                    breakClassName='px-3 py-1 mr-3'
                />
            }
            
        </div>
        <div className='lg:w-1/2 flex justify-center items-center py-10 lg:py-0'>
            <div 
                id="preview-container"
                className={ meta?.canvasDetails?.bleed?.top != 0 || 
                            meta?.canvasDetails?.bleed?.left != 0 || 
                            meta?.canvasDetails?.bleed?.bottom != 0 || 
                            meta?.canvasDetails?.bleed?.right != 0 ? 'border-2 border-sky-500 bg-white' 
                            : ''
                        }
                style={{
                    paddingTop: meta?.canvasDetails?.bleed?.top*scaleY +'px',
                    paddingLeft: meta?.canvasDetails?.bleed?.left*scaleX +'px',
                    paddingBottom: meta?.canvasDetails?.bleed?.bottom*scaleY+'px',
                    paddingRight: meta?.canvasDetails?.bleed?.right*scaleX+'px',
                    backgroundColor: meta?.canvasDetails?.bleedColor,
                }}
            >
                <Stage 
                    // width={ orientation === "landscape" ? size.width : size.height }
                    // height={ orientation === "landscape" ? size.height : size.width }
                    width={ size?.width }
                    height={ size?.height }
                    scaleX={ scaleX }
                    scaleY={ scaleY }
                    ref={previewCanvas}
                >
                    <Layer>
                        <Image 
                            id="bg-image"
                            width={meta?.canvasDetails?.width}
                            height={meta?.canvasDetails?.height}
                            image={ bgImageObject }
                            // width={ orientation === "landscape" ? size.width/scaleX : size.height/scaleX }
                            // height={ orientation === "landscape" ? size.height/scaleY : size.width/scaleY }
                            x={0}
                            y={0}
                        />
                        { canvasObjects.map(element => {
                            return element
                        } )}
                    </Layer>
                </Stage>
            </div>
        </div>

        <Modal
            onClose={() => setShowInvoice(false)}
            isOpen={ showInvoice }
        >
            <h2 className='text-lg font-bold'>Invoice</h2>

            <div className='border-t-2 border-slate-300  flex justify-between mt-3 py-2'>
                <p>Pages: &#8377; {Config?.perPageCost} X {excelRows?.length-1} pages </p>
                <p>&#8377; { Config?.perPageCost *  (excelRows?.length-1)}</p>
            </div>
            <div className='border-y-2 border-slate-400 flex justify-between w-100 font-medium mb-5 py-2'>
                <p>Total</p> 
                <p>&#8377; { Config?.perPageCost *  (excelRows?.length-1)}</p>
            </div>
            {
                 (Config?.perPageCost *  (excelRows?.length-1)) > parseFloat(userData?.userData?.credits) ?
                <p className='text-red-400'>You do not have enough credits to create this project. Please recharge to be able to create the project</p> 
                : null
            }
            <div className='mt-3 flex justify-end'>
                <button type="button" className="px-4 py-1 rounded-md bg-red-500 hover:bg-red-600 text-white border" onClick={() => setShowInvoice(false)} disabled={cancelButtonDisabled}>
                    Cancel
                </button>
                <button type="buttonName" className="ml-2 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border"  onClick={callCreateNewProject} 
                    disabled={createButtonDisabled || (Config?.perPageCost *  (excelRows?.length-1)) > parseFloat(userData?.userData?.credits)}
                >
                    Create
                </button>
            </div>
        </Modal>
    </div>);
}

const mapStateToProps = (state) => {
    return state;
};
 
export default connect(mapStateToProps)(TemplatePreview);