import React, { useState, useEffect } from 'react'
import { useGoogleLogin } from "react-google-login";
import "./login.css";
import axios from "axios";
import Spinner from '../../components/spinner/Spinner'
import Config from "../../Config";
import loginImg from '../../assets/images/auth/login.jpg'
import google from '../../assets/images/auth/google.png'
import Cookies from "universal-cookie";
import { encode, decode } from "base-64";
import renderlyColor from '../../assets/images/logo/renderly_blue.png';
import renderlyWhite from '../../assets/images/logo/renderly_white.png';

const cookies = new Cookies();
function Login() {
    var author = cookies.get("__User_Ver");
    var token = cookies.get("GG_token");
    useEffect(() => {
        if (token) {
            if (decode(author) === `"members"`) {
                window.location.assign("/home");
            } else if (decode(author) === `"vendor"`) {
                window.location.assign("/vendor/client");
            } else if (decode(author) === `"agent"`) {
                window.location.assign("/agents");
            }else if(decode(author) === `"admin"`) {
                window.location.assign("/admin");
            }
        }
    }, [])
    const [isGettingLogin, setIsGettingLogin] = useState(false);
    const clientId = Config.clientID;
    const onFailure = (res) => {
        setIsGettingLogin(false)
        if (res.details === 'Cookies are not enabled in current environment.') {
            alert('Cookies are not enabled, Please go to settings and enable "Allow all cookies"')
        }
    };
    const onSuccess = (res) => {
        setIsGettingLogin(true)
        successGoogleLogin(res.tokenId, res.googleId, res.profileObj.email, res.profileObj.givenName, res.profileObj.familyName, res.profileObj.imageUrl)
    };
    const { signIn } = useGoogleLogin({ onSuccess, onFailure, clientId, cookiePolicy: "single_host_origin" });

    const successGoogleLogin = (token, id, email, firstname, lastname, photo) => {
        var fd = new FormData();
        fd.append("idToken", token);
        fd.append("id", id);
        fd.append("email", email);
        fd.append("photo", photo);
        fd.append("first_name", firstname);
        fd.append("last_name", lastname);
        fd.append("platform", "web");
        axios({
            method: "POST",
            url: `${Config.apiurl}Authentication/loginGoogle`,
            data: fd,
        })
            .then((response) => {
                if (response.data.message === "Registration Success" || response.data.message === "Login Success") {
                    var encodeToken = encode(response.data.token);
                    cookies.set("GG_token", encodeToken, { path: "/" });
                    cookies.set("__User_Ver", response.data.data.profile, { path: "/" });
                    cookies.set("__DP_PG", encode(JSON.stringify(response.data.data)), { path: "/" });
                    if (decode(response.data.data.profile) === `"members"`) {
                        window.location.assign("/home");
                    } else if (decode(response.data.data.profile) === `"vendor"`) {
                        window.location.assign("/vendor/client");
                    } else if (decode(response.data.data.profile) === `"agent"`) {
                        window.location.assign("/agents");
                    } else if (decode(response.data.data.profile) === `"admin"`) {
                        window.location.assign("/admin");
                    }   
                    setIsGettingLogin(false);
                }

            })
            .catch((err) => {
                setIsGettingLogin(false)
                // if (err.response.data.message === "User Deactivated") {
                //     alert('erroe')
                // }
            });
    };
    return (
        <>
            <div className="bg-dark-blue relative">
                {isGettingLogin && <div className="w-full h-full absolute flex items-center justify-center backdrop-blur">
                    <Spinner width="50" height="50" />
                </div>}
                <div className="w-full min-h-screen flex items-center justify-end md:justify-start sm:flex-row flex-col-reverse">
                    <section className='w-full sm:w-1/2 lg:w-2/5 flex items-center justify-center sm:h-screen h-auto'>
                        <div className="max-w-xs md:max-w-md ">
                            <div className="flex flex-col items-center justify-center p-8 md:p-16">
                                <div className='flex logo-font items-center my-5 text-white'>
                                    <img className='w-12 h-12' src={renderlyWhite} alt="Renderly logo"/> 
                                    <span className='ml-2 text-2xl'> Renderly </span>
                                </div>
                                <div className="form-action w-full text-center flex items-center justify-center my-5">
                                    <button onClick={signIn} className="bg-white shadow-lg rounded-full px-8 py-2 text-sm flex items-center">
                                        <img src={google} alt="google" className='w-6 mr-2' />
                                        <span className='font-medium'>Login with Google</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='w-full sm:w-1/2 lg:w-3/5 bg-sky-900 sm:h-screen h-80 login-bg-image'>

                    </section>
                </div>
            </div>
        </>
    )
}

export default Login
