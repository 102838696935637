import axios from "axios";
import Config from "../../Config";
import Cookies from "universal-cookie";
import { decode } from "base-64";

const cookies = new Cookies();

var token = cookies.get('GG_token');
export const getShopList = async (search = '') => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("search", search);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/getShopList`,
        data: fd
    })
}
export const createVendor = async (data) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("company", data.company);
    fd.append("address", data.address);
    fd.append("email", data.email);
    fd.append("pincode", data.pincode);
    fd.append("gst", data.gst);
    fd.append("phone", data.phone);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/createVendor`,
        data: fd
    })
}
export const createShop = async (data) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("company", data.company);
    fd.append("address", data.address);
    fd.append("email", data.email);
    fd.append("pincode", data.pincode);
    fd.append("gst", data.gst);
    fd.append("phone", data.phone);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/createClient`,
        data: fd
    })
}

export const seachShopList = async (search) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("search", search);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/getShopList`,
        data: fd
    })
}


export const getOTP = async (id, amount) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("receiverId", id);
    fd.append("amount", amount);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/generateOtp`,
        data: fd
    })
}


export const verifyOTP = async (otp) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("otp", otp);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/verifyOtp`,
        data: fd
    })
}

export const updateAgentStatus = async (id, action) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("agent_id", id);
    fd.append("action", action === 'active' ? 1 : 4);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/updateAgentStatus`,
        data: fd
    })
}

export const assignAgent = async (client_id, agent_id, action = 'ASSIGN') => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("client_id", client_id); //charul's id
    fd.append("agent_id", agent_id); //
    fd.append("action", action); //REMOVE
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/assignAgentToShop`,
        data: fd
    })
}