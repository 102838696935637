import { TrashIcon, CogIcon, AdjustmentsIcon } from '@heroicons/react/outline'

const Topbar = ({ fontList, createTextBox, createQrCode, deleteItem, fontSize, fontFamily, isBold, isItalic, textColor,
                    textDecoration, setFontSize, setFontFamily, setTextBold, setTextItalic, setTextDecoration, setTextColor,
                    shiftUp, shiftDown, textAlignment, setTextAlignment, setShowSettingsModal
    }) => {

        const increaseFontSize = () => {
            // if((fontSize + 1 >= 2))
            setFontSize(fontSize + 1)
        }

        const decreaseFontSize = () => {
            if(!(fontSize - 1 < 2)) {
                setFontSize(fontSize - 1)
            }
        }

        const changeFontSize = (value) => {
            if(/^\d+$/.test(value)) {
                setFontSize(parseInt(value));
            }
        }
        const openSettingsModal = () => {
            setShowSettingsModal(true);
        }

    return ( 
        <>
            <div className="w-100 flex items-center flex-wrap text-sm"> 
                <button id="addTextBox" className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border my-2" onClick={ createTextBox }> 
                <i class="bi bi-bounding-box mr-2"></i>
                    Add TextBox
                </button>
                <select 
                    className="ml-2 py-1 border rounded-md py-1 px-2 outline-none w-44" 
                    aria-label="form-select-sm"
                    value={fontFamily}
                    style={{fontFamily: fontFamily}}
                    onChange={(e) => setFontFamily(e.target.value)}
                >
                    {
                        fontList.map(font => <option value={font} style={{fontFamily: font}}>{font}</option>)
                    }
                </select>

                <div className="flex mx-2">
                    <button className="px-1 py-1 rounded-l-md bg-gray-100 hover:bg-gray-200 border" onClick={ decreaseFontSize }>-</button>
                    <input className="border py-1 outline-none w-8" type="text"
                        value={fontSize}
                        onChange={(e) => changeFontSize(e.target.value)}
                    />
                    <button className="px-1 py-1 rounded-r-md bg-gray-100 hover:bg-gray-200 border" onClick={ increaseFontSize }>+</button>
                </div>

                <button className={"ml-2 mr-1 px-2 py-1 rounded-md border disabled:opacity-50 disabled:bg-gray-100 "+ ( isBold == "bold" ? " bg-sky-300 hover:bg-sky-400" : " bg-gray-100 hover:bg-gray-200" )}
                        value={isBold}
                        disabled={fontFamily === "Monotype Corsiva"}
                        onClick={(e) => setTextBold(isBold == "bold" ? "" : "bold" )}>
                    <i className="bi-type-bold" style={{fontSize: "1rem"}}></i>
                </button>
                <button className={"mx-1 px-2 py-1 rounded-md border disabled:opacity-50 disabled:bg-gray-100 "+ ( isItalic == "italic" ? " bg-sky-300 hover:bg-sky-400" : " bg-gray-100 hover:bg-gray-200" )}
                    value={isItalic}
                    disabled={fontFamily === "Monotype Corsiva"}
                    onClick={(e) => setTextItalic( isItalic == "italic" ? "" : "italic" )}>
                    <i className="bi-type-italic" style={{fontSize: "1rem"}}></i>
                </button>
                {/* <button className={'mr-2 ml-1 px-2 py-1 rounded-md border'+ ( textDecoration == "underline" ? " bg-sky-300 hover:bg-sky-400" : " bg-gray-100 hover:bg-gray-200" ) }
                    onClick={(e) => setTextDecoration(( textDecoration == "underline" ? "" : "underline" ))}>
                    <i className="bi-type-underline" style={{fontSize: "1rem"}}></i>
                </button> */}

                <button className={'ml-2 mr-1 px-2 py-1 rounded-md border'+ ( textAlignment == "left" ? " bg-sky-300 hover:bg-sky-400" : " bg-gray-100 hover:bg-gray-200" ) }
                    onClick={(e) => setTextAlignment("left")}>
                    <i className="bi bi-text-left" style={{fontSize: "1rem"}}></i>
                </button>
                <button className={'mx-1 px-2 py-1 rounded-md border'+ ( textAlignment == "center" ? " bg-sky-300 hover:bg-sky-400" : " bg-gray-100 hover:bg-gray-200" ) }
                    onClick={(e) => setTextAlignment("center")}>
                    <i className="bi bi-text-center" style={{fontSize: "1rem"}}></i>
                </button>
                <button className={'mr-2 ml-1 px-2 py-1 rounded-md border mr-2'+ ( textAlignment == "right" ? " bg-sky-300 hover:bg-sky-400" : " bg-gray-100 hover:bg-gray-200" ) }
                    onClick={(e) => setTextAlignment("right")}>
                    <i className="bi bi-text-right" style={{fontSize: "1rem"}}></i>
                </button>

                <label className="ml-2 text-center" htmlFor="textColorInput">Text Color:</label>
                <input id="textColorInput" type="color" className="ml-2 mr-2 border rounded-md outline-none w-6" value={textColor} onChange={(e) => { setTextColor(e.target.value); }}/>
                
                {/* <button className="ml-2 mr-1 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={ shiftUp }> <i className="bi bi-front mr-1"></i> Bring Forward </button>
                <button className="mr-2 ml-1 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={ shiftDown }> <i className="bi bi-back mr-1"></i> Send Backward </button> */}

                <button className="p-1 rounded-md bg-red-500 hover:bg-red-600 border" onClick={deleteItem}>
                    <TrashIcon className='text-white w-5'/>
                </button>

                <button className=" py-1 m-2 px-2 py-1 rounded-md bg-gray-100 hover:bg-gray-200 text-black border flex items-center ml-8" onClick={openSettingsModal}>
                    {/* <CogIcon className='w-4 mr-1' />  */}
                    <AdjustmentsIcon className='w-4 mr-1' />
                    <span className='mb-1'>Project Settings</span>
                </button>  

            </div>
            <div className="w-100 flex items-center flex-wrap">
                            
            </div>    
        </>

     );
}
 
export default Topbar;