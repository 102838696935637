import { useState, useEffect, useCallback } from 'react';
import { RefreshIcon } from '@heroicons/react/solid';
import { Link, useHistory } from 'react-router-dom';
import { getAdminPanelDetail, getUsersList } from '../../apis/admin'
import { checkIfNullOrUndefinedOrEmpty, compactNumber } from '../../utilities/general'
import { debounce } from "lodash";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const AdminDashboard = ({ children, view }) => {
    const [searchWord, setSearchWord] = useState('');
    const [openDropDown, setOpenDropDown] = useState(false);
    const [adminData, setAdminData] = useState({})
    const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
    const [data, setData] = useState([])
    const searchResult = (e) => {
        setSearchWord(e.target.value);
        debounceFn(e.target.value);
    };

    const history = useHistory();

    let token, member;

    try {
        token = JSON.parse(atob(cookies.get('GG_token')));
        member = atob(cookies.get("__User_Ver"));
    }
    catch(err) {

    }

    useEffect(() => {
        if(checkIfNullOrUndefinedOrEmpty(token)) {
            history.push("/");
        }
        else if(member !== '"admin"') {
            history.push("/unauthorized");
        }
    }, [])

    function handleDebounceFn(inputValue) {
        getUsersList(`${view.toUpperCase()}S`, 0, inputValue)
            .then((res) => {
                setData(res.data.data)
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        getAdminData()
    }, [])

    const getAdminData = () => {
        getAdminPanelDetail()
            .then((res) => {
                setAdminData(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (<div className='container 2xl:max-w-7xl mx-auto' style={{minHeight:'calc(100vh - 100px)'}}>
        <div className='container mx-auto my-5 px-4'>
            <h2 className='text-4xl font-bold'>Dashboard</h2>
        </div>
        <main className="container mx-auto py-6 px-4">
            <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-3 lg:gap-4 md:gap-6'>
                <div className="w-full rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white pointer-events-none">
                    <div className='py-8'>
                        <span className="text-gray-800 text-5xl block font-semibold">{compactNumber(adminData?.total_shops ? adminData?.total_shops : 0)}</span>
                        <small className='text-gray-700 text-sm pt-4 inline-block'>Total Shops</small>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white pointer-events-none">
                    <div className='py-8'>
                        <span className="text-gray-800 text-5xl block font-semibold">{compactNumber(adminData?.total_vendors ? adminData?.total_vendors : 0)}</span>
                        <small className='text-gray-700 text-sm pt-4 inline-block'>Total vendors</small>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white pointer-events-none">
                    <div className='py-8'>
                        <span className="text-gray-800 text-5xl block font-semibold">{compactNumber(adminData?.total_agents ? adminData?.total_agents : 0)}</span>
                        <small className='text-gray-700 text-sm pt-4 inline-block'>Total agents</small>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white pointer-events-none">
                    <div className='py-8'>
                        <span className="text-gray-800 text-5xl block font-semibold">
                            &#8377; {compactNumber(+adminData?.revenue_collected ? +adminData?.revenue_collected : 0)}
                        </span>
                        <small className='text-gray-700 text-sm pt-4 inline-block'>Revenue earned</small>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white pointer-events-none">
                    <div className='py-8'>
                        <span className="text-gray-800 text-5xl block font-semibold">{compactNumber(adminData?.total_projects ? adminData?.total_projects : 0)}</span>
                        <small className='text-gray-700 text-sm pt-4 inline-block'>Total Projects</small>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white pointer-events-none">
                    <div className='py-8'>
                        <span className="text-gray-800 text-5xl block font-semibold">{compactNumber(adminData?.total_documents_generated ? adminData?.total_documents_generated : 0)}</span>
                        <small className='text-gray-700 text-sm pt-4 inline-block'>Total files created</small>
                    </div>
                </div>
            </div>
        </main>

        <section>
            <div className="container mx-auto py-6 px-4">
                <h1 className="text-3xl py-4 border-b mb-5">Showing {view === "all" ? view.toLowerCase() : view.toLowerCase() + "s"}</h1>
                {children}
            </div>
        </section>

    </div>);
}

export default AdminDashboard;