import {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import { XIcon, ChevronLeftIcon, ClipboardCheckIcon } from '@heroicons/react/outline';
import Modal from "../Modal/Modal";
import { checkIfNotNullOrUndefinedOrEmpty, checkIfNullOrUndefinedOrEmpty } from "../../utilities/general";
import { AsyncPaginate } from "react-select-async-paginate";

const EditorHeader = ({templateName, createNewTemplate, setTemplateName, saveTemplate, 
                templates, setSelectedTemplate, loadTemplate, showRenameModal, setShowRenameModal, setShowSettingsModal, 
                templateOptions, editTemplate, loadOptions}) => {

    const history = useHistory();
    toast.configure()
    const [tempName, setTempName] = useState(templateName);

    const [showGoBackModal, setShowBackModal] = useState(false);
    const [showNewTemplatePrompt, setShowNewTemplatePrompt] = useState(false);

    const [saveNameOnly, setSaveNameOnly] = useState(true);

    const [isUploadButtonDisabled, setUploadButtonDisabled] = useState(false);

    const [tempSelectedTemplate, setTempSelectedTemplate] = useState('');

    useEffect(() => {
        setTempName(templateName)
    }, [templateName])

    const saveTemplateName = () => {
        if(tempName !== "" && tempName !== null && tempName !== undefined) {
            setTemplateName(tempName)
            setShowRenameModal(false);
        } else {
            toast.error("Template name cannot be blank.", {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: false
              })
        }
    }

    const goBackToDashboard = () => {
        history.push("/home");
    }

    // const openSettingsModal = () => {
    //     setShowSettingsModal(true);
    // }

    const callCreateNewTemplate = () => {
        createNewTemplate();
        setShowNewTemplatePrompt(false);
    }

    const callSaveTemplate = () => {
        if(editTemplate) {
            saveTemplate();
        }
        else if(checkIfNullOrUndefinedOrEmpty(templateName)) {
            setSaveNameOnly(false);
            setShowRenameModal(true);
        }
        else {
            saveTemplate();
        }
    }

    const callSaveTemplateName = () => {
        if(editTemplate || saveNameOnly) {
           saveTemplateName();
        }
        else {
            if(tempName !== "" && tempName !== null && tempName !== undefined) {
                setTemplateName(tempName)
                setShowRenameModal(false);
                saveTemplate(tempName);
            }
            else {
                // setShowRenameModal(true);
                toast.error("Please add a name for the template", {
                    position: 'top-left',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: false
                })
            }
        }
    }

    const openTemplate = () => {
        if(checkIfNotNullOrUndefinedOrEmpty(tempSelectedTemplate)) {
            // setSelectedTemplate(tempSelectedTemplate);
            console.log(tempSelectedTemplate)
            loadTemplate(tempSelectedTemplate?.value);
        }
        else {
            toast.error("Please select a template to open it.", {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: false
              })
        }
    }

    const openForSavingName = () => {
        setShowRenameModal(true);
        setSaveNameOnly(true);
    }

    const closeRenameModal = () => {
        setSaveNameOnly(true);
        setShowRenameModal(false);
    }

    return (
        <header className="editor-header bg-slate-900	text-sm">
            <div className="container-fluid flex items-center justify-between">
                <div className="flex items-center">
                    <button className="mx-2 flex items-center text-white px-2 rounded-md bg-slate-700 hover:bg-slate-800 text-white" onClick={() => setShowBackModal(true)}>
                        <ChevronLeftIcon className="w-4"/>
                        Go back
                    </button>
                    <span className="text-white ml-4">
                        { templateName !== "" ? templateName : "New unsaved template*" }
                    </span>
                    <button className="text-xs ml-2 px-2 py-0.5 rounded-md bg-sky-600 hover:bg-sky-700 text-white border" onClick={openForSavingName}>
                        { editTemplate ? "Rename" : "Set name"}
                    </button>
                </div>
                <div className="flex items-center">
                    {/* <button className=" py-1 m-2 px-4 py-1 rounded-md bg-slate-700 hover:bg-slate-800 text-white" onClick={openSettingsModal}>Settings</button>   */}
                    <p className="text-white mr-2">Saved templates: </p>
                    {/* <select className="py-1 ml-3 border rounded-md py-1 px-2 outline-none overflow-y-scroll" name="" id="" onChange={e => { setTempSelectedTemplate(e.target.value); console.log(e.target.value) }}>
                        <option value="" disabled selected>Select Template</option>
                        {
                            templateOptions?.map((t, i) => { 
                                return ( <option key={i} value={t?.value} >{t?.label}</option> )
                            })
                        }
                    </select> */}
                    <AsyncPaginate
                        styles={{
                            control: () => ({
                                // none of react-select's styles are passed to <Control />
                                width: 200,
                                display: "flex",
                                height: 25,
                                backgroundColor: "white",
                                borderRadius: 5
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected ? '#0c4a6e' : (state.isFocused ? '#bae6fd' : 'white'),
                                padding: 5,
                              })
                        }}
                        value={tempSelectedTemplate}
                        loadOptions={loadOptions}
                        options={templates}
                        isSearchable={false}
                        lab
                        onChange={(e) => {
                            console.log(e)
                            console.log("HERE SELELLELELELL")
                            setTempSelectedTemplate(e)
                        }}
                    />
                    <button className=" py-1 m-2 px-4 py-1 rounded-md bg-slate-700 hover:bg-slate-800 text-white" onClick={ openTemplate }>Open Template</button>  
                    <button className=" py-1 m-2 mx-5 px-4 py-1 rounded-md bg-slate-700 hover:bg-slate-800 text-white" onClick={ () => { setShowNewTemplatePrompt(true) } }>
                        <i class="bi bi-file-earmark-plus"></i> New Template
                    </button>                                      
                    <button className=" py-1 m-2 px-4 py-1 rounded-md bg-sky-600 hover:bg-sky-700 text-white border flex items-center" onClick={ callSaveTemplate }>
                        <ClipboardCheckIcon className="w-4 mr-1"/>
                        Save and Continue
                    </button>
                </div>
            </div>

            <Modal
                isOpen={showRenameModal}
                onClose={() => { setShowRenameModal(false); }}
            >
                <div className="flex justify-between">
                    <h5 className="font-bold text-lg mb-2">{ editTemplate ? "Rename Template" : "Name Template" }</h5>
                    <button type="button" className="m-2" onClick={closeRenameModal} aria-label="Close">
                        <XIcon className='text-black w-6'/>
                    </button>
                </div>
                <div className="m-2">
                    <input className="mb-3 border rounded-md py-1 px-2 w-full outline-none" type="text" value={tempName} onChange={(e) => {setTempName(e.target.value)}}/>
                </div>
                <div className="modal-footer">
                    <button type="button" className="px-4 py-1 rounded-md bg-red-500 hover:bg-red-600 text-white border" onClick={closeRenameModal}>Cancel</button>
                    <button type="button" className="ml-2 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={callSaveTemplateName}>
                       <span className="mb-1">{ (editTemplate || saveNameOnly) ? "Save name" : "Save name and continue" }</span>
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={showGoBackModal}
                onClose={ () => { setShowBackModal(false) }}
            >
                <div className="flex justify-between">
                    <h5 className="font-bold text-lg m-2">Go back to dashboard</h5>
                    <button type="button" className="m-2" onClick={() => setShowBackModal(false)} aria-label="Close">
                        <XIcon className='text-black w-6'/>
                    </button>
                </div>
                <div className="mx-2 mt-2 mb-5">
                    <p className="">
                        Are you sure you wish to go back to the dashboard? 
                        {/* The project will get reset. */}
                    </p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="px-4 py-1 rounded-md bg-red-500 hover:bg-red-600 text-white border" onClick={() => setShowBackModal(false)}>Cancel</button>
                    <button type="button" className="ml-2 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={goBackToDashboard}>Yes</button>
                </div>
            </Modal>

            <Modal
                isOpen={showNewTemplatePrompt}
                onClose={ () => { setShowNewTemplatePrompt(false) }}
            >
                <div className="flex justify-between">
                    <h5 className="font-bold text-lg m-2">Create New Template?</h5>
                    <button type="button" className="m-2" onClick={() => setShowNewTemplatePrompt(false)} aria-label="Close">
                        <XIcon className='text-black w-6'/>
                    </button>
                </div>
                <div className="m-2">
                    <p>
                        Are you sure you wish to create a new template?
                        {/* The project will get reset. */}
                    </p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="px-4 py-1 rounded-md bg-red-500 hover:bg-red-600 text-white border" onClick={() => setShowNewTemplatePrompt(false)}>Cancel</button>
                    <button type="button" className="ml-2 px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={callCreateNewTemplate}>Yes</button>
                </div>
            </Modal>
        </header>
    )
}

export default EditorHeader;