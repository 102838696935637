import React, { useEffect } from 'react'

function Dropdown({ className, children, show, onHide }) {

    useEffect(() => {
        if(show) {
            document.addEventListener('click', closeWhenClickedOutside);
        }
    }, [show])

    const closeWhenClickedOutside = (event) => {
        if (!event.target.closest('.dropdown-menu')) {
            onHide();
            document.removeEventListener('click', closeWhenClickedOutside);
        }
    }

    return (
        <>
            <div hidden={!show} className={`dropdown-menu origin-top-right absolute z-40 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${className}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                <div className="py-1" role="none">
                    {children}
                </div>
            </div>
        </>
    )
}

export default Dropdown
