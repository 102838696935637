import axios from "axios";
import Config from "../../Config";
import Cookies from "universal-cookie";
import { decode } from "base-64";

const cookies = new Cookies();

var token = cookies.get('GG_token');
export const getAgent = async (search = '') => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("search", search);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/getAgentList`,
        data: fd
    })
}
export const createAgent = async (data) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("email", data.email);
    fd.append("phone", data.phone);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/createAgent`,
        data: fd
    })
}
export const seachAgent = async (search) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("search", search);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/getAgentList`,
        data: fd
    })
}