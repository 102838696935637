import { useState, useEffect, useCallback } from 'react';
import { PlusIcon, RefreshIcon } from '@heroicons/react/outline'
import { createShop, getOTP, getShopList, seachShopList, verifyOTP } from '../../apis/vendors/vendors'
import { debounce } from "lodash";
import ReactPaginate from 'react-paginate';
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Spinner from '../../components/spinner/Spinner'
import { checkIfNullOrUndefinedOrEmpty } from '../../utilities/general';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const cookies = new Cookies();

function Agents() {
    toast.configure();

    const [showModal, setShowModal] = useState(false);
    const [showClientAddModal, setShowClientAddModal] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [clientData, setClientData] = useState({ firstName: '', lastName: '', company: '', address: '', email: '', pincode: '', gst: '', phone: '' });
    const [shopList, setShopList] = useState([])
    const [searchWord, setSearchWord] = useState('');
    const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
    const history = useHistory();

    const [numberOfPages, setNumberOfPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);

    const PAGE_OFFSET = 10;

    let token, member;

    try {
        token = JSON.parse(atob(cookies.get("GG_token")));
        member = atob(cookies.get("__User_Ver"));    
    }
    catch(err) {}

    useEffect(() => {
        if(checkIfNullOrUndefinedOrEmpty(token)) {
            history.push("/");
        }
        else if(member !== '"agent"') {
            history.push("/unauthorized");
        }
    }, [])
    
    const searchResult = (e) => {
        setSearchWord(e.target.value);
        debounceFn(e.target.value);
    };
    const [loadingList, setLoadingList] = useState(false)
    function handleDebounceFn(inputValue) {
        seachShopList(inputValue)
            .then((res) => {
                setShopList(res.data.data)
                setLoadingList(false)
            })
            .catch((err) => {
                console.log(err);
                setLoadingList(false)
            })
    }
    useEffect(() => {
        handleGetShopList();
    }, [])
    const handleGetShopList = () => {
        setLoadingList(true)
        getShopList()
            .then((res) => {
                if(res.data.data.count%PAGE_OFFSET == 0) {
                    setNumberOfPages(Math.floor(res.data.data.count/PAGE_OFFSET));
                }
                else {
                    setNumberOfPages(res.data.data.count/PAGE_OFFSET + 1);
                }
                setShopList(res.data.data.data)
                setLoadingList(false)
            })
            .catch((err) => { console.log(err); setLoadingList(false) })
    }
    const [showVerifyBtn, setShowVerifyBtn] = useState(false)
    const [amount, setAmount] = useState('')
    const [isOptSafe, setIsOtpSafe] = useState(true);
    const [isAmountSafe, setIsAmountSafe] = useState(true);
    const [otp, setOtp] = useState('')
    const [userId, setUserId] = useState('')
    const validAmount = () => {
        let isSafe = true
        if (amount === '' || amount === undefined || amount === null) {
            setIsAmountSafe(false)
            isSafe = false
        }
        return isSafe
    }
    const isSafeOTP = () => {
        let isSafe = true
        if (otp === '' || otp === undefined || otp === null) {
            setIsOtpSafe(false)
            isSafe = false
        }
        return isSafe
    }
    const generateOTP = () => {
        if (validAmount()) {
            setIsOtpSent(true)
            setShowVerifyBtn(true)
            getOTP(userId, amount)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => { console.log(err) })
            setTimeout(() => {
                setIsOtpSent(false)
            }, 20000)
        }
    }
    const otpHandler = (id) => {
        setShowModal(true)
        setUserId(id)
    }
    const [creatingVendor, setCreatingVendor] = useState(false)
    const handleCreateVendor = (e) => {
        setCreatingVendor(true)
        e.preventDefault();
        createShop(clientData)
            .then(res => {
                setShowClientAddModal(false)
                handleGetShopList();
                setCreatingVendor(false)
                resetClientAdd()
            })
            .catch(err => {
                console.log(err, err?.response, err?.message)
                if(err?.response?.data?.data?.startsWith("The email field must contain a unique value")) {
                    toast.error("The entered email id has already been registered.", {
                        position: 'top-left',
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: false
                    })
                }
                setCreatingVendor(false)
            })
    }
    const handleVerifyOtp = () => {
        if (isSafeOTP()) {
            verifyOTP(otp)
                .then((res) => {
                    if (res.data.message === 'Task Completed') {
                        setShowModal();
                        handleGetShopList();
                        setIsOtpSafe(true);
                        setIsAmountSafe(true);
                        setShowVerifyBtn(false);
                        setAmount('')
                    }
                })
                .catch((err) => { 
                    alert("wrong otp")
                 })
        }
    }

    const resetClientAdd = () => {
        setClientData({ firstName: '', lastName: '', company: '', address: '', email: '', pincode: '', gst: '', phone: '' })
    }

    const handlePageClick = (e) => {
        setCurrentPage(e.selected);
    }

    return (
        <>
            <div className="container 2xl:max-w-7xl mx-auto">
                <div className="container mx-auto py-6 px-4">
                    <div>
                        <div className="w-40 rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white" onClick={() => setShowClientAddModal(true)}>
                            <div className='py-8'>
                                <PlusIcon className='px-10' />
                                <small className='text-gray-700 text-sm pt-4 inline-block'>Create Shop</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container 2xl:max-w-7xl mx-auto">
                <div className="container mx-auto py-6 px-4">
                    <h1 className="text-3xl py-4 border-b mb-10">Shop</h1>
                    <div className="mb-4 flex items-center">
                        <div className="pr-4">
                            <div className="relative">
                                <input type="search"
                                    className="w-full pl-10 pr-4 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                    placeholder="Search shop..."
                                    value={searchWord}
                                    onChange={(e) => searchResult(e)} />
                                <div className="absolute top-0 left-0 inline-flex items-center p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                                        strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                        <circle cx="10" cy="10" r="7" />
                                        <line x1="21" y1="21" x2="15" y2="15" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button onClick={handleGetShopList} className='px-4 py-1 bg-sky-900 group rounded hover:bg-gray-200 hover:ring-1 hover:ring-gray-800'>
                                <RefreshIcon className='w-7 text-white group-hover:text-gray-700' />
                            </button>
                        </div>
                    </div>
                    {loadingList ? <div className='flex items-center justify-center mt-4'>
                        <Spinner width={40} height={40} />
                    </div> : <>
                        <div>
                            {shopList.length > 0 ? <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Sr.no
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Shop name
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                E-mail
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Credits
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Recharge
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shopList?.map((shopData, idx) => {
                                            return <tr key={idx}>
                                                <td className="border-dashed border-t border-gray-200 userId">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1 + (currentPage * PAGE_OFFSET)}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{shopData.fullName}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{shopData.email}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{shopData.credits}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        <button className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border"  onClick={() => otpHandler(shopData.user_id)}>
                                                            Recharge
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div> : <div className='text-center text-gray-700 text-2xl mt-5'><span>No data found</span></div>}

                            {
                                numberOfPages > 1 &&
                                <ReactPaginate
                                breakLabel='...'
                                nextLabel='Next'
                                previousLabel='Previous'
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                pageCount={numberOfPages}
                                renderOnZeroPageCount={"ABC"}
                                containerClassName='flex p-3 w-full justify-center'
                                pageClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                                activeClassName='px-3 py-1 rounded-md border mr-3 bg-sky-300 hover:bg-sky-400'
                                previousClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                                nextClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                                breakClassName='px-3 py-1 mr-3'
                                />
                            }
                            <div className='mb-16'></div>
                        </div>
                    </>}
                </div>
            </div>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">Recharge</h3>
                                </div>
                                <div className="relative flex-auto" style={{ width: '35rem' }}>
                                    <div className="px-5 py-4">
                                        <form className="grid gap-4">
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="first-name" className="block text-sm font-medium text-gray-700">Amount <span className='text-red-500'>*</span></label>
                                                        <input type="text" name="company-name" value={amount} onChange={(e) => setAmount(e.target.value)} id="first-name" autocomplete="given-name" required className={`border rounded-md py-1 px-2 w-full outline-none ${!isAmountSafe && 'required:border-red-500'}`} />
                                                    </div>
                                                    {!isAmountSafe && <span className='text-red-500 font-semibold text-sm'>Please enter the amount</span>}
                                                </div>
                                            </div>
                                            {showVerifyBtn && <div className='col-start-1 col-end-7'>
                                                <div className='grid grid-cols-4 gap-4 items-end'>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="first-name" className="block text-sm font-medium text-gray-700">OTP <span className='text-red-500'>*</span></label>
                                                        <input type="text" onFocus={() => setIsOtpSafe(true)} onBlur={() => setIsOtpSafe(otp === '' ? false : true)} name="company-name" id="first-name" value={otp} onChange={(e) => setOtp(e.target.value)} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                    <div className="">
                                                        <div>
                                                            <button type='button' className='bg-gray-200 hover:bg-gray-300 px-6 py-2 text-sm rounded-md w-full' onClick={handleVerifyOtp}>verify</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!isOptSafe && <span className='text-red-500 font-semibold text-sm block'>Please enter the OTP!!</span>}
                                            </div>}
                                            {!isOtpSent && <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <button type='button' className='bg-gray-200 hover:bg-gray-300 px-6 py-2 text-sm rounded-md' onClick={generateOTP}>Generate OTP</button>
                                                </div>
                                            </div>}
                                        </form>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => {setShowModal(false);setIsOtpSafe(true);setIsAmountSafe(true);setShowVerifyBtn(false);setAmount('')}}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            {showClientAddModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">Add Shop</h3>
                                </div>
                                <div className="relative flex-auto" style={{ width: '35rem' }}>
                                    <div className="px-5 py-4">
                                        <form className="grid gap-4">
                                            <div className='col-start-1 col-end-7'>
                                                <div className="grid grid-cols-6 gap-4">
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label for="first-name" className="block text-sm font-medium text-gray-700">First name <span className='text-red-500'>*</span></label>
                                                        <input type="text" name="first-name" value={clientData.firstName} onChange={(e) => setClientData((prevData) => { prevData.firstName = e.target.value; return { ...prevData } })} id="first-name" autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label for="last-name" className="block text-sm font-medium text-gray-700">Last name <span className='text-red-500'>*</span></label>
                                                        <input type="text" name="last-name" value={clientData.lastName} onChange={(e) => setClientData((prevData) => { prevData.lastName = e.target.value; return { ...prevData } })} id="last-name" autocomplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="first-name" className="block text-sm font-medium text-gray-700">Company name <span className='text-red-500'>*</span></label>
                                                        <input type="text" name="company-name" value={clientData.company} onChange={(e) => setClientData((prevData) => { prevData.company = e.target.value; return { ...prevData } })} id="first-name" autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div className="grid grid-cols-6 gap-4">
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label for="address" className="block text-sm font-medium text-gray-700">Address</label>
                                                        <input type="text" name="address" value={clientData.address} onChange={(e) => setClientData((prevData) => { prevData.address = e.target.value; return { ...prevData } })} id="first-name" autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                    <div className="col-span-3 sm:col-span-3">
                                                        <label for="pincode" className="block text-sm font-medium text-gray-700">Pincode</label>
                                                        <input type="text" name="pincode" value={clientData.pincode} onChange={(e) => setClientData((prevData) => { prevData.pincode = e.target.value; return { ...prevData } })} id="last-name" autocomplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="email" className="block text-sm font-medium text-gray-700">Email <span className='text-red-500'>*</span></label>
                                                        <input type="email" name="email" id="first-name" value={clientData.email} onChange={(e) => setClientData((prevData) => { prevData.email = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                                                        <input type="tel" name="phone" id="first-name" value={clientData.phone} onChange={(e) => setClientData((prevData) => { prevData.phone = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-start-1 col-end-7'>
                                                <div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label for="gst" className="block text-sm font-medium text-gray-700">GST ID</label>
                                                        <input type="text" name="gst" id="first-name" value={clientData.gst} onChange={(e) => setClientData((prevData) => { prevData.gst = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    {creatingVendor ? <div><Spinner width={20} height={20} /></div> : <button
                                        className="bg-sky-900 text-white rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleCreateVendor}
                                    >
                                        Save
                                    </button>}
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => {setShowClientAddModal(false);resetClientAdd()}}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}

export default Agents
