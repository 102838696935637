import { SET_TEMPLATE_META } from "../constants";

const initialTemplateData = {
    templateMeta: {}
};

const templateData = (state = initialTemplateData, action) => {
    switch(action.type) {
        case SET_TEMPLATE_META: {
            // console.log("State preserved. "+ action.payload)
            return {...state, templateMeta:action.payload};
        }
        default:
            return state;
    }
    // console.log("Redux connected!")
    // return state;
};

export default templateData;
  