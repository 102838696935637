import axios from "axios";
import Config from "../../Config";
import Cookies from "universal-cookie";
import { decode } from "base-64";

const cookies = new Cookies();

var token = cookies.get('GG_token');
export const getAdminPanelDetail = async () => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    return await axios({ method: "POST", url: `${Config.apiurl}user/getDetails`, data: fd })
}


export const getUsersList = async (type, pageNo, searchKey = '',) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("type", type);
    fd.append("search_key", searchKey);
    fd.append("pageNo", pageNo);
    fd.append("limit", 20);
    return await axios({ method: "POST", url: `${Config.apiurl}user/getUsers`, data: fd })
}

export const getSingleUserList = async (user_id, type, list, pageNo = 0, searchKey = '',) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("user_id", user_id);
    fd.append("user_type", type);
    fd.append("list", list);
    fd.append("pageNo", pageNo);
    fd.append("search_key", searchKey);
    return await axios({ method: "POST", url: `${Config.apiurl}user/getList`, data: fd })
}

export const getPaymentList = async (user_id, type, pageNo = 0, searchKey = '',) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("user_id", user_id);
    fd.append("user_type", type);
    fd.append("pageNo", pageNo);
    fd.append("search_key", searchKey);
    return await axios({ method: "POST", url: `${Config.apiurl}user/getPaymentHistory`, data: fd })
}

export const assignUser = async (client_id = '', vendor_id = '', agent_id = '', action, assignment) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("client_id", client_id); //charul's id
    fd.append("vendor_id", vendor_id); //iDigi
    fd.append("agent_id", agent_id); //
    fd.append("action", action);
    fd.append("assignment", assignment); //screen
    return await axios({ method: "POST", url: `${Config.apiurl}user/userAssign`, data: fd })
}

export const getSingleUserData = async (client_id = '') => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("client_id", client_id);
    return await axios({ method: "POST", url: `${Config.apiurl}user/getUserData`, data: fd })
}

export const deleteUser = async (client_id = '',status) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("user_id", client_id);
    fd.append("status", status === 'active' ? 1 : 0);
    return await axios({ method: "POST", url: `${Config.apiurl}user/updateUserStatus`, data: fd })
}

export const updateData = async (data, client_id = '',) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("client_id", client_id);
    fd.append("first_name", data.first_name);
    fd.append("last_name", data.last_name);
    fd.append("phone", data.phone);
    fd.append("email", data.email);
    fd.append("address", data?.address);
    fd.append("pincode", data?.pincode);
    fd.append("company", data?.company);
    fd.append("gst", data?.gst_id);
    return await axios({ method: "POST", url: `${Config.apiurl}user/updateUserData`, data: fd })
}

