import axios from "axios";
import Config from "../Config";
import Cookies from "universal-cookie";
import { decode } from "base-64";

const cookies = new Cookies();

var token = cookies.get('GG_token');

export const getProjectListByInput = async (search = '') => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("search", search);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}project/getProjectList`,
        data: fd
    })
}

export const getProjectList = async (pageNo) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("pageNo", pageNo);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}project/getProjectList`,
        data: fd
    })
}

export const downloadFiles = async (projectId) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("projectId", projectId);
    return await axios({method: "POST",url: `${Config.apiurl}/project/getProjectMediaList`,data: fd})
}

export const downloadAllFiles = async (projectId) => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    fd.append("event_id", projectId);

    return await axios(
        {
            method: "POST",
            url: `${Config.apiurl}/project/downloadAll`,
            data: fd
        }
    );
}