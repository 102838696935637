export default {
    apiurl: 'https://api.renderly.in/',
    identity: 'certhandler',
    tokenName: "GG_tokens",
    clientID: "388582543808-ehm54b68t28ljplbqk76tei24gdk77dh.apps.googleusercontent.com",
    imageMediaPrefix: 'https://wmmedia.sgp1.digitaloceanspaces.com/graphic-goose',
    predefinedCanvasSizes: {
        "A4" : {width: 297, height: 210},
        "A3" : {width: 420, height: 297},
        "A2" : {width: 594, height: 420},
        "Letter" : {width: 279.4, height: 215.9},
        "Custom" : {width: 200, height: 200},
    },
    dpiValues: [72, 96, 144, 300],
    perPageCost: 0.02,
}