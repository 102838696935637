import axios from "axios";
import Config from "../../Config";
import Cookies from "universal-cookie";
import { decode } from "base-64";

const cookies = new Cookies();

var token = cookies.get('GG_token');

export const getUserAccount = async () => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/getUserAccountInfo`,
        data: fd
    })
}
export const getUserDetails = async () => {
    let fd = new FormData();
    fd.append("token", decode(token));
    fd.append("identity", Config.identity);
    return await axios({
        method: "POST",
        url: `${Config.apiurl}user/getDetails`,
        data: fd
    })
}