import {useState} from 'react';

const Contact = () => {

    const [errors, setErrors] = useState();

    return (<div className="flex justify-center items-start mx-3 md:mx-0">
        <section className="flex flex-wrap w-full md:w-2/3 lg:w-2/4">
            <h2 className="text-center w-full mt-5 text-xl font-bold">Contact Us</h2>
            <label htmlFor="" className="mt-4 mb-1 w-full">Full Name: *</label>
            <input type="text" className="my-1 w-full border rounded-md py-1 px-2 w-full outline-none"/>


            <div className="w-full md:w-1/2 flex flex-col">
                <label htmlFor="" className="mt-4 mb-1 ">Email Address: *</label>
                <input type="text" className="my-1 border rounded-md py-1 px-2 outline-none md:mr-2"/>
            </div>
            <div className="w-full md:w-1/2 flex flex-col">
                <label htmlFor="" className="mt-4 mb-1 ">Contact Number: *</label>
                <input type="text" className="my-1 border rounded-md py-1 px-2 outline-none"/>
            </div>

            <label htmlFor="" className="w-full mt-4 mb-1">Your query: *</label>
            <textarea className="w-full border rounded-md py-1 px-2 w-full outline-none min-h-[8rem]"/>
            <button className="ml-auto mt-4 px-4 py-1 rounded-md bg-sky-300 hover:bg-sky-400 border">
                Submit
            </button>
        </section>
    </div>);
}
 
export default Contact;