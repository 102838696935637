import { XCircleIcon } from '@heroicons/react/outline';
import { EyeIcon, PencilIcon, RefreshIcon, SupportIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { assignUser, deleteUser, getPaymentList, getSingleUserData, getUsersList, updateData } from '../../apis/admin';
import { getOTP, verifyOTP } from '../../apis/vendors/vendors';
import Modal from '../../components/Modal/Modal';
import Spinner from '../../components/spinner/Spinner'
import { numberWithComma } from '../../utilities/general';

function Shops() {
  const [searchWord, setSearchWord] = useState('');
  const [openDropDown, setOpenDropDown] = useState(false);
  const [showPaymentsModal, setShowPaymentsModal] = useState();
  const [showAssignVendorModal, setShowAssignVendorModal] = useState();
  const [showAssignAgentModal, setShowAssignAgentModal] = useState();
  const [showeditModal, setShowEditModal] = useState(false);
  const [shopList, setShopList] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [vendorLists, setVendorList] = useState([])
  const PAGE_OFFSET = 10;

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
  const searchResult = (e) => {
    setSearchWord(e.target.value);
    debounceFn(e.target.value);
  };
  function handleDebounceFn(inputValue) {
    getUsersList(`SHOPS`, 0, inputValue)
      .then((res) => {
        setShopList(res.data.data.data)
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    getShopsList(0);
  }, [])
  const getShopsList = (pageNo) => {
    setIsDataLoading(true)
    getUsersList('SHOPS', pageNo)
      .then((res) => {
        if(res.data.data.count%PAGE_OFFSET == 0) {
          setNumberOfPages(Math.floor(res.data.data.count/PAGE_OFFSET))
        }
        else {
          setNumberOfPages(Math.floor(res.data.data.count/PAGE_OFFSET) + 1)
        }
        setShopList(res.data.data.data)
        setIsDataLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
        setIsDataLoading(false)
      })
  }
  const [vendorLoading, setVendorLoading] = useState(false)
  const getVendorList = () => {
    setShowAssignVendorModal(true)
    getUsersList('VENDORS', 0)
      .then((res) => {
        setVendorList(res.data.data.data)
        setVendorLoading(false)
      })
      .catch((err) => {
        setVendorLoading(false)
      })
  }
  const [agentLists, setAgentLists] = useState([])
  const getAgentList = () => {
    setShowAssignAgentModal(true)
    getUsersList('AGENTS', 0)
      .then((res) => {
        setAgentLists(res.data.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const [paymentData, setPaymentData] = useState([])
  const [paymentLoading, setPaymentLoading] = useState(true)
  const paymentDetail = (userID) => {
    setShowPaymentsModal(true)
    getPaymentList(userID, 'SHOP', 0)
      .then((res) => {
        setPaymentData(res.data.data.data)
        setPaymentLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setPaymentLoading(false)
      })
  }

  const [openRechargeModal, setOpenRechargeModal] = useState(false)
  const handleRecharge = () => {
    setOpenRechargeModal(true)
  }
  const [amount, setAmount] = useState('')
  const [userId, setUserId] = useState('')
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isShowVerify, setIsShowVerfy] = useState(false);
  const [otp, setOtp] = useState('');
  const generateOTP = () => {
    setIsOtpSent(true)
    setIsShowVerfy(true)
    getOTP(userId, amount)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => { console.log(err) })
    setTimeout(() => {
      setIsOtpSent(false)
    }, 20000)
  }
  const handleVerifyOtp = () => {
    if (otp !== '' && otp !== null && otp !== undefined) {
      verifyOTP(otp)
        .then((res) => {
          if (res.data.message === 'Task Completed') {
            setOpenRechargeModal();
            paymentDetail(userId)
          }
        })
        .catch((err) => { console.log(err) })
    }
  }
  const [client_id, setClient_id] = useState('')
  const [agent_id, setAgent_id] = useState('')
  const [vendor_id, setVendor_id] = useState('')
  const setVendorData = (agentId, vendorId) => {
    setAgent_id(agentId)
    setVendor_id(vendorId)
  }
  const handleAssignUser = (assignment) => {
    assignUser(client_id, vendor_id, agent_id, 'ASSIGN', assignment)
      .then((res) => {
        getShopsList()
        setShowAssignAgentModal(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const [clientData, setClientData] = useState({ firstName: '', lastName: '', company: '', address: '', email: '', pincode: '', gst: '', phone: '' });
  const [addAgentModal, setAddAgentModal] = useState(false)
  const handleCreateVendor = (e) => {
    // e.preventDefault();
    // createVendor(clientData)
    //   .then(res => {
    //     setAddAgentModal(false)
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
  }
  const [singleUserData, setSingleUserData] = useState({})
  const [loadingSingleData, setLoadingSingleData] = useState(false)
  const [currentUserId, setCurrentUserId] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const getSingleData = (userId) => {
    setLoadingSingleData(true)
    setCurrentUserId(userId)
    getSingleUserData(userId)
      .then((res) => {
        setLoadingSingleData(false)
        setShowEditModal(true)
        setSingleUserData(res.data.data)
      })
      .catch((err) => {
        console.error(err)
        setLoadingSingleData(false)
      })
  }
  const handleUpdate = () => {
    setIsUpdating(true)
    updateData(singleUserData, currentUserId)
      .then((res) => {
        setIsUpdating(false)
        getShopsList();
        setShowEditModal(false)
      })
      .catch((err) => {
        setIsUpdating(false)
      })
  }

  const [deleteShowModal, setShowDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [status, setStatus] = useState('')
  const handleUserDelete = (id, action) => {
    setStatus(action)
    setShowDeleteModal(true)
    setCurrentUserId(id)
  }
  const deleteSingleUser = () => {
    setIsDeleting(true)
    deleteUser(currentUserId, status)
      .then((res) => {
        setIsDeleting(false)
        getShopsList();
        setShowDeleteModal(false)
      })
      .catch((err) => {
        setIsDeleting(false)
        console.log(err)
      })
  }

  const handlePageClick = (e) => {
    getShopsList(e.selected);
    setCurrentPage(e.selected);
  }

  return <>
    <div className="mb-4 flex items-center space-x-4">
      <div className="relative inline-block text-left">
        <div>
          <button onClick={() => setOpenDropDown(true)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none capitalize " id="menu-button" aria-expanded="true" aria-haspopup="true">
            Shop
            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        {openDropDown && <div className="origin-top-right absolute z-40 left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div className="py-1" role="none" onClick={() => setOpenDropDown(false)}>
            <Link to={`/admin`} className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-3">
              Agent
            </Link>
            <Link to={`/admin/vendor`} className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-3">
              Vendor
            </Link>
            <Link to={`/admin/shop`} className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-3">
              Shop
            </Link>
          </div>
        </div>}
      </div>

      <div className="">
        <div className="relative">
          <input type="search"
            className="w-full pl-10 pr-4 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium"
            placeholder="Search..." value={searchWord} onChange={(e) => searchResult(e)} />
          <div className="absolute top-0 left-0 inline-flex items-center p-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
              strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
              strokeLinejoin="round">
              <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
              <circle cx="10" cy="10" r="7" />
              <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <button onClick={() => getShopsList(currentPage)} className='px-4 py-1 bg-sky-900 group rounded hover:bg-gray-200 hover:ring-1 hover:ring-gray-800'>
          <RefreshIcon className='w-7 text-white group-hover:text-gray-700' />
        </button>
      </div>
    </div>
    {isDataLoading ? <div className='w-full flex items-center justify-center my-16'>
      <Spinner width={40} height={40} />
    </div> : <div className="" >
      {shopList.length > 0 ? <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative" >
        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
          <thead>
            <tr className="text-left">
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Sr.no
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Shop name
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                E-mail
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Status
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Vendor
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Agent
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Payments
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {shopList?.map((agentData, idx) => {
              return <tr key={idx}>
                <td className="border-dashed border-t border-gray-200 userId">
                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1 + (currentPage * PAGE_OFFSET)}</span>
                </td>
                <td className="border-dashed border-t border-gray-200 firstName">
                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{agentData.fullName}</span>
                </td>
                <td className="border-dashed border-t border-gray-200 lastName">
                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{agentData.email}</span>
                </td>
                <td className="border-dashed border-t border-gray-200 lastName">
                  <div className="px-5">
                    {agentData.active === '1' ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 "> Active </span> : <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 "> In-active </span>}
                  </div>
                </td>
                <td className="border-dashed border-t border-gray-200 lastName">
                  <button title={agentData.vendor_name ? agentData.vendor_name : `assign`} className="px-4 py-1 mx-auto rounded-md bg-white hover:bg-slate-100 border flex items-center space-x-2"
                    onClick={() => { setClient_id(agentData.user_id); getVendorList() }}
                  >
                    <span className="text-gray-700 text-xs line-clamp-1">{agentData.vendor_name ? agentData.vendor_name : `assign`}</span>
                    <EyeIcon className=' w-4 mr-2' />
                  </button>
                </td>
                <td className="border-dashed border-t border-gray-200 lastName">
                  <button title={agentData.agent_name ? agentData.agent_name : `assign`} className="px-4 py-1 mx-auto rounded-md bg-white hover:bg-slate-100 border flex items-center space-x-2"
                    onClick={() => { setClient_id(agentData.user_id); getAgentList() }}
                  >
                    <span className="text-gray-700 text-xs line-clamp-1">{agentData.agent_name ? agentData.agent_name : `assign`}</span>
                    <EyeIcon className=' w-4 mr-2' />
                  </button>
                </td>
                <td className="border-dashed border-t border-gray-200 lastName">
                  <button className="px-4 py-1 mx-auto rounded-md bg-white hover:bg-slate-100 border flex items-center space-x-2"
                    onClick={() => { paymentDetail(agentData.user_id); setUserId(agentData.user_id) }}
                  >
                    <span className="text-gray-700 text-sm">View History</span>
                  </button>
                </td>
                <td className="border-dashed border-t border-gray-200 phoneNumber">
                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-2">
                    {loadingSingleData ? <div className='px-4'><Spinner width={20} height={20} /></div> : <button onClick={() => getSingleData(agentData.user_id)} className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border flex items-center space-x-2">
                      <PencilIcon className=' w-4 mr-2' />
                      Edit
                    </button>}
                    {agentData.active === '1' ? <button onClick={() => handleUserDelete(agentData.user_id, 'inactive')} className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border flex items-center space-x-2">
                      <TrashIcon className=' w-4 mr-2' />
                      Delete
                    </button> : <button onClick={() => handleUserDelete(agentData.user_id, 'active')} className="px-4 py-1 rounded-md bg-green-100 text-green-500 hover:bg-green-200 border border-green-700 flex items-center space-x-2">
                      <SupportIcon className=' w-4 mr-2' />
                      Activate
                    </button>}
                  </span>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div> : <div className='text-center text-gray-700 text-2xl mt-5'><span>No data found</span></div>}
    </div>}

    {
      numberOfPages > 1 &&
        <ReactPaginate
          breakLabel='...'
          nextLabel='Next'
          previousLabel='Previous'
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          pageCount={numberOfPages}
          renderOnZeroPageCount={"ABC"}
          containerClassName='flex p-3 w-full justify-center'
          pageClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
          activeClassName='px-3 py-1 rounded-md border mr-3 bg-sky-300 hover:bg-sky-400'
          previousClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
          nextClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
          breakClassName='px-3 py-1 mr-3'
        />
      }
      <div className='mb-16'></div>

    {/* Assign agent modal */}
    <Modal isOpen={showAssignAgentModal} onClose={() => setShowAssignAgentModal(false)}>
      <div className='border-b border-gray-300 -mx-3'>
        <div className='flex justify-between px-3'>
          <h3 className='mb-2 font-semibold font text-xl '>Assign agent</h3>
          <div className=' cursor-pointer' onClick={() => setShowAssignAgentModal(false)}>
            <XIcon className='w-6' />
          </div>
        </div>
      </div>
      <div>
        <div className="w-full py-2">
          <div className="relative">
            <input type="search"
              className="w-full pl-10 pr-4 py-2 rounded-lg shadow border-none ease-in duration-300 shadow-sky-200 focus:outline-none  focus:shadow-sky-400 text-gray-600 font-medium"
              placeholder="Search agent..." value={""} onChange={(e) => console.log("TODO: ")} />
            <div className="absolute top-0 left-0 inline-flex items-center p-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                strokeLinejoin="round">
                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative max-h-96">
          <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr className="text-left">
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Agent name
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Vendor name
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Assign
                </th>
              </tr>
            </thead>
            <tbody>
              {agentLists?.map((data, idx) => {
                return <tr key={idx}>
                  <td className="border-dashed border-t border-gray-200 userId">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{data.fullName}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{data.vendor_name}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 lastName">
                    <input type="radio" value={data.user_id} onChange={(e) => setVendorData(e.target.value, data.vendor_id)} name={`agent`} className=' default:ring-2 ml-10' />
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        <div className='text-right space-x-5 mt-3'>
          <button className='px-3 py-2 rounded bg-gray-100 text-gray-700' onClick={() => setShowAssignAgentModal(false)}>Cancel</button>
          <button disabled={vendor_id === '' ? true : false} className='px-3 py-2 rounded bg-sky-900  text-white disabled:opacity-75' onClick={() => handleAssignUser('AGENT')}>Save</button>
        </div>
      </div>
    </Modal>

    {/* Assign vendor modal */}
    <Modal isOpen={showAssignVendorModal} onClose={() => setShowAssignVendorModal(false)}>
      <div className='border-b border-gray-300 -mx-3'>
        <div className='flex justify-between px-3'>
          <h3 className='mb-2 font-semibold font text-xl '>Assign Vendor</h3>
          <div className=' cursor-pointer' onClick={() => setShowAssignVendorModal(false)}>
            <XIcon className='w-6' />
          </div>
        </div>
      </div>
      <div>
        <div className="w-full py-2">
          <div className="relative">
            <input type="search"
              className="w-full pl-10 pr-4 py-2 rounded-lg shadow border-none ease-in duration-300 shadow-sky-200 focus:outline-none  focus:shadow-sky-400 text-gray-600 font-medium"
              placeholder="Search vendor..." value={""} onChange={(e) => console.log("TODO: ")} />
            <div className="absolute top-0 left-0 inline-flex items-center p-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                strokeLinejoin="round">
                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative max-h-96">
          <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr className="text-left">
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Vendor name
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Vendor email
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Assign
                </th>
              </tr>
            </thead>
            <tbody>
              {vendorLists?.map((vendorList, idx) => {
                return <tr key={idx}>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{vendorList.fullName}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{vendorList.email}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 lastName">
                    <input type="radio" value={vendorList.user_id} onChange={(e) => setVendor_id(e.target.value)} name={`vendor`} className=' default:ring-2 ml-10' />
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        <div className='text-right space-x-5 mt-3'>
          <button className='px-3 py-2 rounded bg-gray-100 text-gray-700' onClick={() => setShowAssignVendorModal(false)}>Cancel</button>
          <button disabled={vendor_id === '' ? true : false} className='px-3 py-2 rounded bg-sky-900  text-white disabled:opacity-75' onClick={() => handleAssignUser('VENDOR')}>Save</button>
        </div>
      </div>
    </Modal>

    {/* Edit modal */}
    <Modal isOpen={showeditModal} onClose={() => setShowEditModal(false)}>
      <div>
        <div className='border-b border-gray-300 -mx-3'>
          <div className='flex justify-between px-3'>
            <h3 className='mb-2 font-semibold font text-xl '>Edit</h3>
            <div className=' cursor-pointer' onClick={() => setShowEditModal(false)}>
              <XIcon className='w-6' />
            </div>
          </div>
        </div>
        <div className="relative flex-auto">
          <div className="py-4">
            <form className="grid gap-4">
              <div className='col-start-1 col-end-7'>
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-3 sm:col-span-3">
                    <label for="first-name" className="block text-sm font-medium text-gray-700">First name</label>
                    <input type="text" name="first-name" value={singleUserData.first_name} onChange={(e) => setSingleUserData((prevData) => { prevData.first_name = e.target.value; return { ...prevData } })} id="first-name" autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <label for="last-name" className="block text-sm font-medium text-gray-700">Last name</label>
                    <input type="text" name="last-name" value={singleUserData.last_name} onChange={(e) => setSingleUserData((prevData) => { prevData.last_name = e.target.value; return { ...prevData } })} id="last-name" autocomplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                </div>
              </div>
              <div className='col-start-1 col-end-7'>
                <div>
                  <div className="col-span-6 sm:col-span-3">
                    <label for="first-name" className="block text-sm font-medium text-gray-700">Company name</label>
                    <input type="text" name="company-name" value={singleUserData.company} onChange={(e) => setSingleUserData((prevData) => { prevData.company = e.target.value; return { ...prevData } })} id="first-name" autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                </div>
              </div>
              <div className='col-start-1 col-end-7'>
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-3 sm:col-span-3">
                    <label for="address" className="block text-sm font-medium text-gray-700">Address</label>
                    <input type="text" name="address" value={singleUserData.address} onChange={(e) => setSingleUserData((prevData) => { prevData.address = e.target.value; return { ...prevData } })} id="first-name" autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                  <div className="col-span-3 sm:col-span-3">
                    <label for="pincode" className="block text-sm font-medium text-gray-700">Pincode</label>
                    <input type="text" name="pincode" value={singleUserData.pincode} onChange={(e) => setSingleUserData((prevData) => { prevData.pincode = e.target.value; return { ...prevData } })} id="last-name" autocomplete="family-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                </div>
              </div>
              <div className='col-start-1 col-end-7'>
                <div>
                  <div className="col-span-6 sm:col-span-3">
                    <label for="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" name="email" id="first-name" value={singleUserData.email} onChange={(e) => setSingleUserData((prevData) => { prevData.email = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                </div>
              </div>
              <div className='col-start-1 col-end-7'>
                <div>
                  <div className="col-span-6 sm:col-span-3">
                    <label for="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                    <input type="tel" name="phone" id="first-name" value={singleUserData.phone} onChange={(e) => setSingleUserData((prevData) => { prevData.phone = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                </div>
              </div>
              <div className='col-start-1 col-end-7'>
                <div>
                  <div className="col-span-6 sm:col-span-3">
                    <label for="gst" className="block text-sm font-medium text-gray-700">GST ID</label>
                    <input type="text" name="gst" id="first-name" value={singleUserData.gst_id} onChange={(e) => setSingleUserData((prevData) => { prevData.gst_id = e.target.value; return { ...prevData } })} autocomplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="flex items-center justify-end pt-2 border-t border-solid border-blueGray-200 rounded-b -mx-3">
          {isUpdating ? <div className='px-6'><Spinner width={30} height={30} /></div> : <button
            className="bg-sky-900 text-white rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="submit"
            onClick={handleUpdate}
          >
            Save
          </button>}
          <button
            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowEditModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>

    {/* Payment modal */}
    <Modal isOpen={showPaymentsModal} onClose={() => setShowPaymentsModal(false)} size='lg'>
      <div className=''>
        <div className='border-b border-gray-300 -mx-3'>
          <div className='flex justify-between px-3'>
            <h3 className='mb-2 font-semibold font text-xl '>Payment history</h3>
            <div className=' cursor-pointer' onClick={() => setShowPaymentsModal(false)}>
              <XIcon className='w-6' />
            </div>
          </div>
        </div>

        <div className='flex items-center space-x-3'>
          <div className="py-2 grow">
            <div className="relative">
              <input type="search"
                className="w-full pl-10 pr-4 py-2 rounded-lg shadow border-none ease-in duration-300 shadow-sky-200 focus:outline-none  focus:shadow-sky-400 text-gray-600 font-medium"
                placeholder="Search..." value={""} onChange={(e) => console.log("TODO: ")} />
              <div className="absolute top-0 left-0 inline-flex items-center p-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                  strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                  strokeLinejoin="round">
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <circle cx="10" cy="10" r="7" />
                  <line x1="21" y1="21" x2="15" y2="15" />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <button onClick={() => handleRecharge()} className='px-3 py-2 rounded bg-gray-300 hover:bg-gray-100 ring-1 ring-gray-500 hover:ring-gray-700'>Add credits</button>
          </div>
        </div>
        <div className='overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative max-h-96'>
          <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr className="text-left">
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Sr.no
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Recharger Name
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Vendor Name
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Transaction Id
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Transaction Date
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Transaction Type
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Transaction Amount
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Transaction Status
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentData?.map((data, idx) => {
                return <tr>
                  <td className="border-dashed border-t border-gray-200 userId">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{data.recharger_name}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{data.vendor_name}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 lastName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{data.transcationId}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 emailAddress">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                      <Moment unix format="DD/MM/YYYY hh:mm a">
                        {data.transactionDate}
                      </Moment>
                    </span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 emailAddress">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                      {data.tranasactionType}
                    </span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 gender">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">&#8377; {numberWithComma(data.tranasactionAmount ? data.tranasactionAmount : 0)}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 phoneNumber">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> {data.transactionStatus} </span>
                    {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 ml-4"> pending </span>
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 ml-4"> failed </span> */}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>

    {/* Add Credits */}
    <Modal isOpen={openRechargeModal} onClose={() => setOpenRechargeModal(false)} size='sm'>
      <div className=''>
        <div className='border-b border-gray-300 -mx-3'>
          <div className='flex justify-between px-3'>
            <h3 className='mb-2 font-semibold font text-xl '>Recharge</h3>
            <div className=' cursor-pointer' onClick={() => setOpenRechargeModal(false)}>
              <XIcon className='w-6' />
            </div>
          </div>
        </div>
        <div className="relative flex-auto">
          <div className="px-5 py-4">
            <form className="grid gap-4">
              <div className='col-start-1 col-end-7'>
                <div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Amount</label>
                    <input type="text" name="company-name" value={amount} onChange={(e) => setAmount(e.target.value)} id="first-name" autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                </div>
              </div>
              {isShowVerify && <div className='col-start-1 col-end-7'>
                <div className='grid grid-cols-4 gap-4 items-end'>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">OTP</label>
                    <input type="text" name="company-name" id="first-name" value={otp} onChange={(e) => setOtp(e.target.value)} autoComplete="given-name" className="border rounded-md py-1 px-2 w-full outline-none " />
                  </div>
                  <div className="">
                    <div>
                      <button type='button' className='bg-gray-200 hover:bg-gray-300 px-6 py-2 text-sm rounded-md w-full' onClick={handleVerifyOtp}>verify</button>
                    </div>
                  </div>
                </div>
              </div>}
              <div className='col-start-1 col-end-7' hidden={isOtpSent}>
                <div>
                  <button type='button' className='bg-gray-200 hover:bg-gray-300 px-6 py-2 text-sm rounded-md' onClick={generateOTP}>Generate OTP</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="flex items-center justify-end px-6 pt-3 border-t border-solid border-blueGray-200 rounded-b">
          <button
            className="text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setOpenRechargeModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>

    {/* Delete modal */}
    <Modal isOpen={deleteShowModal} onClose={() => setShowDeleteModal(false)}>
      <div className='flex flex-col items-center'>
        <div>
          <XCircleIcon className='text-red-600 w-20' />
        </div>
        <div>
          <span className='block text-gray-700 text-2xl text-center my-4'>
            Are you sure ?
          </span>
          <span className='block text-gray-700 text-base text-center my-3'>
            {`Do you really want to ${status} this record ?`}
          </span>
          <div className='text-center space-x-5 flex items-center justify-center'>
            <button className='px-3 py-2 rounded bg-gray-100 text-gray-700' onClick={() => setShowDeleteModal(false)}>Cancel</button>
            {isDeleting ? <div className='px-6'><Spinner width={30} height={30} /></div> : <button className='px-3 py-2 rounded text-red-700 bg-red-100' onClick={deleteSingleUser}>{status === 'active' ? "Activate" : "Delete"}</button>}
          </div>
        </div>
      </div>
    </Modal>

  </>;
}

export default Shops;
