import axios from "axios";
import Config from "../Config";

export const createCertificateTemplate = async (token, clientId, templateName, templateMeta) => {
    let fd = new FormData();

    fd.append("token", JSON.stringify(token));
    fd.append("identity", "certhandler");
    fd.append("templateName", templateName);
    fd.append("meta", JSON.stringify(templateMeta));

    return await axios({
        method: "POST",
        url: `${Config.apiurl}Project/createProjectTemplate`,
        data: fd
    })
}

export const updateCertificateTemplate = async (token, clientId, templateId, templateName, templateMeta) => {
    let fd = new FormData();

    fd.append("token", JSON.stringify(token));
    fd.append("identity", "certhandler");
    fd.append("templateId", templateId)
    fd.append("meta", JSON.stringify(templateMeta));
    fd.append("templateName", templateName);

    return await axios({
        method: "POST",
        url: `${Config.apiurl}Project/updateTemplate`,
        data: fd
    })
}

export const getTemplates = async (token, pageNo) => {
    let fd = new FormData();

    fd.append("token", JSON.stringify(token));
    fd.append("clientId", token?.id)
    fd.append("pageNo", pageNo);
    fd.append("identity", "certhandler");

    return await axios({
        method: "POST",
        url: `${Config.apiurl}Project/getTemplateList`,
        data: fd
    })
}

export const uploadImageMedia = async (token, certMedia) => {
    let fd = new FormData();

    fd.append("token", JSON.stringify(token));
    fd.append("identity", "certhandler");
    fd.append("project_media", certMedia);

    return await axios({
        method: "POST",
        url: `${Config.apiurl}Project/uploadProjectMedia`,
        data: fd
    })
}


// ---------------------------------------------------------

export const getImageMedia = async (token, pageNo) => {
    let fd = new FormData();

    fd.append("token", JSON.stringify(token));
    fd.append("identity", "certhandler");
    fd.append("pageNo", pageNo);

    return await axios({
        method: "POST",
        url: `${Config.apiurl}Project/getMediaList`,
        data: fd
    })
}

export const createNewProject = async (token, templateId, projectTitle, numOfEntries, excelSheet) => {
    let fd = new FormData();

    fd.append("token", JSON.stringify(token));
    fd.append("identity", "certhandler");
    fd.append("templateId", templateId);
    fd.append("eventTitle", projectTitle);
    fd.append("numEntries", numOfEntries);
    fd.append("project_media", excelSheet);

    return await axios({
        method: "POST",
        url: `${Config.apiurl}Project/createProject`,
        data: fd
    })
}

export const deleteProjectMedia = async (token, imageId) => {
    let fd = new FormData();

    fd.append("token", JSON.stringify(token));
    fd.append("identity", "certhandler");
    fd.append("mediaId", imageId);

    return await axios({
        method: "POST",
        url: `${Config.apiurl}Project/deleteMedia`,
        data: fd
    })
}