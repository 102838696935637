import { XIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';
import { useState } from 'react';
import Config from '../../Config';
import { checkIfNullOrUndefinedOrEmpty } from '../../utilities/general';

import Modal from '../Modal/Modal';

const SettingsModal = ({showSettingsModal, setShowSettingsModal, colorspace, outputType, lotSize, dpi, canvasDetails, 
    bleed, orientation, predefinedSize, filenameColumn, saveProjectSettings, objectState, extendImageIntoBleed, bleedColor}) => {
    const [tempBleed, setTempBleed] = useState(bleed);
    const [tempOrientation, setTempOrientation] = useState(orientation);
    const [tempOutputType, setTempOutputType] = useState(outputType);
    const [tempColorspace, setTempColorspace] = useState(colorspace);
    const [tempPredefinedSize, setTempPredefinedSize] = useState(predefinedSize);
    const [canvasSize, setCanvasSize] = useState({ height: canvasDetails?.height/10, width: canvasDetails?.width/10 });
    const [tempLotSize, setTempLotSize] = useState(lotSize);
    const [tempDpi, setTempDpi] = useState(dpi);
    const [tempBleedColor, setTempBleedColor] = useState(bleedColor);
    const [tempExtendImageIntoBleed, setTempExtendImageIntoBleed] = useState(extendImageIntoBleed);
    const [tempFilenameColumn, setTempFilenameColumn] = useState(filenameColumn);

    const [errors, setErrors] = useState({width: '', height: '', lotSize: '', bleed: ''});

    { console.log(tempBleedColor) }

    useEffect(() => {
        setTempColorspace(colorspace);
        setTempOutputType(outputType);
        setTempLotSize(lotSize);
        setTempDpi(dpi);
        setCanvasSize({ height: canvasDetails?.height/10, width: canvasDetails?.width/10 });
        setTempBleed(bleed);
        setTempOrientation(orientation);
        setTempPredefinedSize(predefinedSize);
        setTempFilenameColumn(filenameColumn);
        setTempExtendImageIntoBleed(extendImageIntoBleed);
        setTempBleedColor(bleedColor);
    }, [colorspace, outputType, lotSize, dpi, canvasDetails, bleed, orientation, predefinedSize, bleedColor, extendImageIntoBleed])

    const changeOrientation = (value) => {
        setTempOrientation(value)
        if(value === 'landscape') {
            if(canvasSize?.height > canvasSize?.width) {
              let details = canvasSize;
              let temp = details?.width;
              details.width = details?.height;
              details.height = temp;
      
              setCanvasSize({...details})
            }
          }
          else if(value === 'portrait') {
            if(canvasSize?.width > canvasSize?.height) {
              let details = canvasSize;
              let temp = details?.width;
              details.width = details?.height;
              details.height = temp;
      
              setCanvasSize({...details})
            }
          }
    }

    // Canvas settings methods
    const changeCanvasWidth = (value) => {
        // if(/^\d+$/.test(value) || value === '') {
        if(/^[\d]*.[\d]*$/.test(value) || value === '') {
            setCanvasSize({...canvasSize, width: value})
            if(parseFloat(value) >= parseFloat(canvasSize?.height)) {
                setTempOrientation('landscape');
            }
            else if(parseFloat(value) < parseFloat(canvasSize?.height)) {
                setTempOrientation('portrait');
            }
        }
    }

    const changeCanvasHeight = (value) => {
        if(/^[\d]*.[\d]*$/.test(value) || value === '') {
            setCanvasSize({...canvasSize, height: value})

            if(parseFloat(value) > parseFloat(canvasSize?.width)) {
                setTempOrientation('portrait');
            }
            else if(parseFloat(value) <= parseFloat(canvasSize?.width)) {
                setTempOrientation('landscape');
            }
        }
    }

    const changeLotSize = (value) => {
        if(/^\d+$/.test(value) || value === '') {
            if(value > 0 || value === '') {
                setTempLotSize(value);
            }
        }
    }

    const changeTempBleed = (value, side) => {
        if(/^[\d]*.[\d]*$/.test(value) || value === '') {
            if(side === 'top') {
                setTempBleed({...tempBleed, top: value});
            }
            else if(side === 'left') {
                setTempBleed({...tempBleed, left: value});
            }
            else if(side === 'bottom') {
                setTempBleed({...tempBleed, bottom: value});
            }
            else if(side === 'right') {
                setTempBleed({...tempBleed, right: value});
            }
        }
    }

    const callSaveProjectSettings = () => {
        if(checkIfNullOrUndefinedOrEmpty(canvasSize?.width) || checkIfNullOrUndefinedOrEmpty(canvasSize?.height) || checkIfNullOrUndefinedOrEmpty(tempLotSize)
            || checkIfNullOrUndefinedOrEmpty(bleed?.left) || checkIfNullOrUndefinedOrEmpty(bleed?.top) || checkIfNullOrUndefinedOrEmpty(bleed?.right)
            || checkIfNullOrUndefinedOrEmpty(bleed?.bottom)
        ) {
            let newErrors = errors;

            if(checkIfNullOrUndefinedOrEmpty(canvasSize?.width)) { newErrors['width'] = 'Please enter width' }
            else { newErrors['width'] = ''; }

            if(checkIfNullOrUndefinedOrEmpty(canvasSize?.height)) { newErrors['height'] = 'Please enter height' }
            else { newErrors['height'] = ''; }

            if(checkIfNullOrUndefinedOrEmpty(tempLotSize)) { newErrors['lotSize'] = 'Please enter lot size' }
            else { newErrors['lotSize'] = ''; }

            if(checkIfNullOrUndefinedOrEmpty(tempBleed?.left) || checkIfNullOrUndefinedOrEmpty(tempBleed?.top) || checkIfNullOrUndefinedOrEmpty(tempBleed?.right)
            || checkIfNullOrUndefinedOrEmpty(tempBleed?.bottom))
            {
                newErrors['bleed'] = 'Please enter values for bleed'
            }
            else { newErrors['bleed'] = '' }

            setErrors({...newErrors});
            return;
        }
        else {
            setErrors({width: '', height: '', lotSize: '', bleed: ''});
        }

        if(
            parseFloat(tempBleed?.left) < 0 || parseFloat(tempBleed?.left) > 5 ||
            parseFloat(tempBleed?.top) < 0 || parseFloat(tempBleed?.top) > 5 ||
            parseFloat(tempBleed?.right) < 0 || parseFloat(tempBleed?.right) > 5 ||
            parseFloat(tempBleed?.bottom) < 0 || parseFloat(tempBleed?.bottom) > 5
        ) {
            let newErrors = errors;
            newErrors['bleed'] = 'Bleed values must be bewteen 0 mm to 5 mm';
            setErrors({...newErrors});
            return;
        }
        else {
            setErrors({width: '', height: '', lotSize: '', bleed: ''});
        }

        let resetCanvas = false;
        let newSettings = { 
            colorspace: tempColorspace,
            outputType: tempOutputType,
            lotSize: tempLotSize,
            dpi: tempDpi,
            canvasSize: canvasSize,
            orientation: tempOrientation,
            bleed: tempBleed,
            predefinedSize: tempPredefinedSize,
            filenameColumn: tempFilenameColumn,
            bleedColor: tempBleedColor,
        };

        if(canvasSize?.height !== (canvasDetails?.height/10) || canvasSize?.width !== (canvasDetails?.width/10) || orientation !== tempOrientation) {
            resetCanvas = true;
        }

        saveProjectSettings(newSettings, resetCanvas);
        setShowSettingsModal(false);
    }

    return (<>
        <Modal
            isOpen={showSettingsModal}
            onClose={() => setShowSettingsModal(false)}
        >
            <div className="flex justify-between">
                <h5 className="font-bold text-lg m-2">Project Settings</h5>
                <button type="button" className="m-2" onClick={() => setShowSettingsModal(false)} aria-label="Close">
                <XIcon className='text-black w-6'/>
                </button>
            </div>

            <section className="m-2 flex flex-wrap">
                <div className='w-1/2'>
                    <p className=' font-medium'>Colorspace:</p>
                    <div className="flex items-center mt-1">
                        <input className="py-0" type="radio" name="colorspaceRadios" id="colorspaceRadio1" value="RGB" 
                        onChange={(e) => setTempColorspace(e.target.value)} checked={ tempColorspace === "RGB" }/>
                        <label className="form-check-label text-black ml-1" htmlFor="colorspaceRadio1">
                            RGB 
                        </label>
                        <input className="ml-4 py-0" type="radio" name="colorspaceRadios" id="colorspaceRadio2" value="CMYK" 
                        onChange={(e) => setTempColorspace(e.target.value)} checked={ tempColorspace === "CMYK" }/>
                        <label className="form-check-label text-black ml-1" htmlFor="colorspaceRadio2">
                            CMYK
                        </label>
                    </div>
                </div>

                <div className='w-1/2'>
                    <p className=" font-medium">Output:</p>
                    <div className="flex items-center mt-1">   
                        <input className="py-0" type="radio" name="outputRadios" id="outputRadio1" value="PDF" 
                        onChange={(e) => setTempOutputType(e.target.value)} checked={ tempOutputType === "PDF" }/>
                        <label className="text-black ml-1" htmlFor="outputRadio1">
                            PDF 
                        </label>
                        <input className="ml-4 py-0" type="radio" name="outputRadios" id="outputRadio2" value="Image-JPG" 
                        onChange={(e) => setTempOutputType(e.target.value)} checked={ tempOutputType === "Image-JPG" }/>
                        <label className="text-black ml-1" htmlFor="outputRadio2">
                            Image (.jpg)
                        </label>
                    </div>
                    {tempOutputType?.startsWith('Image') && <p className='my-2 text-[13px] text-red-500'>Images might take longer time to generate than PDF</p>}
                </div>

                <div className='w-1/2'>
                    <p className="mt-4 font-medium">Lot Size:</p>
                    <input className="py-1 border rounded-md py-1 px-2 outline-none mr-2" type="text"
                        value={tempLotSize}
                        onChange={(e) => changeLotSize(e.target.value)}
                    />
                    { errors?.lotSize !== '' && <p className='text-red-500 mt-2'>{errors?.lotSize}</p> }
                    { tempLotSize<50 && tempLotSize >1 ? <p className='my-2 text-[13px] text-red-500'>For lower lot sizes, generation will take longer time.</p> : null}
                    { tempLotSize == 1 ? <p className='my-2 text-[13px] text-red-500'>For lot size 1, you can select a column for the filename.</p> : null}
                </div>

                {
                    tempOutputType === "Image-JPG" ?
                    <div className='w-1/2'>
                        <p className="mt-4 font-medium">DPI:</p>
                        <select name="" id="" className="py-1 border rounded-md py-1 px-2 outline-none " value={tempDpi}
                            onChange={(e) => setTempDpi(e.target.value)}
                        >
                        {
                            Config.dpiValues.map(dpiVal => {
                                return <option value={dpiVal}>
                                {dpiVal}
                                </option>
                            })
                        }
                        </select>
                    </div>
                    :
                    <div className='w-1/2'>

                    </div>
                }

                {
                tempLotSize == 1 ?
                <div className='w-full'>
                    <p className="mt-4 font-medium">Select field for filename:</p>
                    <select name="" id="" className="py-1 border rounded-md py-1 px-2 outline-none"
                        value={tempFilenameColumn}
                        onChange={(e) => setTempFilenameColumn(e.target.value) }
                    >
                        {/* <option value={null} disabled selected>Select</option> */}
                        {
                            objectState?.length !== 0 &&
                                <option value="" className='italic'>No field</option>                            
                        }
                        {
                            objectState?.length !== 0 && 
                            objectState?.map((element, i) => {
                                if(element?.type === 'TextBox')
                                return <option key={i} value={element?.text}>
                                    {element?.text}
                                </option>
                            })
                        }
                        {
                            objectState?.length == 0 &&
                            <>
                                <option disabled></option>
                                <option value="" disabled className='my-2'>Add Text fields to see columns here</option>
                                <option disabled></option>
                            </>
                        }
                    </select>
                </div>          
                : null          
                }

                <div className='w-1/2 mt-4'>
                    <label className="text-black mb-0 font-semibold">Canvas Size:</label>
                    <section className='my-1'>
                        <select className='py-1 w-1/2 border rounded-md py-1 px-2 w-full outline-none'
                            value={tempPredefinedSize} 
                            onChange={(e) => { setTempPredefinedSize(e.target.value) }}
                        >
                            {
                                Object.keys(Config.predefinedCanvasSizes).map(element => {
                                    return (
                                        <option value={element}>{element}</option>
                                    )
                                })
                            }
                        </select>
                    </section>
                </div>

                <section className="mb-2 mt-4">
                    <label htmlFor="" className='mt-4 font-medium w-1/2'>Orientation:</label>
                    <div className='flex mt-1'>
                        <div className="flex items-center">
                            <input className="form-check-input py-0" type="radio" name="exampleRadios" id="orientationRadio1" value="landscape" 
                            onChange={(e) => changeOrientation(e.target.value)} checked={ tempOrientation === "landscape" }/>
                            <label className="text-black ml-1" htmlFor="orientationRadio1">
                                Landscape
                            </label>
                        </div>
                        <div className="ml-4 flex items-center">
                            <input className="form-check-input py-0" type="radio" name="exampleRadios" id="orientationRadio2" value="portrait" 
                            onChange={(e) => changeOrientation(e.target.value)} checked={ tempOrientation === "portrait" }/>
                            <label className="text-black ml-1" htmlFor="orientationRadio2">
                                Portrait
                            </label>
                        </div>
                    </div>
                </section>

                {
                    tempPredefinedSize === "Custom" ?
                    <section className="flex mb-2 mt-4 w-full">
                        <div className="w-1/2 ">
                            <label className="text-black font-semibold">
                                Height (in cm)
                            </label>
                            <input className="py-0.5 w-full border rounded-md py-1 px-2 w-full outline-none" type="text" onChange={(e) => changeCanvasHeight(e.target.value)} value={canvasSize.height}
                                disabled={tempPredefinedSize !== "Custom"}
                            />
                            { errors?.height !== '' && <p className='text-red-500 mt-2'>{errors?.height}</p> }
                        </div>
                        <div className="w-1/2 ml-2">
                            <label className="text-black font-semibold">
                                Width (in cm)
                            </label>
                            <input className="py-0.5 w-full border rounded-md py-1 px-2 w-full outline-none" type="text" onChange={(e) => changeCanvasWidth(e.target.value)} value={canvasSize.width}
                                disabled={tempPredefinedSize !== "Custom"}
                            />
                            { errors?.width !== '' && <p className='text-red-500 mt-2'>{errors?.width}</p> }
                        </div>
                    </section>
                    : null
                }

                <section className='w-full mt-4'>
                    <label htmlFor="" className='font-medium'>Bleed (in mm):</label>
                    <div className='flex flex-wrap mt-2'>
                        <label htmlFor="" className='w-1/5 mr-2'>Left:</label>
                        <label htmlFor="" className='w-1/5 mr-2'>Top:</label>
                        <label htmlFor="" className='w-1/5 mr-2'>Bottom:</label>
                        <label htmlFor="" className='w-1/5 mr-2'>Right: </label>
                        <input type="text" name='bleed-left'   className='w-1/5 mr-2 border rounded-md py-1 px-2 outline-none' 
                        value={tempBleed?.left}   onChange={(e) => changeTempBleed(e.target.value, 'left')}/>
                        <input type="text" name='bleed-top'    className='w-1/5 mr-2 border rounded-md py-1 px-2 outline-none' 
                        value={tempBleed?.top}    onChange={(e) => changeTempBleed(e.target.value, 'top')}/>
                        <input type="text" name='bleed-bottom' className='w-1/5 mr-2 border rounded-md py-1 px-2 outline-none' 
                        value={tempBleed?.bottom} onChange={(e) => changeTempBleed(e.target.value, 'bottom')}/>
                        <input type="text" name='bleed-right'  className='w-1/5 mr-2 border rounded-md py-1 px-2 outline-none' 
                        value={tempBleed?.right}  onChange={(e) => changeTempBleed(e.target.value, 'right')}/>
                    </div>
                    { errors?.bleed !== '' && <p className='text-red-500 mt-2'>{errors?.bleed}</p> }
                </section>

                {/* <section className='w-1/2 mt-4 flex items-center'>
                    <input type="checkbox" id="extendImageIntoBleed" checked={tempExtendImageIntoBleed} 
                        onChange={(e) => setTempExtendImageIntoBleed(e.target.checked)} />
                    <label htmlFor='extendImageIntoBleed' className='ml-2'>Extend image into bleed</label>
                </section> */}

                <section className='w-1/2 mt-4 flex items-center'>
                    <label className="text-center" htmlFor="bleedColorInput">Bleed Color:</label>
                    <input id="bleedColorInput" type="color" className="ml-2 mr-2 border rounded-md outline-none w-6" 
                            value={tempBleedColor} onChange={(e) => { setTempBleedColor(e.target.value); }}
                    />
                </section>
                <p className='text-red-500 text-[13px] mt-4'>
                    Making changes in canvas size or orientation will reset objects added to the canvas
                </p>
                <section className='mt-2 my-5 w-full flex'>
                    <button className='ml-auto px-4 py-1 rounded-md bg-gray-200 hover:bg-gray-300 border' onClick={() => callSaveProjectSettings()}>
                        Save settings
                    </button>
                </section>

            </section>
        </Modal>
    </>);
}
 
export default SettingsModal;