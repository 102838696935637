import Cookies from "universal-cookie";
// import { useHistory } from "react-router-dom";
import { decode } from "base-64";
const cookies = new Cookies();
// const history = new useHistory()
var author = cookies.get("__User_Ver");

export const logout = () => {
    cookies.remove("GG_token", { path: "/" });
    cookies.remove("__User_Ver", { path: "/" });
    cookies.remove("__DP_PG", { path: "/" });
};

export const pushToLogin = () => {
    window.location.replace("/");
}

export const userRedirect = () => {
    // if (decode(author) === `"members"`) {
    //     window.location.replace("/home");
    // } 
    console.log(decode(author))
    // else if (decode(author) === `"vendor"`) {
    //     window.location.assign("/vendor");
    // } else if (decode(author) === `"agent"`) {
    //     window.location.assign("/agents");
    // }
}

export function capitalizeWord(word) {
    return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
}

export const compactNumber = (value) => {
    if (value >= 1000) {
        const suffixes = ["", "k", "m", "b", "t"];
        const suffixNum = Math.floor(("" + value).length / 3);
        let shortValue = parseFloat(
            (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
        );
        if (shortValue % 1 != 0) {
            shortValue = shortValue.toFixed(1);
        }
        return shortValue + suffixes[suffixNum];
    } else {
        return value
    }

};

// Function returns "," for number values
export const numberWithComma = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
// Function return truncated values
export const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const checkIfNotNullOrUndefined = (value) => {
    return value !== null && value !== undefined;
}

export const checkIfNotNullOrUndefinedOrEmpty = (value) => {
    return value !== null && value !== undefined && value !== "" && value !== "null";
}

export const checkIfNullOrUndefined = (value) => {
    return value === null || value === undefined;
}

export const checkIfNullOrUndefinedOrEmpty = (value) => {
    return value === null || value === undefined || value === "";
}