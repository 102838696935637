import { useState, useEffect,useCallback } from 'react';
import { Link, useHistory } from "react-router-dom";
import { PlusIcon } from '@heroicons/react/outline'
import { getProjectList, getProjectListByInput, downloadFiles, downloadAllFiles } from '../../apis/projects'
import { debounce } from "lodash";
import Moment from 'react-moment';
import Spinner from '../../components/spinner/Spinner'
import ReactPaginate from 'react-paginate';
import Cookies from "universal-cookie";
import { EyeIcon, PencilIcon, RefreshIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import { checkIfNullOrUndefinedOrEmpty } from '../../utilities/general';

const cookies = new Cookies();

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const [projectList, setProjectList] = useState([]);

    const history = useHistory();
    const [searchedProjectList, setSearchedProjectList] = useState([]);

    const [searchWord, setSearchWord] = useState('');
    const [currentFilesData, setCurrentFilesData] = useState();
    const [projectCount, setProjectCount] = useState();

    const debounceFn = useCallback(debounce(handleDebounceFn, 500), []);

    const [currentProjectId, setCurrentProjectId] = useState(null);

    const [isListLoading, setIsListLoading] = useState(false);

    const [downloadAllInProgress, setDownloadAllInProgress] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const PAGE_SIZE = 10;

    let token, member;

    try {
        token = JSON.parse(atob(cookies.get("GG_token")));
        member = atob(cookies.get("__User_Ver"));    
    }
    catch(err) {}

    const searchResult = (e) => {
        setSearchWord(e.target.value);
        debounceFn(e.target.value);
    };

    useEffect(() => {
        if(checkIfNullOrUndefinedOrEmpty(token)) {
            history.push("/");
        } else if(member !== '"members"') {
            history.push("/unauthorized");
        }
        fetchProjectList(0);
        // fetchProjectListByInput();
    }, [])

    function handleDebounceFn(inputValue) {
        fetchProjectListByInput(inputValue);
    }

    const fetchProjectListByInput = (inputValue) => {
        setIsListLoading(true);
        getProjectListByInput(inputValue)
            .then((res) => {
                setProjectCount(res.data.data.count);

                setCurrentPage(0);
                setProjectList(res.data.data.projects);
                setIsListLoading(false);
            })
            .catch((err) => console.log(err))
    }

    const fetchProjectList = (currentPage) => {
        setIsListLoading(true);
        getProjectList(currentPage)
            .then((res) => {
                if(currentPage == 0) {
                    setProjectCount(res.data.data.count);
                    if(res.data.data.count%PAGE_SIZE == 0) {
                        setNumberOfPages(Math.floor(res.data.data.count/PAGE_SIZE));
                    }
                    else {
                        setNumberOfPages(Math.floor(res.data.data.count/PAGE_SIZE) + 1);
                    }
                }
                // let newProjectList = projectList;
                // newProjectList[currentPage] = res.data.data.projects;
                // setProjectList({...newProjectList});
                setProjectList(res.data.data.projects);
                setIsListLoading(false);
            })
            .catch((err) => console.log(err))
    }

    const downloadZip = (id) => {
        downloadFiles(id)
        .then((res) => {
            setCurrentFilesData(res.data.data)
        })
        .catch((err) => console.log(err))
    }

    const showFilesModal = (id) => {
        setCurrentFilesData([]);
        setShowModal(true);
        downloadZip(id);
        setCurrentProjectId(id);
    }

    const callDownloadAll = () => {
        setDownloadAllInProgress(true);
        downloadAllFiles(currentProjectId)
            .then(res => {
                console.log(res)
                console.log(res.data.data)
                window.open(res?.data?.data, '_blank')
                setDownloadAllInProgress(false);
            })
            .catch(err => {
                setDownloadAllInProgress(false);
            })
    }

    const handlePageClick = (e) => { 
        // if(projectList.length <= projectCount+1 && projectList.length <= (parseInt(e.selected)*PAGE_SIZE)+1) {
        // if(checkIfNullOrUndefinedOrEmpty(projectList[e.selected])) {
            fetchProjectList(e.selected)
        // }
        setCurrentPage(e.selected); 
    }

    // const refreshTable = () => {
    //     setProjectList({});
    //     setCurrentPage(0);
    //     fetchProjectList(0);
    // }

    return (
        <>
            <div className='container 2xl:max-w-7xl mx-auto'>
                <div className="container mx-auto py-6 px-4">
                    <div>
                        <div className="w-40 rounded-md shadow-md hover:shadow-xl transition ease-in-out duration-300 cursor-pointer text-center bg-white">
                            <Link className="" to="/editor">
                                <div className='py-8'>
                                    <PlusIcon className='w-20 mx-auto' />
                                    <small className='text-gray-700 text-sm pt-4 inline-block'>Create Project</small>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container 2xl:max-w-7xl mx-auto'>
                <div className="container mx-auto py-6 px-4">
                    <h1 className="text-3xl py-4 border-b mb-10">Project Dashboard</h1>
                    <div className="mb-4 flex justify-center items-center">
                        <div className="flex-1 pr-4 flex">
                            <div className="relative md:w-1/3">
                                <input type="search"
                                    className="w-full pl-10 pr-4 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                    placeholder="Search..."
                                    value={searchWord}
                                    onChange={(e) => searchResult(e)} />
                                <div className="absolute top-0 left-0 inline-flex items-center p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                                        strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                        <circle cx="10" cy="10" r="7" />
                                        <line x1="21" y1="21" x2="15" y2="15" />
                                    </svg>
                                </div>
                            </div>
                            <button onClick={() => fetchProjectList(currentPage)} className='ml-3 px-4 py-1 bg-sky-900 group rounded hover:bg-gray-200 hover:ring-1 hover:ring-gray-800'>
                            <RefreshIcon className='w-7 text-white group-hover:text-gray-700' />
                        </button>
                        </div>

                    </div>
                    { projectList?.length == 0 && <div className='text-center text-gray-700 text-2xl mt-5 w-full my-5 py-10'><span>No data found</span></div>  }
                    { isListLoading && <div className='flex items-center justify-center mt-5 w-100 my-5 py-10'><Spinner width={40} height={40} /></div> }
                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                        { (!isListLoading && projectList?.length != 0) &&
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Sr.no
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Project title
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Created time
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Completed in
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Number of entries
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Files
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Total cost
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {console.log(projectList[currentPage])} */}
                                { projectList?.length != 0 ? projectList?.map((projectData, idx) => {
                                    return <tr key={idx}>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{(currentPage*PAGE_SIZE) + idx + 1}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{projectData.projectName}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 lastName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                <Moment format='LT' unix>{projectData.created_on}</Moment>
                                            </span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 emailAddress">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                { projectData.completed_on && (projectData.completed_on - projectData.created_on) + "s" }
                                            </span>
                                        </td>
                                        {/* <td className="border-dashed border-t border-gray-200 emailAddress">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                {projectData.completed_on && <Moment format='LT' unix>{projectData.completed_on}</Moment>}
                                            </span>
                                        </td> */}
                                        <td className="border-dashed border-t border-gray-200 gender">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                <div className="w-full bg-gray-200 rounded-full">
                                                    <div className={"bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none " 
                                                        + (projectData?.documents_processed/projectData?.total_documents  == 1 ? "rounded-full" :"rounded-l-full" )} 
                                                         style={{ "width": ((projectData?.documents_processed/projectData?.total_documents)*100).toFixed(0)+"%" }}
                                                    >
                                                         { projectData?.documents_processed && 
                                                           ((projectData?.documents_processed/projectData?.total_documents)*100).toFixed(2)
                                                        }%
                                                    </div>
                                                </div>
                                            </span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                <button className="px-4 py-1 rounded-md bg-gray-100 hover:bg-gray-200 border" onClick={() => showFilesModal(projectData.id)}>
                                                    Click here
                                                </button>
                                            </span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 gender">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">&#x20B9; {projectData.total_cost}</span>
                                        </td>
                                    </tr>
                                }) : <div className='text-center text-gray-700 text-2xl mt-5 w-full my-5 py-10'><span>No data found</span></div>}
                            </tbody>
                        </table>
                        }
                    </div>

                    {
                        (numberOfPages > 1) &&
                        <ReactPaginate
                            breakLabel='...'
                            nextLabel='Next'
                            previousLabel='Previous'
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            pageCount={numberOfPages}
                            renderOnZeroPageCount={"ABC"}
                            containerClassName='flex p-3 w-full justify-center'
                            pageClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                            activeClassName='px-3 py-1 rounded-md border mr-3 bg-sky-300 hover:bg-sky-400'
                            previousClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                            nextClassName='px-3 py-1 rounded-md border mr-3 bg-white hover:bg-gray-200'
                            breakClassName='px-3 py-1 mr-3'
                        />
                    }

                    <div className='my-10'></div>
                </div>
            </div>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-full">
                        <div className="relative my-6 mx-auto max-w-3xl max-h-screen w-11/12 md:w-8/12 lg:w-7/12">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full max-h-96 bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold"> Media Files</h3>
                                </div>
                                <div className="relative flex-auto overflow-auto">

                                    {
                                        currentFilesData?.length == 0 ?
                                        <p className='text-center'>Loading...</p>
                                        : ""
                                    }
                                    {
                                        currentFilesData?.map(zipFile => {
                                            return (
                                            <div className='cursor-pointer'>
                                                <a download className="text-gray-700 hover:bg-gray-200 px-4 py-2 text-sm block underline hover:underline"
                                                    href={zipFile?.path}
                                                    target="_blank" rel="noreferrer"
                                                >
                                                    {zipFile?.fileName}
                                                </a>
                                            </div>        
                                            )
                                        })
                                    }
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="bg-sky-900 text-white rounded-md font-bold flex uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:opacity-75"
                                        type="button"   disabled={downloadAllInProgress}
                                        onClick={() => callDownloadAll()}
                                    >
                                        {
                                            downloadAllInProgress ?
                                            <>
                                                <Spinner className="mr-3" width={20} height={20} />
                                                <span>Preparing files for download</span>
                                            </> :
                                            <span>Download All</span>                                            
                                        }
                                    </button>
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};

export default Home;
