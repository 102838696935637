import { useEffect } from "react";
import onClickOutside from "react-onclickoutside";

const Modal = ({isOpen, onClose, children, size='sm'}) => {
    Modal.handleClickOutside = () => onClose();
    
    useEffect(() => {
        if(isOpen) {
            document.addEventListener('click', closeWhenClickedOutside);
        }
    }, [isOpen])

    const closeWhenClickedOutside = (event) => {
        if (!event.target.closest('.modal')) {
            // onClose();
            document.removeEventListener('click', closeWhenClickedOutside);
        }
    }

    if(!isOpen) {
        return null;
    }
    else {
        return (<div className="fixed inset-0 z-50 overflow-auto bg-slate-900/50 flex justify-center items-center w-100">
            <div className={"modal p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none " + 
                    (size === 'sm' ? " w-11/12 md:w-6/12 lg:w-6/12 " : "") + 
                    (size === 'md' ? " w-11/12 md:w-8/12 lg:w-7/12 " : "") + 
                    (size === 'lg' ? " w-11/12 md:w-11/12 lg:w-11/12 " : "")
            }>
                {children}
            </div>
        </div>);
    }
}

const clickOutsideConfig = {
    handleClickOutside: () => Modal.handleClickOutside
  };
  
 
// export default onClickOutside(Modal, clickOutsideConfig);
export default Modal;