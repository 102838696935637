import { Link } from 'react-router-dom';

const Footer = () => {
    return (<footer className=" text-white bg-neutral-900 py-1 bottom-0 left-0 right-0 fixed mb-10 translate-y-10">
        <div className='container 2xl:max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center'>
            <a href="https://idigitize.co/" className="px-3 text-xs">Created by iDigitize</a>
            <div className=''>
                <a href="" className="px-3 text-xs">Privacy Policy</a>
                <a href="" className="px-3 text-xs">Terms & Conditions</a>
                <Link to="/contact" className="px-3 text-xs">Contact Us</Link>
            </div>
        </div>
    </footer>);
}

export default Footer;